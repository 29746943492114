/* Auth customer screen start */
.header-disabled {
  margin-top: 20px;
}

.authCustomer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.authCustomer-container,
.authCustomer-datePicker {
  width: 100%;
}
.authCustomer-container-content-v2 {
  flex-grow: 1;
  margin-bottom: auto;
  padding: 24px 16px;
  border-radius: 12px;
  border-width: 1px;
  border-color: #ececec;
  margin: auto;
  width: 88%;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
}
.authCustomer-container-content,
.authSmsOtp,
.authEmailOtp,
.setMpin {
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  margin-bottom: 75px;
}
.authCustomer-heading,
.authMpin-widget-heading {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
}
.authCustomer-subHeading,
.authMpin-widget-subHeading {
  margin-top: 8px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  color: #272d3b;
  opacity: 0.7;
}
.authCustomer-input {
  margin-top: 15px;
  z-index: 100;
}
.authCustomer-input-container {
  margin-top: 15px;
}
.authCustomer-input-label {
  margin-bottom: 8px;
}

.authCustomer-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  z-index: 10;
  width: 100%;
}
.v3-customer-auth-btm-sheet-pan-text {
  color: var(--primary-base-color-2) !important;
}
.authCustomer-button {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 16px;
  flex-direction: column;
  gap: 3px;
}

.authSmsOtp-widget,
.authEmailOtp-widget {
  flex-grow: 1;
  margin-bottom: auto;
  padding: 24px 16px;
  border-radius: 12px;
  border-width: 1px;
  border-color: #ececec;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
}
.authSmsOtp-widget-heading,
.authEmailOtp-widget-heading {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
}
.authSmsOtp-widget-sub-heading {
  color: var(--text-dark-grey, #7b7b7b);
  font-size: 12px;
  font-family: "Inter";
  line-height: 16px;
  letter-spacing: 0.24px;
}
.authSmsOtp-widget-pin-container,
.authEmailOtp-widget-pin-container,
.authMpin-widget-pin-container {
  width: 104%;
  display: flex;
  justify-content: space-between;
  margin-left: -8px;
  margin-top: 32px;
}
.authSmsOtp-widget-resendOtp,
.authEmailOtp-widget-resendOtp {
  display: flex;
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  margin-top: 12px;
}
.authSmsOtp-widget-resendOtp-timer,
.authEmailOtp-widget-resendOtp-timer {
  font-weight: bold;
}
.authSmsOtp-message,
.authEmailOtp-message {
  display: flex;
  padding: 12px;
  width: 95%;
  margin-bottom: 20px;
}
.authSmsOtp-message-icon,
.authEmailOtp-message-icon {
  width: 20px;
  margin-top: 3px;
}
.authSmsOtp-message-text,
.authEmailOtp-message-text {
  font-size: 12px;
}
.authSmsOtp-cta,
.authEmailOtp-cta {
  display: flex;
  align-items: center;
  justify-content: center;
}
.authSmsOtp-button,
.authEmailOtp-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 16px;
}

.authMpin-bottom-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 16px;
}

.authMpin-widget {
  width: 90%;
  border-radius: 12px;
  margin: auto;
  padding: 20px;
  box-shadow: 0px 1px 4px rgba(48, 48, 48, 0.16);
}
.authMpin-widget-pin-container {
  width: 104%;
  display: flex;
  justify-content: space-between;
  margin-left: -8px;
  margin-top: 12px;
}
.authMpin-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.authMpin-button {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 12px;
}
.authMpin-forgotMpin-container {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
}
.authMpin-forgotMpin-text {
  font-size: 12px;
  font-weight: 400;
  color: #7b7b7b;
}
.authMpin-resetNow-text {
  font-size: 12px;
  font-weight: 600;
  color: #3a5fb6;
  margin-left: 8px;
  cursor: pointer;
  margin-right: 3px;
}
.authMpin-resetNow-arrowRight {
  margin-bottom: 2px;
}

/* CHANGE COLOR OF MPIN PAGE RIGHT ARROW */

/* .authMpin-resetNow-arrowRight{
	fill: green !important;
	stroke: green !important;
} */

.authMpin-bannerImg {
  display: block;
  width: 150px;
  height: 250px;
  margin: 30px auto;
}

.mpinIntro {
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
}
.mpinIntro-container {
  padding: 0px 20px;
}
.mpinIntro-title-1 {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-top: 10px;
}
.mpinIntro-title-2 {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.mpinIntro-img-vertical {
  display: block;
  width: 100px;
  height: 150px;
  margin: 20px auto;
}
.mpinIntro-img-horizontal {
  display: block;
  width: 262px;
  height: 165px;
  margin: 20px auto;
}
.mpinIntro-introPara-1,
.mpinIntro-introStep {
  font-size: 14px;
  font-weight: 600;
}
.mpinIntro-introPara-2 {
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
}
.mpinIntro-introSteps-container {
  margin-top: 15px;
}
.mpinIntro-cta-container,
.mpinSuccess-cta-container,
.setMpin-cta-container,
.mpinBlocked-cta-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mpinIntro-cta,
.mpinSuccess-cta,
.mpinBlocked-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 16px;
}

.setMpin-loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setMpin-widget {
  padding: 24px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  border: 1px solid #ececec;
}
.setMpin-widget-heading {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
}
.setMpin-widget-sub-heading {
  color: #7b7b7b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
}
.setMpin-widget-subHeading {
  margin-top: 24px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  color: #7b7b7b;
}
.setMpin-widget-pin-container {
  width: 104%;
  display: flex;
  justify-content: space-between;
  margin-left: -8px;
  margin-top: 12px;
}
.setMpin-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 16px;
}
.setMpin-tnc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setMpin-tnc-container {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 90%;
  margin-bottom: 20px;
  gap: 10px;
  color: var(--tertiary-neutral-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.setMpin-tnc-checkbox {
  width: 20px;
  margin: -3px 5px -5px 0px;
}

/* CHANGE NORMAL STATE OF SET MPIN CHECKBOX  */

/* .setMpin-tnc-checkMark-notchecked {
	border-color: green !important;
} */

/* CHANGE SELECTED STATE COLOR OF SET MPIN CHECKBOX */

/* .setMpin-tnc-checkBox-checked:checked~.hf-checkbox-checkmark {
	background-color: yellow !important;
	border-color: yellow !important;
}
.setMpin-tnc-checkMark-checked::after {
	border-color: pink !important;
} */

.setMpin-tnc-text {
  font-size: 12px;
  margin-top: -2px;
  color: #000;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.v3-mpinSuccess,
.mpinBlocked {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #ebf6ff, rgba(235, 246, 255, 0));
}
.mpinSuccess-content-container,
.mpinBlocked-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
}
.mpinSuccess-content-icon-container,
.mpinBlocked-img-container {
  padding: 28px;
  border-radius: 100px;
}
.mpinSuccess-content-img,
.mpinBlocked-img {
  width: 100px;
  height: 100px;
}
.mpinSuccess-content-text,
.mpinBlocked-text {
  width: 80%;
}
.mpinSuccess-content-text-1,
.mpinBlocked-text-1 {
  margin-top: 36px;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
}
.mpinSuccess-content-text-2 {
  margin-top: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
.mpinBlocked-text-2 {
  font-size: 12px;
  color: #272727;
  text-align: center;
  font-family: Inter;
  margin: 10px 0px;
}

.v3-mpin-blocked-expired-text {
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  color: var(--primary-color-1);
  padding-bottom: 12px;
}

.v3-mpinSuccess-card-img-container {
  width: 85%;
  z-index: 1;
  animation: zoomInOut 1s;
}
.v3-mpinSuccess-card-theme-img-container {
  width: 85%;
  z-index: 1;
  animation: zoomInOut 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .v3-mpinSuccess-tick-img {
  position: absolute;
  width: 35%;
} */
@keyframes zoomInOut {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.v3-mpinSuccess-card-img {
  width: 85%;
  z-index: 1;
  animation: zoomInOut 1s;
}

@keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.v3-mpinSuccess-content-img {
  width: 100%;
  animation: rotateImage 1s ease-in-out;
}
.v3-mpinSuccess-content-icon-container {
  position: absolute;
  /* width: 100%; */
}
.v3-mpinSuccess-content-container-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes floatFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.v3-mpinSuccess-content-text-1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  animation: floatFromBottom 1s ease;
  position: fixed;
  top: 80%;
  left: 0;
  right: 0;
}
/* Auth cusomer screen end */

/* preloader screen start */
.preloader,
.preloader-banner,
.preloader-logos,
.preloader-issuer,
.preloader-hyperfaceLogo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.preloader-issuer-name,
.preloader-hyperfaceLogo-text {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4f4f4f;
}

.preloader-banner {
  margin-top: 10%;
}

.preloader-banner-img {
  width: 300px;
  height: 300px;
}

.preloader-default-banner-img {
  width: 200px;
  height: 200px;
  margin-bottom: 30%;
}

.preloader-logos {
  margin-top: 32px;
}

.preloader-issuer-name {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4f4f4f;
}

.preloader-issuer-logo-container {
  margin-top: 12px;
}

.preloader-issuer-logo {
  width: 100px;
  height: 43px;
}

.preloader-issuer-default-logo {
  width: 36px;
  height: 36px;
}

.preloader-hyperfaceLogo-container {
  display: "flex";
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-top: 16px;
}

.preloader-hyperfaceLogo-img {
  width: "100px";
  height: "28px";
  margin-top: 12px;
}
/* preloader screen end */

/* home screen start */
.home,
.home-activityContainer,
.home-transactionContainer,
.home-preloaderContainer,
.home-versionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container {
  width: 100%;
  min-height: 750px;
  background-color: #fff;
  opacity: 0;
  transition: all 0.5s;
}

.home-container.active {
  opacity: 1;
  transition: all 0.5s;
}

.home-preloader {
  margin-top: 5%;
}

.home-v2 {
  width: 100%;
}

.home-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.home-header-title {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #030303;
}
.home-activitySection-physicalCardStrip-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.home-activitySection-activateCard-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 20px;
}
.home-activitySection-payLater-card {
  width: 80%;
  padding: 12px 0;
}
.home-activitySection-payLater-card-heading {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-right: 4px;
}
.home-activitySection-payLater-card-subHeading {
  font-family: "Inter";
  font-weight: 400;
  line-height: 16px;
  opacity: 0.64;
  margin-top: 4px;
  margin-right: 4px;
  font-size: 12px;
}
.home-cardAndBalance {
  margin-bottom: 24px;
}
.home-transactionSection {
  padding: 16px;
  width: 90%;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 1.41px;
  margin-bottom: 24px;
  border: 1px solid #ececec;
}
.home-transactionSection-toast {
  margin-left: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
}
.home-transactionSection-render-transactionDate {
  margin-top: 30px;
  margin-left: 24px;
  color: #7b7b7b;
  font-style: 500;
  font-size: 12px;
  line-height: 18px;
}
.home-transactionSection-renderLoader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-transactionSection-render-title {
  display: flex;
  justify-content: space-between;
  margin: 24px 16px 0px 16px;
}
.home-transactionSection-render-title-text {
  font-family: Inter;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #303030;
}
.home-transactionSection-render-emptyItem {
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  margin: 16px 0px;
  justify-content: center;
}
.home-transactionSection-open {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}
.home-transactionSection-close {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.home-transactionSection-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 8px;
  border-width: 1px;
}
.home-transactionSection-container {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #f0f0f0;
}
.home-transactionSection-Emptytransaction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
.home-transactionSection-Emptytransaction-icon {
  width: 185px;
  height: 122px;
}
.home-transactionSection-Emptytransaction-text {
  margin-top: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.home-transactionSection-Emptytransaction-msg,
.home-transactionSection-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.home-transactionSection-Emptytransaction-msg-text,
.home-transactionSection-cta-text {
  font-size: 14px;
  font-weight: 400;
  color: #303030;
}
.home-transactionSection-button {
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  margin: 0px 16px;
}

.home-cardDetailsWidget-cardDetails {
  margin: auto;
  margin-top: 20px;
  position: relative;
  width: 222px;
}

.home-cardDetailsWidget-cardDetails-horizontal {
  margin: auto;
  margin-top: 20px;
  position: relative;
  width: 291px;
}

.home-cardDetailsWidget-cardNumber-horizontal {
  font-weight: 500;
  font-size: 20px;
  position: absolute;
  top: 60px;
  left: 20px;
  color: white;
}

.home-cardDetailsWidget-cardDetails-strip {
  background-color: black;
  height: 100%;
  width: 28px;
  margin-left: 33px;
}

.home-cardDetailsWidget-cardDetails-cardImage {
  margin: auto;
  height: 349px;
  width: 222px;
  display: block;
}
.home-cardDetailsWidget-cardDetails-cardImage-horizontal {
  margin: auto;
  height: 190px;
  width: 291px;
  display: block;
}

.home-cardDetailsWidget-cardNumber-1 {
  position: absolute;
  left: 137px;
  top: 33px;
  letter-spacing: 1px;
  font-size: 20px;
}
.home-cardDetailsWidget-cardNumber-2 {
  position: absolute;
  left: 137px;
  top: 68px;
  letter-spacing: 1px;
  font-size: 20px;
}
.home-cardDetailsWidget-cardNumber-3 {
  position: absolute;
  left: 137px;
  top: 101px;
  letter-spacing: 1px;
  font-size: 20px;
}
.home-cardDetailsWidget-cardNumber-4 {
  position: absolute;
  left: 137px;
  top: 135px;
  letter-spacing: 1px;
  font-size: 20px;
}

.home-cardDetailsWidget-cardNumber-2-star {
  position: absolute;
  left: 142px;
  top: 70px;
  letter-spacing: 2px;
  font-size: 20px;
}

.home-cardDetailsWidget-cardNumber-3-star {
  position: absolute;
  left: 142px;
  top: 105px;
  letter-spacing: 2px;
  font-size: 20px;
}

.home-cardDetailsWidget-cardNumberBox {
  position: absolute;
  top: 0px;
}

.home-cardDetailsWidget-cardNumberLine-1 {
  top: 30px;
  position: absolute;
  left: 131px;
}
.home-cardDetailsWidget-cardNumberLine-2 {
  top: 65px;
  position: absolute;
  left: 131px;
}

.home-cardDetailsWidget-cardNumberLine-3 {
  top: 100px;
  position: absolute;
  left: 131px;
}

.home-cardDetailsWidget-date-cvv-box {
  position: absolute;
  top: 170px;
  left: 131px;
  margin-top: 5px;
}

.home-cardDetailsWidget-expdate,
.home-cardDetailsWidget-cvv {
  font-size: 6px;
}

.home-cardDetailsWidget-expdate-text-horizontal,
.home-cardDetailsWidget-cvv-text-horizontal {
  font-size: 12px;
  margin-top: 25px;
}

.home-cardDetailsWidget-expdate-horizontal,
.home-cardDetailsWidget-cvv-horizontal {
  font-size: 14px;
  font-weight: 500;
  margin-top: 7px;
}

.home-cardDetailsWidget-monthDate {
  font-size: 12px;
  margin-left: 8px;
}
.home-cardDetailsWidget-cvvBox {
  margin-top: 10px;
  display: flex;
}

.home-cardDetailsWidget-cvvnumber {
  font-size: 12px;
  margin-top: -5px;
  margin-left: 10px;
}

.home-cardDetailsWidget-eyeicon {
  cursor: pointer;
  margin: auto;
  text-align: center;
}

.home-cardDetailsWidget-eyeicon-text {
  cursor: pointer;
  margin-left: 8px;
  font-size: 12px;
}

.home-cardDetailsWidget-copyNumber-box {
  position: absolute;
  top: 282px;
  left: 80px;
  cursor: pointer;
}

.home-cardDetailsWidget-copyNumber-box-horizontal {
  position: absolute;
  top: 209px;
  left: 160px;
  cursor: pointer;
}
.home-cardDetailsWidget-copyNumber-text {
  margin-left: 3px;
  font-size: 12px;
}

.nudge {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-radius: 8px;
  border-width: 1px;
}
.nudge-heading {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #303030;
  margin-right: 4px;
}
.nudge-text {
  width: 80%;
}
.nudge-subHeading {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #303030;
  opacity: 0.64;
  margin-top: 4px;
  margin-right: 4px;
}

.nudge-smartnudges {
  min-height: 90px;
  margin: 0px 5px;
  margin-bottom: 20px;
}

.v3-nudge-smartnudges {
  margin: auto;
  min-height: 40px;
  width: 100%;
  margin-bottom: 18px;
}

.nudge-smartnudges-nudge-container {
  background-color: rgb(120, 184, 206);
  /* padding: 12px; */
  padding-top: 8px;
  padding-bottom: 8px;
  /* padding-left: 5px; */
  margin: auto;
  margin-left: 10px;
  min-height: 85px;
  border-radius: 12px;
  display: flex;
  margin-top: 4px;
  box-shadow: 0px 1px 4px rgba(48, 48, 48, 0.16);
}

.v3-nudge-smartnudges-nudge-container {
  display: flex;
  background-color: var(--primary-color-5);
  border-radius: 38px;
  padding: 8px 13px 12px 8px;
  border: 1px solid;
  margin: 0px 8px;
  max-height: 70px;
  min-height: 70px;
}

.v3-nudge-smartnudges-pill-view {
  color: var(--primary-color-1, #1965cf);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.14px;
}
.v3-nudge-smartnudges-nudge-container-view-all {
  width: fit-content;
  display: flex;
  background-color: var(--primary-color-5);
  border-radius: 38px;
  padding: 19px 20px;
  border: 1px solid;
  margin: 0px 8px;
  justify-content: "center";
  border: 1px solid var(--primary-color-3, #acc8ee);
  min-height: 70px;
  max-height: 70px;
  align-items: center;
}
.v3-nudge-smartnudges-nudge-container .v3-nudge-smartnudges-nudge-arrow {
  margin-left: auto;
}

.nudge-smartnudges-nudge-icon {
  margin: auto 0px;
}
.v3-nudge-smartnudges-nudge-icon {
  margin: auto 0px;
  width: 40px;
  height: 40px;
}
.v3-nudge-smartnudges-nudge-icon > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.nudge-smartnudges-nudge-nudgeTitleDescriptionContainer {
  margin-left: 8px;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
}

.nudge-smartnudges-nudge-nudgeTitle {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.nudge-smartnudges-nudge-nudgeDescription {
  font-size: 10px;
  cursor: pointer;
}

.nudge-smartnudges-nudge-crossImage {
  position: absolute;
  top: 0px;
  right: 6px;
  cursor: pointer;
  box-shadow: #706464 -1px 1px 3px 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.cardDetailsWidget {
  padding: 0px 20px;
}
.cardDetailsWidget-error {
  padding-top: 60px;
  border-radius: 12px;
  border-color: #ececec;
  border-width: 1px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 3.84px;
}
.cardDetailsWidget-error-img {
  display: block;
  margin: auto;
  width: 96px;
  height: 96px;
}
.cardDetailsWidget-error-text {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #7b7b7b;
}
.cardDetailsWidget-container {
  border-radius: 8px;
  background-color: #fff;
  margin: auto;
}
.cardDetailsWidget-bannerImg-container {
  display: flex;
  margin: 32px 0px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.cardDetailsWidget-bannerImg {
  width: 200px;
  height: 300px;
}
.cardDetailsWidget-nudge {
  margin-top: 24px;
}

.cardDetailsWidget-loader-v1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* home screen end */

/* manage card screen start */
.manageCard-container {
  opacity: 0;
  transition: all 0.5s;
}
.manageCard-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.manageCard-widget {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #ececec;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  margin-bottom: 12px;
  padding: 12px 20px;
}

.manageCard-widget-icon {
  padding: 10px;
}

.manageCard-widget-text {
  font-size: 16px;
  font-weight: 500;
  margin-left: 12px;
}
/* manage card screen end */

/* about program screen start */

.aboutProgram-description,
.aboutProgram-faq {
  margin: 0px 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 1.41px;
  border-color: #ececec;
  border-width: 1px;
  padding: 16px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}
.aboutProgram-description-container_v2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutProgram-description_v2 {
  color: var(--text-black, #272727);
  padding: 16px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.28px;
  text-align: left;
  width: 85%;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 1px solid #ececec;
}
.aboutProgram-cvp {
  margin: 32px 16px 0px 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 1.41px;
  border: 1px solid #ececec;
  padding-bottom: 24px;
}
.aboutProgram-cvp-container {
  padding: 0 24px;
}
.aboutProgram-cvp-border {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ececec;
}
.aboutProgram-cvp-img-container {
  width: 28%;
  margin-right: 2%;
}
.aboutProgram-cvp-img {
  width: 80px;
  height: 80px;
}
.aboutProgram-cvp-title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.aboutProgram-cvp-description {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.aboutProgram-faq {
  margin: 32px 16px 0px 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 1.41px;
  border-color: #ececec;
  border-width: 1px;
  padding: 16px;
}
.aboutProgram-faq-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.aboutProgram-faq-title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}
.aboutProgram-faq-accordian {
  margin-top: 24px;
  width: 100%;
}
.aboutProgram-faq-accordian-item {
  display: flex;
  margin-top: 8px;
  margin-left: 4px;
  align-items: center;
}
.aboutProgram-faq-accordian-item-text {
  width: 80%;
  margin-left: 4px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.aboutProgram-faq-accordian-question {
  width: 85%;
  margin-left: 4px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}
.aboutProgram-knowMore-container {
  display: flex;
  align-items: center;
  margin: 12px 16px 0px 16px;
  cursor: pointer;
}
.aboutProgram-knowMore-text,
.aboutProgram-faq-accordian-container {
  display: flex;
  align-items: center;
}
.aboutProgram-knowMore-chevronIcon {
  margin-left: 6px;
}
.aboutCard-container {
  opacity: 0;
  transition: all 0.5s;
}
.aboutCard-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.v3-aboutCard-card-section {
  background: var(--primary-color-4);
  position: relative;
  min-height: 281px;
  padding-top: 24px;
  padding-bottom: 12px;
}
.v3-aboutCard-card-shadowLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 80%;
}
.v3-aboutCard-card-shadowRight {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 80%;
}
.v3-aboutCard-card-patternLeft {
  position: absolute;
  left: 0;
  top: 20%;
  height: 50%;
}
.v3-aboutCard-card-patternRight {
  position: absolute;
  right: 0;
  top: 0;
  height: 50%;
}
.v3-aboutCard-card-text {
  text-align: center;
}
.v3-aboutCard-card-text > div:first-child {
  font-size: 12px;
  font-weight: 500;
}
.v3-aboutCard-card-text > div:last-child {
  font-size: 18px;
  font-weight: 700;
  margin-top: 4px;
}
.v3-aboutCard-card-cardImage {
  display: block;
  margin: 10px auto;
  width: 288px;
  height: 180px;
}
.v3-aboutCard-card-verticalCardImage {
  height: 288px;
  width: 180px;
}
.v3-aboutCard-benefits {
  margin: auto 18px;
  margin-bottom: 48px;
}
.v3-aboutCard-benefits-cvp {
  margin: 24px 0px;
}
.v3-aboutCard-benefits-cvp > div:first-child {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 18px;
}
.v3-aboutCard-benefits-cvp > div:last-child {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
.v3-aboutCard-welcomeBenefits {
  margin-top: 24px;
}
.v3-aboutCard-welcomeBenefits > div:first-child {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 24px;
}
.v3-aboutCard-welcomeBenefits-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.v3-aboutCard-welcomeBenefits-container > div {
  display: flex;
  gap: 8px;
  align-items: center;
}
.v3-aboutCard-welcomeBenefits-container > div > div:first-child {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--color-static-Campaign-Offers-Yellow-1000, #fff9ea);
}
.v3-aboutCard-welcomeBenefits-container > div > div:first-child > img {
  width: 36px;
}
.v3-aboutCard-welcomeBenefit-benefitText {
  width: 80%;
}
.v3-aboutCard-welcomeBenefit-benefitText > div:first-child {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
}
.v3-aboutCard-welcomeBenefit-benefitText > div:last-child {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  opacity: 0.6;
}
.v3-aboutCard-bottomContainer {
  margin: auto 16px;
  margin-top: 23px;
  padding-bottom: 16px;
}
.v3-aboutCard-lifetimeRewardPoints {
  margin-bottom: 24px;
}
.v3-aboutCard-lifetimeRewardPoints > div:first-child {
  font-size: 16px;
  font-weight: 700;
}
.v3-aboutCard-lifetimeRewardPoints > div:last-child {
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
.v3-aboutCard-RewardPointsCard {
  padding: 12px;
  border-radius: 12px;
  min-height: 172px;
  position: relative;
  overflow: hidden;
}
.v3-aboutCard-RewardPointsCard-bgPattern {
  position: absolute;
  right: 0;
  top: 0;
}
.v3-aboutCard-RewardPointsCard-text {
  margin-top: 12px;
}
.v3-aboutCard-RewardPointsCard-text > div:first-child {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  text-transform: capitalize;
}
.v3-aboutCard-RewardPointsCard-text > div:last-child {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-top: 4px;
}
.v3-aboutCard-RewardPointsCard-image {
  width: 30%;
}
.v3-aboutCard-moreToKnow {
  margin-top: 24px;
}
.v3-aboutCard-moreToKnow > div:first-child {
  font-size: 16px;
  font-weight: 700;
}
.v3-aboutCard-moreToKnow > div:last-child {
  margin-top: 12px;
}
.v3-aboutCard-moreToKnow-card {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 12px 0px;
}
.v3-aboutCard-moreToKnow-card > div:first-child {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: var(--primary-color-5);
  color: var(--tertiary-neutral-color-1);
}
.v3-aboutCard-moreToKnow-card > div:first-child > img {
  width: 100%;
}
.v3-aboutCard-moreToKnow-card > div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.v3-aboutCard-moreToKnow-arrowRight {
  width: 10px;
  height: 10px;
}
.v3-aboutCard-moreToKnow-card {
  font-size: 14px;
  font-weight: 500;
}
.v3-aboutCard-favouriteBrands {
  position: relative;
  min-height: 377px;
  background: #d7e0ff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v3-aboutCard-favouriteBrands-spotlightLeft {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.v3-aboutCard-favouriteBrands-spotlightRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.v3-aboutCard-favouriteBrands-bottomCircle {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(165, 181, 236, 0.516) 30.83%,
    rgba(128, 144, 203, 0.084) 61.32%
  );
}
.v3-aboutCard-favouriteBrands-title {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  position: relative;
  z-index: 10;
  margin-top: 30px;
}
.v3-aboutCard-favouriteBrands-description {
  position: relative;
  margin-top: 17px;
  padding: 10px;
  border-radius: 4px;
  background: var(--color-static-Campaign-Offers-Yellow-1000, #fff9ea);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  z-index: 10;
}
.v3-aboutCard-favouriteBrands-description > div {
  background: linear-gradient(
    91deg,
    #997d2f -3.77%,
    #72570d 50.26%,
    #c69e30 103.32%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.v3-aboutCard-favouriteBrands-carousel {
  margin-top: 34px;
  width: 100%;
}
.v3-aboutCard-favouriteBrands-brandLogo {
  width: 68px;
  height: 68px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--White, #fafafa);
  overflow: hidden;
  margin-left: 10px;
  box-shadow: 0px 1.055px 1.786px rgba(154, 154, 154, 0.25);
}

.v3-aboutCard-favouriteBrands-slider {
  height: 72px;
}

.v3-aboutCard-favouriteMerchantsCarousel-container {
  width: 90%;
  margin: auto;
}
.v3-aboutCard-hf-branding {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 0px;
}

/* about program screen end */

/* customer care screen start */
.customerCare {
  margin: 0px 16px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  border: 1px solid #ececec;
}
.customerCare-description {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}
.customerCare-mobileText,
.customerCare-emailText {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-top: 20px;
}
.customerCare-mobile-container,
.customerCare-email-container {
  margin-top: 2px;
  cursor: "pointer";
  display: flex;
  align-items: center;
}
.customerCare-mobile-img,
.customerCare-email-img {
  margin-right: 12px;
  width: 20px;
  height: 20px;
}
.customerCare-mobile,
.customerCare-email {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
.customerSupport-container {
  opacity: 0;
  transition: all 0.5s;
  background-color: var(--leading-white);
}
.customerSupport-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.v3-customerSupport {
  /* border: solid red; */
  margin: 24px 16px;
  position: relative;
}
.v3-customerSupport-faqsTitle {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 24px;
}
.v3-customerSupport-faqs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 24px;
}
.v3-customerSupport-otherFaqs {
  margin-top: 24px;
}
.v3-customerSupport-otherFaqsTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
}
.v3-customerSupport-infoCard {
  padding: 16px;
  min-height: 141px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.v3-customerSupport-infoCard-title {
  font-size: 14px;
  font-weight: 700;
}
.v3-customerSupport-infoCard-desc {
  width: 50%;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.24px;
}
.v3-customerSupport-infoCard-backgroundPattern {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
}
.v3-customerSupport-infoCard-image {
  position: absolute;
  width: 30%;
  right: 10px;
  bottom: 10px;
  z-index: 5;
}
.v3-contentSeperator {
  height: 1px;
  position: relative;
  background: #ececec;
}
.v3-contentSeperator-centerMarker {
  width: 4px;
  height: 4px;
  border: 0.5px solid var(--color-static-UI-Separator, #dedede);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}
.customerCare-faq-accordian {
  margin: 16px 0px;
  width: 100%;
}
.customerCare-faq-accordian-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.customerCare-faq-accordian-question {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  width: 85%;
}
.customerCare-faq-illustration {
  height: 41px;
  width: 41px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5faff;
}
.customerCare-faq-illustration > img {
  width: 100%;
}
.customerCare-faq-left {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 95%;
}
.customerCare-faq-accordian-item-text {
  width: 90%;
  margin-top: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
}
.v3-customerSupport-question {
  margin-top: 24px;
  padding-bottom: 60px;
}
.v3-customerSupport-title {
  font-size: 16px;
  font-weight: 600;
}
.v3-customerSupport-headline {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
  margin-top: 4px;
}
.v3-customerSupport-textInput {
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--primary-color-1);
  min-height: 45px;
  color: var(--tertiary-neutral-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
  overflow: hidden;
}
.v3-customerSupport-button-container {
  padding: 16px;
  background: var(--leading-white);
  border-top: 1px solid var(--tertiary-neutral-color-3);
}
.v3-customerSupport-CTA,
.tokenisation-saved-card-cta-container,
.tokenisation-add-card-cta-section {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  padding: 16px;
  background: #fff;
  box-shadow: 0px -4px 4px 0px rgba(197, 197, 197, 0.25);
  width: 100%;
  max-width: 450px;
  z-index: 5;
}
.tokenisation-saved-card-cta-container,
.tokenisation-add-card-cta-section {
  flex-direction: column;
}
.bottomSheetDetails-container {
  opacity: 0;
  transition: all 0.5s;
}
.bottomSheetDetails-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.v3-customerSupport-bottomSheet {
  margin: 12px 16px;
}
.v3-customerSupport-bottomSheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.v3-customerSupport-bottomSheet-title {
  font-size: 16px;
  font-weight: 700;
}
.v3-customerSupport-bottomSheet-contacts {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--tertiary-neutral-color-3);
  margin: 0px 24px 12px 24px;
}
.v3-customerSupport-bottomSheet-contacts > div:first-child {
  display: flex;
  align-items: center;
  gap: 12px;
}
.v3-customerSupport-bottomSheet-contacts-image {
  width: 41px;
  height: 41px;
  border-radius: 100px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color-5);
  overflow: hidden;
}
.v3-customerSupport-bottomSheet-contacts-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.v3-customerSupport-bottomSheet-contacts-text > div:first-child {
  color: var(--primary-base-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
}
.v3-customerSupport-bottomSheet-contacts-text > div:last-child {
  color: var(--secondary-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
}
.v3-customerSupport-bottomSheet-contacts-divider {
  margin: 12px;
  color: var(--primary-base-color-1);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.v3-customerSupport-bottomSheet-closeButton {
  cursor: pointer;
}
.v3-customerSupport-bottomSheet-faq-blockedCard {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.v3-customerSupport-bottomSheet-faq-blockedCard div > div:first-child {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.v3-customerSupport-bottomSheet-faq-blockedCard div > div:last-child {
  color: var(--color-static-UI-Light-text, #747474);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.24px;
}
.v3-customerSupport-bottomSheet-informationSafety {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
  margin-top: 12px;
}
.v3-customerSupport-bottomSheet-accountAlerts {
  margin-top: 12px;
}
.v3-customerSupport-bottomSheet-accountAlerts > div:first-child {
  color: var(--color-static-UI-Light-text, #747474);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.v3-customerSupport-bottomSheet-accountAlerts > div:nth-child(2) {
  color: var(--color-static-UI-Light-text, #747474);
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.24px;
}
.v3-customerSupport-bottomSheet-accountAlerts > div:nth-child(3) {
  color: var(--color-static-UI-Light-text, #747474);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
  margin-top: 15px;
}
.v3-customerSupport-bottomSheet-accountAlerts > div:nth-child(4) {
  color: var(--color-static-ui-sub-text-s, #323232);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.24px;
}
.v3-customerSupport-bottomSheet-ellipse-container {
  position: relative;
  height: 150px;
  margin-top: 0px;
}
.v3-customerSupport-bottomSheet-PrivacyIcon-svg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 35%;
  width: 96px;
}
.v3-customerSupport-faq-bottomSheet-closeButton {
  position: absolute;
  right: 5%;
  top: 3%;
  z-index: 35;
  cursor: pointer;
}
.v3-blockCardReason-bottomSheet-ellipse-svg {
  width: 100%;
  border-radius: 15px;
}
.v3-customer-support-email-query-header {
  padding: 24px 26px 28px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.v3-customer-support-email-query-title {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.v3-customer-support-back-button {
  transform: rotate(180deg) scale(1.3);
  margin-top: 4px;
}
.v3-customer-support-email-query-text {
  display: flex;
  flex-direction: column;
  gap: 6.5px;
}
.v3-customer-support-email-query-text > div:first-child {
  color: var(--tertiary-neutral-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
}
.v3-customer-support-email-query-text > div:last-child {
  color: var(--tertiary-neutral-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
}
.v3-customerSupport-textInput-container {
  margin: 0px 20px 28px 20px;
}
.v3-customer-support-bottomSheet {
  padding-bottom: 18px;
}
/* customer care screen end */

/* block card screen start */

.blockCard,
.blockCardReason,
.newCardRequest,
.newCardRequest-tnc {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.blockCard-content,
.cardBlocked-content,
.newCardRequest-content {
  padding: 24px;
  margin: 0px 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  border: 1px solid #ececec;
}
.blockCard-content-img-horizontal,
.cardBlocked-content-img {
  display: flex;
  margin: 0px auto 20px auto;
  width: 196px;
  height: 120px;
  border-radius: 4px;
}
.blockCard-content-img-vertical {
  display: flex;
  margin: 0px auto 20px auto;
  width: 120px;
  height: 196px;
  border-radius: 4px;
}
.blockCard-content-text {
  text-align: center;
  font-weight: 400;
  font-family: "Inter";
  margin-top: 24px;
  font-size: 16px;
}
.blockCard-ctaSection,
.blockCardReason-ctaSection {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 16px;
}
.blockCardReason-content {
  padding: 16px;
  margin: 0px 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  border: 1px solid #ececec;
}
.blockCardReason-content-header,
.newCardRequest-content-header {
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter";
  color: #303030;
}

.blockCardReason-content-box {
  border: 1px solid;
  padding: 4px 28px 6px 8px;
  border-radius: 8px;
  margin-bottom: 12px;
  width: 100%;
  cursor: pointer;
}

.blockCardReason-content-box-selected {
  padding: 4px 28px 6px 8px;
  border-radius: 8px;
  margin-bottom: 12px;
  width: 100%;
  cursor: pointer;
  border: 1px solid;
}

/* TO CHANGE COLOR OF Normal STATE OF BLOCK CARD REASON */

/* .blockCardReason-content-box{
border: 1px solid yellow !important;
}
.blockCardReasonRadio-normalState::before {
	border-color: yellow !important; 
	color: yellow !important;
}
.blockCardReasonRadio-normalState{
	color: yellow !important;
} */

/* TO CHANGE COLOR OF SELCTED STATE OF BLOCK CARD REASON */

/* .blockCardReason-content-box-selected{
border: 1px solid yellow !important;
}
.blockCardReasonRadio-selectedState:checked + *::before {
	background: radial-gradient(yellow 0%,yellow 40%, transparent 50%, transparent) !important;
	border-color: yellow !important; 
	color: yellow !important;
}
.blockCardReasonRadio-selectedState{
	color: yellow !important;
}


.blockCardReasonRadio-selectedState-label{
	color: yellow !important;
}  */

.blockCardReason-content-subHeader,
.newCardRequest-content-subHeader {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #7b7b7b;
  font-family: "Inter";
  margin-top: 18px;
}
.newCardRequest-content-replacement-fee-text {
  font-size: 12px !important;
  align-self: center;
}
.blockCardReason-radio-content {
  width: 100%;
  border-radius: 8px;
  border-width: 1px;
  padding: 6px 28px 6px 8px;
  margin-bottom: 12px;
}
.newCardRequest-content-card-replacement-fee {
  display: flex;
}

.newCardRequest-content-replace-fee-rupee {
  align-self: center;
  margin-right: 10px;
}

.blockCardReason-radio-content-text {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
}
.cardBlocked-ctaSection,
.newCardRequest-ctaSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 16px;
}
.cardBlocked-content-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
}
.newCardRequest-error-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newCardRequest-content-address {
  margin: 18px 0px;
  padding: 16px;
  border-radius: 8px;
}
.newCardRequest-content-address-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #425466;
  width: 90%;
}
.newCardRequest-message {
  display: flex;
  align-items: center;
  justify-content: center;
}
.newCardRequest-message-container {
  display: flex;
  padding: 12px;
  width: 90%;
  margin: 24px 0px;
}
.newCardRequest-message-icon {
  width: 30px;
  margin-top: 3px;
}
.newCardRequest-message-text {
  font-size: 12px;
  color: #7b7b7b;
}
.newCardRequest-tnc-message {
  display: flex;
  padding: 12px;
  width: 90%;
  margin-bottom: 20px;
}
.newCardRequest-tnc-checkBox {
  width: 30px;
}

.newCardRequest-replacement-fee-text {
  font-size: 14px;
  font-weight: 400;
}

/* CHANGE NORMAL STATE OF NEW CARD REQUEST CHECKBOX  */

/* .newCardRequest-tnc-checkMark-notchecked {
	border-color: yellow !important;
} */

/* CHANGE SELECTED STATE COLOR OF NEW CARD REQUEST CHECKBOX */

/* .newCardRequest-tnc-checkBox-checked:checked~.hf-checkbox-checkmark {
	background-color: green !important;
	 border-color: green !important; 
}
.newCardRequest-tnc-checkMark-checked::after {
	border-color: blue !important;
} */

.newCardRequest-tnc-link {
  font-size: 12px;
  color: #7b7b7b;
}
/* block card screen end */

/* card settings screen start */
.cardSetting-settings {
  border-bottom: 1px solid #ececec;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.cardSetting-settings-container {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  border-bottom-color: #dad3d3;
  border-bottom-width: 2px;
  border-radius: 0px;
  padding: 12px 16px 18px 16px;
  margin-bottom: 0px;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
}

.cardSetting-settings-transaction-section-div {
  padding-bottom: 8px;
  margin-top: 4px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-width: 0px;
  width: 100%;
  padding-top: 4px;
}

.cardSetting-settings-transaction-section-icon {
  margin-right: 8px;
  margin-bottom: 5px;
}

.cardSetting-settings-transaction-section-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #303030;
  flex: 10;
}

.cardSetting-settings-transaction-amount-section {
  width: 40%;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: right;
  /* margin: auto; */
}

.v3-cardSetting-transaction-amount-prefix {
  font-size: 20px;
  color: var(--primary-color-1);
  font-weight: 500;
}
.cardSetting-settings-transaction-min-box {
  margin-top: 5px;
  width: 100%;
}

.cardSetting-settings-transaction-slider-box-inner {
  width: 76%;
}

.cardSetting-settings-transaction-minMax {
  display: flex;
  width: 100%;
}

.cardSetting-settings-transaction-minText {
  margin-right: 10px;
  font-size: 14px;
}

.cardSetting-settings-transaction-maxText {
  margin-left: 10px;
  font-size: 14px;
}

.cardSetting-settings-transaction-rangeAmount-box {
  display: flex;
  justify-content: space-between;
}

.cardSetting-settings-transaction-rangeAmount-min {
  margin-right: -5px;
  font-size: 14px;
}

.cardSetting-settings-transaction-rangeAmount-max {
  margin-right: -5px;
  font-size: 14px;
}

.cardSetting-settings-transaction-contactless-description-box {
  margin-top: 10px;
  border-radius: 4px;
}

.cardSetting-settings-transaction-contactless-description-text {
  padding: 5px;
  text-align: center;
  font-size: 14px;
}

.cardSettings {
  display: flex;
  flex: 1;
  flex-direction: column;
  transition: all 0.5s;
}

.cardSettings.active {
  opacity: 1;
  transition: all 0.5s;
}

.cardSettings-loader-div {
  width: 100%x;
}

.cardSettings-main-div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cardSettings-main-div-1 {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.cardSettings-main-div-2 {
  align-items: center;
  justify-content: center;
}

.cardSettings-header-v1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
}

.cardSettings-header-v1-text {
  width: 100%;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 500;
  text-align: center;
}

.cardSettings-cardStatus-transactionLimit {
  align-items: center;
  justify-content: center;
}

.cardSettings-cardStatus-transactionLimit-innerDiv {
  width: 100%;
}

.cardSettings-cardStatus-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid #ececec;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
}

.cardSettings-cardStatus-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.cardSettings-transactionLimit-container {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ececec;
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  /* margin-bottom: 12px; */
}
.cardSettings-transactionLimit-text {
  font-weight: 500;
  font-size: 14px;
}

.cardSettings-domestic-international-topDiv {
  border-width: 1px;
  border-color: #ececec;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  border-radius: 12px;
}
.cardSettings-domestic-international-topDiv-container {
  width: 100%;
  margin-top: 16px;
  padding: 0px 18px;
  border-radius: 12px;
}

.cardSettings-domestic-international-container {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: white;
  text-align: center;
  flex-direction: row;
  display: flex;
  border-radius: 12px;
}

.cardSettings-domestic-div {
  padding: 16px;
  cursor: pointer;
  flex: auto;
}

.cardSettings-domestic-text {
  font-weight: bold;
}

.cardSettings-international-div {
  flex: auto;
  padding: 16px;
  cursor: pointer;
}

.cardSettings-international-text {
  font-weight: bold;
}

.cardSettings-domIntl-transactions {
  padding: 18px;
  padding-top: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.cardSettings-btn-div {
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
}

.cardSettings-btn-save {
  padding-top: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  display: flex;
  width: 100%;
}

/* TO CHANGE COLOR OF TEXT AND ICON OF CARD SETTING PAGE TRANSACTIONS SECTION */

/* .cardSetting-settings-transaction-section-text-Online{
	color: red !important;
}
.cardSetting-online-transaction-icon{
	fill: red !important;
} */

/* card settings screen end */

/* view statement screen start */
.statements-container {
  opacity: 0;
  transition: all 0.5s;
}

.statements-container.active {
  opacity: 1;
  transition: all 0.5s;
}

.statement-loader-v1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.statement {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.statement-DateSelect {
  z-index: 2;
}
.rewards-DateSelect {
  width: 100%;
}
.statement-statementAccordian {
  margin-top: 20px;
  z-index: -1;
  width: 100%;
}
.dateSelect {
  margin: 0px 16px;
  border-radius: 8px;
}
.dateSelect-container {
  /* padding: 5px; */
}
.dateSelect-container-box {
  display: flex;
  border-radius: 12px;
  background: #fff;
  padding: 16px;
  /* box-shadow: 0px 1px 4px 0px rgba(48, 48, 48, 0.16); */
}
.dateSelect-container-box-dropdown {
  /* display: flex; */
  flex: 1 1 0%;
}
.dateSelect-container-box-dropdown-content {
  display: flex;
  margin: 10px;
  border-radius: 8px;
  border-width: 2px;
  border-style: groove;
  cursor: pointer;
}
.dateSelect-container-box-dropdown-content-text {
  padding: 12px;
}
.dateSelect-container-box-dropdown-content-imgBox {
  display: flex;
  flex: 1 1 0%;
  justify-content: end;
  align-items: center;
  margin-right: 15px;
}
.dateSelect-container-box-dropdown-content-img {
  height: 8px;
  width: 16px;
}
.dateSelect-container-box-dropdown-contentClose-year,
.dateSelect-container-box-dropdown-contentClose-month {
  display: none;
}
.dateSelect-container-box-dropdown-contentOpen-year {
  position: absolute;
  width: 40%;
  left: 34px;
  top: 165px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  z-index: 10;
  overflow: hidden;
}
.dateSelect-container-box-dropdown-contentOpen-month {
  position: absolute;
  width: 40%;
  right: 34px;
  top: 165px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  z-index: 10;
  overflow: hidden;
}
.dateSelect-container-box-dropdown-contentOpen-month-scroll {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
}

.dateSelect-container-box-dropdown-contentOpen-year-scroll {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
}
.dateSelect-container-box-dropdown-contentOpen-yearSelected-text,
.dateSelect-container-box-dropdown-contentOpen-monthSelected-text {
  margin-top: 5px;
  width: 100%;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
}
.dateSelect-container-box-dropdown-contentOpen-yearNotSelected-text,
.dateSelect-container-box-dropdown-contentOpen-monthNotSelected-text {
  margin-top: 5px;
  width: 100%;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
}
.dateSelect-button {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 12px 10px;
  border-radius: 8px;
}
.dateSelect-button-text {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #030303;
}
.statementAccordian {
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 2px 2.62px;
  margin: 0px 16px 12px 16px;
  border: 1px solid #ececec;
}
.statementAccordian-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.statementAccordian-emptyStatement {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  opacity: 0.64;
  letter-spacing: 0.02em;
}
.statementData {
  margin-bottom: 20px;
}
.statementData-header {
  width: 100%;
  margin: 10px 0px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.statementData-container {
  padding: 8px 0px;
}
.statementData-openingBalance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 16px;
}
.statementData-openingBalance-text {
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  width: 70%;
}
.statementData-openingBalance-amount {
  width: 30%;
  text-align: right;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
}

.statementData-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0px 10px 0px;
  padding: 6px 16px;
  width: 100%;
}
.statementData-details-text {
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  width: 40%;
}
.statementData-details-amount {
  width: 30%;
  text-align: right;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
}
.statementData-details-sign-debit,
.statementData-details-sign-credit {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
}
.statementData-totalDetails {
  border-bottom: 1px solid #ececec;
  margin-bottom: 16px;
  margin-top: 12px;
}
.statementData-totalDetails-content {
  margin-top: 8px;
  padding: 4px 16px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 10px;
}
.statementData-totalDetails-content-text {
  margin-bottom: 8px;
  width: 50%;
  font-weight: 700;
  font-size: 11px;
  font-family: Inter;
}
.statementData-totalDetails-content-amount {
  width: 50%;
  text-align: right;
  font-size: 12px;
  font-family: Inter;
  font-weight: 700;
}
.statementData-minAmount {
  padding: 0px 16px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.statementData-minAmount-text {
  margin-bottom: 8px;
  width: 50%;
  font-size: 11px;
  font-family: Inter;
  font-weight: 400;
}
.statementData-minAmount-amount {
  width: 50%;
  text-align: right;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
}
.statementData-dueDate {
  padding: 0px 16px;
  margin-top: 12px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.statementData-dueDate-text {
  margin-bottom: 8px;
  width: 50%;
  font-size: 11px;
  font-family: Inter;
  font-weight: 400;
}
.statementData-dueDate-date {
  width: 50%;
  text-align: right;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
}
.statementData-viewTransaction {
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  cursor: pointer;
}
.statementData-viewTransaction-text {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  font-family: Inter;
}

.statementData-payBill-button {
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  margin: 12px 16px;
}

.statementData-transaction-button {
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  margin: 0px 16px;
}
/* view statement screen end */

/* my active EMIs screen start */

.emi-loader-v1,
.emi {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emi-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom-width: 1px;
  border-bottom-color: #ececec;
}
.emi-header-text {
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
}
.emi-container {
  width: 100%;
  background-color: #ffffff;
}
.emi-content {
  display: flex;
  align-items: center;
}
.emi-content-toggleHeader {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: solid;
}
.emi-content-toggleHeader-text {
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
}
.emi-transactionEmi,
.emi-billedEmi {
  padding: 16px;
}
.emi-transactionEmi-widget,
.emi-billedEmi-widget {
  border-radius: 8px;
  border-width: 1px;
  border-color: #ececec;
  margin-top: 24px;
}
.emi-transactionEmi-widget-header,
.emi-billedEmi-widget-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 14px;
  border-radius: 8px;
}
.emi-transactionEmi-widget-header-img-container {
  width: 15%;
  align-items: center;
  border-radius: 40px;
}
.emi-transactionEmi-widget-header-img {
  height: 32px;
  width: 32px;
}
.emi-transactionEmi-widget-header-content {
  width: 65%;
}
.emi-transactionEmi-widget-header-content-description,
.emi-billedEmi-widget-header-content-date {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}
.emi-transactionEmi-widget-header-content-date {
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.emi-transactionEmi-widget-header-content-amount-container {
  width: 20%;
}
.emi-transactionEmi-widget-header-content-amount {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}
.emi-transactionEmi-widget-header-content-paidCount {
  margin-top: 4px;
  border: solid;
  border-width: 1px;
  padding: 1px 4px;
  border-radius: 4px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
}
.emi-transactionEmi-widget-container,
.emi-billedEmi-widget-container {
  padding: 12px 12px 0px 12px;
}

.emi-transactionEmi-widget-emiRow,
.emi-billedEmi-widget-emiRow {
  width: 100%;
  border-bottom: solid 1px #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.emi-transactionEmi-widget-emiRow-text,
.emi-billedEmi-widget-emiRow-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}
.emi-transactionEmi-widget-emiRow-date,
.emi-billedEmi-widget-emiRow-date {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  color: #036f29;
}
.emi-transactionEmi-widget-footer,
.emi-billedEmi-widget-footer {
  padding: 12px;
  width: 100%;
  border-top: solid 1px #f8f8f8;
}
.emi-transactionEmi-widget-footer-container,
.emi-billedEmi-widget-footer-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.emi-transactionEmi-widget-footer-text,
.emi-billedEmi-widget-footer-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.emi-transactionEmi-emptyTransactionEmi,
.emi-billedEmi-emptyBillEmi {
  width: 100%;
  margin-top: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.emi-transactionEmi-emptyTransactionEmi-text-1,
.emi-billedEmi-emptyBillEmi-text-1 {
  margin-top: 12px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.emi-transactionEmi-emptyTransactionEmi-text-2,
.emi-billedEmi-emptyBillEmi-text-2 {
  width: 80%;
  margin-top: 16px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.emi-billedEmi-widget-header-content-paidCount {
  border: solid;
  border-width: 1px;
  padding: 1px 4px;
  border-radius: 4px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
}
.v3-emi-transactionEmi-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
}
.v3-emi-transactionEmi-card > div:first-child {
  display: flex;
  align-items: center;
  width: 60%;
}
.v3-emi-transactionEmi-card-logo-progressBar {
  width: 56px;
  height: 56px;
  position: relative;
  margin-right: 8px;
}
.v3-emi-transactionEmi-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  overflow: hidden;
  border-radius: 100%;
}
.v3-emi-TransactionEmi-textContent-left {
  width: 65%;
}
.v3-emi-TransactionEmi-textContent-left > div:first-child {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.42px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v3-emi-TransactionEmi-textContent-left > div:last-child {
  color: #747474;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v3-emi-TransactionEmi-textContent-left > div:last-child > span {
  font-weight: 400;
}
.v3-emi-transactionEmi-card > div:last-child > div:first-child {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
  text-align: right;
}
.v3-emi-transactionEmi-card > div:last-child > div:last-child {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;
  color: #747474;
}
.v3-emi-cta-container {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  padding: 16px 0px;
  background: #fff;
  box-shadow: 0px -4px 4px 0px rgba(197, 197, 197, 0.25);
  position: fixed;
  width: 100%;
  max-width: 450px;
}
.v3-emiList-skeleton-loader-wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* my active EMIs screen end */

/* convert to EMI screen start */
.v3-convertToEMI-emi-description {
  font-family: Mulish;
  padding: 18px 0;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--tertiary-neutral-color-3);
}
.v3-convertToEMI-emi-description-left {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 65%;
}
.v3-convertToEMI-emi-description-left > div:first-child {
  width: 40px;
  height: 40px;
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-convertToEMI-emi-description-left > div:last-child {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 70%;
}
.v3-convertToEMI-emi-description-left > div:last-child > div:first-child {
  color: var(--tertiary-neutral-color-1);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
}
.v3-convertToEMI-emi-description-left > div:last-child > div:last-child {
  color: var(--tertiary-neutral-color-2);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
}
.v3-convertToEMI-emi-description-right {
  color: var(--tertiary-neutral-color-1);
  font-size: 16px;
  font-weight: 700;
  line-height: 16px; /* 100% */
  letter-spacing: 0.32px;
  align-self: flex-start;
}
.v3-convertToEMI-emi-plans {
  padding: 20px;
}
.v3-convertToEMI-emi-plans-title {
  color: var(--tertiary-neutral-color-1);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}
.v3-convertToEMI-emi-plans-processingFee {
  color: var(--primary-base-color-2);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.24px;
}
.v3-convertToEMI-emi-plans-processingFee > span {
  font-weight: 600;
}
.v3-convertToEMI-emi-plans-container {
  margin-top: 12px;
}
.v3-convertToEmi-selectEmi-card {
  padding: 16px;
}
.v3-convertToEmi-selectEmi-element-details {
  font-family: Mulish;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.v3-convertToEmi-selectEmi-element-details > div:first-child {
  color: var(--tertiary-neutral-color-1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.v3-convertToEmi-selectEmi-element-details > div:last-child {
  color: var(--tertiary-neutral-color-1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.v3-convertToEmi-selectEmi-element-details-total > div:last-child {
  font-weight: 600;
}
.v3-convertToEmi-selectEmi-card {
  border-radius: 12px;
  border: 1px solid #ececec;
  background: #fff;
  margin-top: 12px;
}
.v3-convertToEmi-selectEmi-selectedCard {
  border-radius: 8px;
  border: 1px solid #0a7aff;
  background: #f5faff;
}
.v3-convertToEmi-selectEmi-card > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.v3-convertToEmi-selectEmi-card-tenure {
  display: flex;
  gap: 12px;
  align-items: center;
}
.v3-convertToEmi-selectEmi-card-tenure > div:first-child {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-convertToEmi-selectEmi-card-tenure > div:last-child {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  color: var(--primary-base-color-1);
}
.v3-convertToEmi-selectEmi-card-emiAmount > div:first-child {
  font-family: Mulish;
  color: var(--primary-base-color-1);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; /* 128.571% */
  text-align: right;
}
.v3-convertToEmi-selectEmi-card-emiAmount > div:last-child {
  font-family: Mulish;
  color: var(--primary-base-color-2);
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 500;
  letter-spacing: 0.24px;
  margin-top: 4px;
  text-align: right;
}
.v3-convertToEmi-selectEmi-card-selectedEmiAmount > div:first-child {
  color: #0a7aff;
  font-weight: 700;
}
.v3-convertToEmi-selectEmi-card-selectedEmiAmount > div:last-child {
  color: #0a7aff;
}
.v3-convertToEmi-selectEmi-card-selected-top {
  margin-bottom: 12px;
}
.v3-convertToEmi-selectEmi-card-selectedTenure {
  font-weight: 700;
  color: rgba(10, 122, 255, 1);
}

@keyframes slideUpFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.v3-convertToEMI-confirmTenure-button {
  width: 100%;
  padding: 16px;
  position: fixed;
  bottom: 0;
  background: #fff;
  box-shadow: 0px -4px 4px 0px rgba(223, 223, 223, 0.25);
  animation: slideUpFromBottom 0.32s ease-out forwards;
  max-width: 450px;
}
.v3-emiOverview-content {
  font-family: Mulish;
  margin: 20px;
  margin-bottom: 0px;
}
.v3-emiOverview-content > div:first-child {
  color: var(--tertiary-neutral-color-1);
  font-size: 16px;
  font-weight: 700;
}
.v3-emiOverview-content > div:nth-child(2) {
  margin-top: 16px;
}
.v3-emiOverview-content-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  color: var(--primary-base-color-1);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}
.v3-emiOverview-content-details > div:last-child {
  font-weight: 700;
}
.v3-emiOverview-content-totalAmount {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: var(--primary-base-color-5);
}
.v3-emiOverview-content-totalAmount > div:first-child {
  color: var(--tertiary-neutral-color-1);
  font-size: 14px;
  font-weight: 600;
}
.v3-emiOverview-content-totalAmount > div:last-child {
  color: var(--primary-base-color-1);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
}
.v3-emiOverview-termsAndConditions {
  padding: 16px;
  padding-bottom: 0px;
  font-family: Mulish;
}
.v3-emiOverview-termsAndConditions > div:last-child {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
}
.v3-emiOverview-tnc-section {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 13px;
  padding-right: 13px;
}
.v3-checkbox {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.v3-checkbox-label {
  color: var(--tertiary-neutral-color-2);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.v3-checkbox-label > span {
  font-weight: 700;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-decoration-line: underline;
}
.v3-emiOverview-termsAndConditions > div:first-child {
  color: var(--tertiary-neutral-color-2);
  font-size: 10px;
  font-weight: 500;
  margin-top: 16px;

  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
  padding-bottom: 25px;
}
.v3-emiOverview-convertToEmi-button {
  padding: 16px;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #fff;
  /* box-shadow: 0px -4px 4px 0px rgba(223, 223, 223, 0.25); */
}
.v3-convertToEMI-container {
  background-color: var(--leading-white);
}
.v3-convertToEMI-wrapper {
  height: 100vh;
  height: 100dvh;
  overflow: auto;
  padding-bottom: 80px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.v3-convertToEMI-wrapper::-webkit-scrollbar {
  display: none;
}
.v3-emiOverview-wrapper {
  overflow: auto;
  padding-bottom: 80px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.v3-emiOverview-wrapper::-webkit-scrollbar {
  display: none;
}
.v3-emiOverview-content-preClosureFee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.v3-emiOverview-bottomSheet {
  margin: 24px 16px 16px 16px;
}
.v3-emiOverview-bottomSheet-preClosureFee {
  color: var(--tertiary-neutral-color-1);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  margin-bottom: 12px;
}
.v3-emiOverview-bottomSheet-preClosureFee-description {
  color: var(--tertiary-neutral-color-2);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px; /* 125% */
  margin-top: 12px;
  margin-bottom: 22px;
}
.v3-convertToEMI-header-wrapper {
  position: relative;
}
.v3-convertToEMI-header-circularProgress {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  bottom: 12px;
  right: 10px;
  color: var(--tertiary-neutral-color-2);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
}
.v3-convertToEMI-header-circularProgress > div {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* convert to EMI screen end */

/* reset pin screen start */
.code_action_text {
  font-family: "Inter";
  text-decoration: underline;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.v3ResetPin-container {
  opacity: 0;
  transition: all 0.5s;
}
.v3ResetPin-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.v1ResetPin-container {
  opacity: 0;
  transition: all 0.5s;
}
.v1ResetPin-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.resetPin {
  padding: 0px 16px;
}
.resetPin-widget {
  margin-top: 24px;
  padding: 24px 16px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1.41px;
  border-color: #ececec;
  border-width: 1px;
}
.resetPin-widget-heading {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 20px;
  margin: 2px 0;
}
.resetPin-widget-sub-heading {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  margin-bottom: 32px;
}
.resetPin-widget-subHeading {
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.resetPin-pin-box {
  margin-top: 24px;
}
.resetPin-pin-container {
  width: 104%;
  display: flex;
  justify-content: space-between;
  margin-left: -8px;
}
.resetPin-cta-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.resetPin-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 16px;
}
/* reset pin screen end */

/* analyse spends screen start */

.spendAnalysis-container {
  opacity: 0;
  transition: all 0.5s;
}
.spendAnalysis-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.analyseSpends-dateSelect {
  z-index: 1;
}

.analyseSpends-content {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  align-items: center;
}
.analyseSpends-content-text {
  font-size: 16px;
  font-weight: 600;
}
.analyseSpends-content-amount {
  font-weight: bold;
  font-size: 32px;
}

.analyseSpends-widget-header {
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  width: 65%;
  margin-bottom: 10px;
}
.analyseSpends-widget-transaction-content {
  margin-bottom: 14px;
  margin-left: 15px;
  width: 80%;
  flex: auto;
}
.analyseSpends-widget-transaction-content-div-1,
.analyseSpends-widget-transaction-content-div-2 {
  display: flex;
  align-items: center;
}
.analyseSpends-widget-transaction-content-amount,
.analyseSpends-widget-transaction-content-countOfTxn {
  margin-left: auto;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.analyseSpends-widget-transaction-content-amount-text {
  color: #303030;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.analyseSpends-widget-transaction-content-percentage {
  color: #7b7b7b;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.analyseSpends-widget-transaction-content-countOfTxn-label {
  background-color: #f0eff5;
  border-radius: 3px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  padding: 3px;
}

.analyseSpends-widget-icon-container {
  margin-bottom: 16px;
}

.analyseSpends-noSpends {
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 1.41px;
  margin: 24px auto;
  border: 1px solid #ececec;
}

.analyseSpends-noSpends-content {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.analyseSpends-noSpends-content-img {
  width: 185px;
  height: 122px;
  margin-bottom: 24px;
}

.analyseSpends-noSpends-content-text-1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.analyseSpends-noSpends-content-text-2 {
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.analyseSpends,
.spendCategory {
  padding: 0px 16px;
}

.spendCategory-content {
  margin: 5px auto 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.spendCategory-content-categoryText {
  margin: 5px 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #303030;
}
.spendCategory-content-amount {
  font-size: 32px;
  font-weight: bold;
}

.spendCategory-content-date {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
  font-family: Inter;
  font-style: normal;
}
.analyseSpends-loader,
.spendCategory-loader {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.analyseSpends-widget,
.spendCategory-widget {
  padding: 16px;
  width: 100%;
  margin: 20px auto 24px auto;
  border-radius: 8px;
  border-width: 1px;
  border-color: #f8f8f8;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 1.41px;
}
.analyseSpends-widget-transaction,
.spendCategory-widget-transaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0px solid #f0f0f0;
}
.spendCategory-widget-transaction-icon-container {
  margin-bottom: 16px;
  margin-right: 5%;
}
.spendCategory-widget-transaction-content {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 2 1 0%;
}
.spendCategory-widget-transaction-content-description {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.spendCategory-widget-transaction-content-date {
  margin-top: 5px;
  color: #7b7b7b;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.spendCategory-widget-transaction-content-debitAmount {
  color: #303030;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.spendCategory-widget-transaction-content-creditAmount {
  color: #303030;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

/* analyse spends screen end */

/* view transaction screen start */

.emptyTransaction {
  margin-top: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.emptyTransaction-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.emptyTransaction-content-img {
  padding: 25px 0px;
  border-radius: 100px;
  margin-top: 40px;
  height: 100px;
  height: 80px;
}
.emptyTransaction-content-text-container {
  margin-top: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.emptyTransaction-content-text-1 {
  width: 80%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #303030;
  opacity: 0.64;
  text-align: center;
  letter-spacing: 0.02em;
}
.emptyTransaction-content-text-2 {
  width: 80%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #303030;
  opacity: 0.64;
  text-align: center;
  letter-spacing: 0.02em;
  margin-top: 24px;
}
.showTransaction {
  border: 1px solid #ececec;
  margin-top: 32px;
  background-color: #fff;
  margin: 24px 16px 12px 16px;
  border-color: #ececec;
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 2px 2.62px;
  padding: 16px;
  border-radius: 12px;
}
.showTransaction-header {
  padding: 12px;
}
.showTransaction-header-text {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.32px;
}
.showTransaction-header-date {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.showTransaction-transactionDate,
.viewTransaction-transactionDate {
  margin-top: 30px;
  margin-left: 24px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.04em;
}
.showTransaction-toast,
.viewTransaction-toast {
  margin-left: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
}

.transaction {
  margin: 16px 0px 4px 16px;
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0px solid #f0f0f0;
}

/* view transaction screen end */

/* offer screen start */
.offer {
  border: 1px solid #ececec;
  margin-top: 24px;
  width: 90%;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 12px;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
}

.offer-text-div {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.offer-promotions-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.offer-viewAll-text-div {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.offer-viewAll-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
}

.offer-carouselProvider {
  width: 100%;
  margin-top: 12px;
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-border-radius: 2px;
  /* padding-left: 5px; */
}

.offer-slide {
  cursor: pointer;
}
.offer-viewAll-arrowRight {
  margin-bottom: 4px;
  margin-left: 1px;
}
.v3-offers-bannerCvpCard {
  width: 107px;
  height: 151px;
  padding: 18px 12px;
  background: var(--leading-white);
  box-shadow: 0px 4px 36px -2px #00000026;
  border-radius: 12px;
}
.v3-offers-bannerCvpCard-logo {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}
.v3-offers-bannerCvpCard-title {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 800;
  line-height: 17.6px;
  letter-spacing: 0.02em;
  color: var(--primary-base-color-1);
}
.v3-offers-bannerCvpCard-description {
  margin-top: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.06px;
  color: var(--primary-base-color-2);
}
/* offer screen end */

/* offer card screen start */

.offersCard {
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 3.84px;
}

.offersCard-main-div {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.offersCard-rewardIcon {
  width: 70px;
  border-right: 1px solid #ecedff;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.offersCard-description-div {
  width: 75%;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.offersCard-description-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #272727;
}

/* offer card screen end */

/* offer list screen start */

.offerList-loader-div {
  align-items: center;
  justify-content: center;
}

.offerList-loader-div-1 {
  width: 100%;
}

.offerList-loader {
  width: 100%;
}

.offerList-main-div {
  align-items: center;
  justify-content: center;
}

.offerList-header-div {
  width: 100%;
}

.offerList-header {
  width: 100%;
}

.offerList-offers-map {
  align-items: center;
  justify-content: center;
  padding-bottom: 36px;
}

.offerList-offers-mapped {
  margin: auto;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  width: 90%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
  border: 1px solid #ececec;
}

.offerList-offers-rewardIcon {
  width: 107px;
  border-right-width: 1px;
  padding: 18px;
  align-items: center;
  justify-content: center;
  border-right-style: solid;
}

.offerList-offers-content-div {
  width: 70%;
}

.offerList-offers-content {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

.offerList-offers-content-image-div {
  width: 35%;
  padding: 4px;
  margin-right: 12px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.offerList-offers-content-text-div {
  margin-left: 4px;
}

.offerList-offers-content-text {
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
}

.offerList-offers-content-details-text-div {
  padding-left: 18px;
  padding-right: 18px;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.offerList-offers-content-details-text {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 4px;
}

/* offer list screen end */
/* EMI plans screen start */

.emiPlans,
.emiPlans-v1 {
  width: 100%;
}

.emiPlans-error,
.emiPlans-error-v1 {
  align-items: center;
  justify-content: center;
}

.emiPlans-header,
.emiPlans-header-v1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 13px;
  gap: 8px;
  justify-content: space-between;
}

.emiPlans-main-div,
.emiPlans-main-div-v1 {
  align-items: center;
  justify-content: center;
}

.emiPlans-header-div,
.emiPlans-header-div-v1 {
  width: 100%;
}

.emiPlans-header-text,
.emiPlans-header-text-v1 {
  width: 100%;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
}

.emiPlans-tax-div {
  margin-bottom: 12px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.emiPlans-tax-widget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 12px;
  border-radius: 12px;
  width: 90%;
  justify-self: center;
  border-width: 1px;
  border-color: #ececec;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
}

.emiPlans-tax-logo-div {
  display: flex;
  flex-direction: row;
  width: 62%;
}

.emiPlans-tax-logo {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
}

.emiPlans-tax-transactionLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emiPlans-tax-description-text-div {
  align-items: flex-start;
  margin-top: 4px;
}

.emiPlans-tax-description-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #303030;
}

.emiPlans-tax-date-text-div,
.emiPlans-tax-date-text-div-v1 {
  align-items: center;
  margin-top: 4px;
}

.emiPlans-tax-date-text,
.emiPlans-tax-date-text-v1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  display: contents;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: #303030;
}

.emiPlans-tax-date-text-v1 {
  display: block;
}

.emiPlans-tax-amount-text-div {
  align-items: center;
  margin-top: 8px;
  width: 40%;
}

.emiPlans-tax-amount-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  padding-right: 10px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #303030;
  justify-content: flex-end;
}

.emiPlans-choosePlan-widget {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border-radius: 12px;
  border-width: 1px;
  border-color: #ececec;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
}

.emiPlans-choosePlan-header {
  width: 100%;
}

.emiPlans-choosePlan-header-div {
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #f8efef;
}

.emiPlans-choosePlan-header-title-text {
  font-family: "Inter";
  font-style: normal;
  padding: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 0px;
}

.table-header {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #f8efef;
}
.table-cell > input[type="radio"] {
  margin-top: 7px;
}

.emiPlans-choosePlan-header-subTitle-text {
  font-family: "Inter";
  font-style: normal;
  padding: 16px;
  line-height: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 0px;
  font-size: 11px;
  font-weight: 400;
  padding-top: 4px;
}

.emiPlans-tnc-div {
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 5px;
}

.emiPlans-tnc-div-1 {
  width: 100%;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 20px;
}

.emiPlans-tnc-text-div {
  margin-left: 24px;
  margin-bottom: 4px;
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}

.emiPlans-tnc-text {
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
}

.emiPlans-tnc-link {
  margin-left: 4px;
}

.emiPlans-tnc-text-2 {
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
}

.emiPlans-cardInterface-v1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.emiPlans-transaction-title-div-v1 {
  align-items: center;
  margin-top: 4px;
}

.emiPlans-transaction-title-text-v1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #303030;
}

.emiPlans-tax-amount-text-div-v1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #303030;
  margin-top: 8px;
}

.emiPlans-choose-plan-div,
.emiPlans-choose-plan-div-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: auto;
  width: 95%;
}

.emiPlans-choose-plan-text {
  font-family: "Inter";
  font-style: normal;
  padding: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-left: 0px;
}

.emiPlans-monthlyPlanTextBox {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 2px;
  background: #ffe5a6;
  border-radius: 2px;
}

.emiPlans-monthlyPlanText {
  font-family: "Inter";
  font-style: "normal";
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
}

.emiPlans-map-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 20px;
  width: 100%;
  border-radius: 8px;
  margin: 0px;
}

.emiPlans-amountPerMonth-text {
  font-family: "Inter";
  font-style: normal;
  padding-top: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.emiPlans-monthlyPlanIntrestBox-div {
  gap: 10px;
  display: flex;
  flex-direction: row;
}

.emiPlans-monthlyPlanIntrestBox-div-text,
.emiPlans-monthlyPlanIntrest-text-1,
.emiPlans-monthlyPlanIntrest-text-2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #7b7b7b;
  mix-blend-mode: normal;
  letter-spacing: 0.04em;
}

.emiPlans-monthlyPlanDuration-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #303030;
}

.emiPlans-tnc-div-v1 {
  width: 95%;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 20px;
  margin: auto;
}

.emiPlans-tnc-text-div-v1 {
  margin-bottom: 4px;
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: 3px;
}

.emiPlans-tnc-link-div {
  margin-left: 4px;
}

.emiPlans-tnc-link-text {
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
}

.emiPlans-tnc-box {
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

/* EMI plans screen end */

/* card and balance screen start */
.cardAndBalance-vertivalCard,
.cardAndBalance-horizontalCard {
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 3.84px;
  opacity: 1;
  position: relative;
}

.cardAndBalance-vertivalCard-hideVirtualCard,
.cardAndBalance-horizontalCard-hideVirtualCard {
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 3.84px;
  opacity: 0.6;
  position: relative;
}
.cardAndBalance-vertivalCard-img {
  width: 165px;
  height: 261px;
  border-radius: 12px;
}
.cardAndBalance-vertivalCard-img-right-overlayIcon {
  width: 15px;
  height: 70px;
  border-radius: 12px;
  position: absolute;
  left: 152px;
  top: 95px;
}
.cardAndBalance-vertivalCard-img-bottom-overlayIcon {
  width: 70px;
  height: 15px;
  border-radius: 12px;
  position: absolute;
  top: 249px;
  left: 49px;
}
.cardAndBalance-horizontalCard {
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 3.84px;
  opacity: 1;
}
.cardAndBalance-horizontalCard-img {
  width: 261px;
  height: 165px;
}
.cardAndBalance-horizontalCard-img-bottom-overlayIcon {
  width: 70px;
  height: 15px;
  border-radius: 12px;
  position: absolute;
  top: 153px;
  right: 95px;
}
.cardAndBalance-horizontalCard-img-right-overlayIcon {
  width: 14px;
  height: 70px;
  border-radius: 12px;
  position: absolute;
  top: 46px;
  right: -2px;
}
.cardAndBalance-vertivalCard-viewCardAnimation,
.cardAndBalance-horizontalCard-viewCardAnimation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cardAndBalance-vertivalCard-viewCardAnimation-widget {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  align-self: center;
  justify-self: center;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 3.84px;
  height: 261px;
  width: 165px;
  cursor: pointer;
  border: 1px solid #ececec;
}
.cardAndBalance-horizontalCard-viewCardAnimation-widget {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  align-self: center;
  justify-self: center;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 3.84px;
  height: 165px;
  width: 261px;
  cursor: pointer;
  padding-top: 9px;
  position: relative;
}
.cardAndBalance-horizontalCard-viewCardAnimation-img {
  height: 130px;
  width: 222px;
  margin: auto;
}
.cardAndBalance-vertivalCard-viewCardAnimation-img {
  margin: auto;
  height: 212px;
  width: 135px;
  border-radius: 12px;
}
.cardAndBalance-horizontalCard-viewCardAnimation-text {
  font-weight: bold;
  padding-bottom: 4px;
  text-align: center;
}
.cardAndBalance-vertivalCard-viewCardAnimation-text {
  font-weight: bold;
  margin-top: -10px;
  padding-bottom: 6px;
  text-align: center;
}
.cardAndBalance,
.cardAndBalance-horizontalOrientation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.rewards-v2-pending-cashback-text {
  font-size: 10px;
  opacity: 0.7;
}
.rewards-v2-pending-cashback-amount {
  font-size: 12px;
  font-weight: 600;
}

.cardAndBalance-horizontalOrientation-rewards {
  margin-top: 12px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid #ececec;
  box-shadow: rgb(0 0 0 / 22%) 0px 1px 2.22px;
}
.cardAndBalance-horizontalOrientation-rewards-content {
  width: 57%;
  margin: 12px 16px 14px 16px;
  padding: 12px;
  border-radius: 6px;
  background-color: #ececec;
}
.cardAndBalance-horizontalOrientation-rewardsEmpty-text,
.cardAndBalance-horizontalOrientation-rewardsDescription-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.cardAndBalance-horizontalOrientation-rewards-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardAndBalance-horizontalOrientation-rewards-div-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
}
.cardAndBalance-horizontalOrientation-rewards-bannerImg-container {
  width: 36%;
  margin-top: -15px;
}
.cardAndBalance-horizontalOrientation-rewards-bannerImg {
  width: 96px;
  height: 96px;
  margin: 4px;
  margin-left: 10px;
}
.cardAndBalance-horizontalOrientation-stripMsg {
  width: 100%;
  align-items: center;
  display: flex;
  padding: 12px 24px;
  position: absolute;
  z-index: 1;
  top: 43%;
}
.cardAndBalance-horizontalOrientation-stripMsg-text {
  margin: auto;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
}
.cardAndBalance-verticalOrientation {
  width: 90%;
}

.cardAndBalance-verticalOrientation-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.cardAndBalance-verticalOrientation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardAndBalance-verticalOrientation-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  position: relative;
}
.cardAndBalance-verticalOrientation-rewards {
  width: 45%;
  margin-left: 12px;
  height: 261px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #ececec;
  box-shadow: rgb(0 0 0 / 22%) 0px 1px 2.22px;
  flex-direction: column;
}
.cardAndBalance-verticalOrientation-rewards-bannerImg {
  width: 170px;
  height: 170px;
  margin-top: -15px;
  margin-left: -5px;
  padding: 16px;
}
.cardAndBalance-verticalOrientation-rewardsEmpty-container {
  border-radius: 4px;
  width: 85%;
  padding: 9px;
  margin-top: 15px;
}
.cardAndBalance-verticalOrientation-rewardsEmpty-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.cardAndBalance-verticalOrientation-rewards-content {
  border-radius: 4px;
  width: 80%;
  padding: 9px;
}
.cardAndBalance-verticalOrientation-rewardsDescription-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.cardAndBalance-verticalOrientation-rewards-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardAndBalance-verticalOrientation-rewards-div-null-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
}
.cardAndBalance-verticalOrientation-rewards-div-text {
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.48px;
}

.cardAndBalance-verticalOrientation-stripMsg-rewardsEnabled {
  display: block;
  position: relative;
}

.cardAndBalance-verticalOrientation-stripMsg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.cardAndBalance-verticalOrientation-stripMsg-container {
  width: 165px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 12px 6px;
  position: absolute;
  bottom: 108px;
  z-index: 1;
}
.cardAndBalance-verticalOrientation-stripMsg-text {
  margin: auto;
  width: 88%;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: center;
}

.cardAndBalance-cardSummary {
  margin-top: 24px;
  width: 90%;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #ececec;
  box-shadow: rgb(0 0 0 / 22%) 0px 1px 2.22px;
}
.cardAndBalance-cardSummary-accordian {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px;
}

.cardAndBalance-cardSummary-accordian-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.cardAndBalance-cardSummary-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px 16px;
}
.cardAndBalance-cardSummary-content-text,
.cardAndBalance-cardSummary-content-text-1,
.cardAndBalance-cardSummary-content-text-2 {
  font-family: Inter;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.16em;
  line-height: 13px;
  color: #303030;
  opacity: 0.64;
}
.cardAndBalance-cardSummary-content-amount,
.cardAndBalance-cardSummary-content-amount-1,
.cardAndBalance-cardSummary-content-amount-2 {
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  margin-top: 2px;
  color: #303030;
}
.cardAndBalance-cardSummary-content-repaymentFlow-button {
  width: 48%;
}
.cardAndBalance-cardSummary-repaymentFlow-container {
  display: flex;
  border-top: 2px solid #ececec;
  flex-direction: column;
  padding: 12px 16px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.cardAndBalance-cardSummary-content-repaymentFlow-container-noReminder,
.cardAndBalance-cardSummary-content-div-noReminder {
  display: none;
}

.horizontal-card-width-height-for-all {
  width: 342px !important;
  height: 205px !important;
}

.horizontal-card-width-height-view-card-for-all {
  width: 300px !important;
  height: 155px !important;
}

.cardAndBalance-cardSummary-content-repaymentFlow-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: column; */
}
.cardAndBalance-cardSummary-content-repaymentFlow-content-div {
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.cardAndBalance-cardSummary-content-repaymentFlow-content-div-amount {
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #303030;
  letter-spacing: 0.02em;
}
.cardAndBalance-cardSummary-content-repaymentFlow-content-div-text {
  margin-left: 4px;
  font-family: Inter;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}
.cardAndBalance-cardSummary-content-repaymentFlow-content-div-text-v2_1 {
  background-color: #ececec;
  padding: 2px 8px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
}
.cardAndBalance-cardSummary-content-repaymentFlow-content-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardAndBalance-cardSummary-content-repaymentFlow-content-button-1 {
  cursor: pointer;
  width: 100%;
  padding: 12px 5%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  border: 1px solid #fff;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.cardAndBalance-cardSummary-content-repaymentFlow-content-button-2 {
  cursor: pointer;
  margin-left: 12px;
  width: 100%;
  padding: 12px 5%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.cardAndBalance-cardSummary-content-div {
  display: flex;
  padding: 12px 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.cardAndBalance-cardSummary-content-div-1 {
  display: flex;
  align-items: center;
}
.cardAndBalance-cardSummary-content-div-1-amount,
.cardAndBalance-cardSummary-content-div-1-date {
  margin-right: 4px;
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  letter-spacing: 0.02em;
}
.cardAndBalance-cardSummary-content-div-1-text {
  margin: 0px 6px 0px 3px;
  color: #fff;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

/* card and balance screen end */

/* Benefit Widget Skeleton begin */

.skeleton-home-benefits-widget {
  border: 1px solid #eeeeee;
  border-radius: 0.75rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  margin-top: 24px;
  box-shadow: 0px 1px 4px 0px #30303029;
}

.skeleton-benifits-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafaff;
  padding: 3px;
}

.skeleton-benifits-left-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.skeleton-benifits-header-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.125rem;
  width: 6.5rem;
  height: 2.125rem;
}

.skeleton-benifits-header-title {
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  text-align: left;
}

.skeleton-benifits-header-org-name {
  width: 3.063rem;
  height: 0.75rem;
  opacity: 40%;
}

.skeleton-benifits-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  gap: 0.2rem;
  width: 90%;
  position: relative;
  padding: 15px 3px;
}

.skeleton-benefits-body-header {
  display: flex;
  gap: 8px;
  align-items: center;
}

.skeleton-benefits-body-header-text {
  font-size: 0.95rem;
  font-weight: 500;
  color: #374151;
}

.skeleton-benefits-body-text {
  padding-top: 8px;
  font-size: 0.788rem;
  color: #4b5563;
}

.skeleton-benifits-availabe-amount-header {
  font-size: 12px;
  font-weight: 400;
  color: #7b7b7b;
}

.skeleton-benifits-amount {
  display: flex;
  align-items: center;
  height: 1.5rem;
  width: 100%;
  gap: 4px;
}

.skeleton-benifits-amount-available {
  font-size: 20px;
  font-weight: 600;
}

.skeleton-benifits-total-amount {
  display: flex;
  padding-top: 4px;
  gap: 4px;
}

.skeleton-benifits-amount-outof {
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
}

.skeleton-benifits-amount-totalLoadedAmount {
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
}

.skeleton-benifits-amount-target {
  font-size: 1rem;
  font-weight: 500;
  opacity: 40%;
}

.skeleton-benifits-footer {
  margin-top: 10px;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
  padding-top: 12px;
}

.skeleton-benifits-last-loaded-text {
  font-size: 12px;
  color: #4b5563;
  font-weight: 400;
}

.skeleton-benifits-last-loaded-info {
  font-size: 12px;
  font-weight: 600;
  color: #101727;
}
/* Benefit Widget Skeletion end */

/* Benefit widget css begin*/

.home-benefits-widget {
  border: 1px solid #eeeeee;
  border-radius: 0.75rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  box-shadow: 0px 1px 4px 0px #30303029;
}

.benifits-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafaff;
  padding: 3px;
}

.benifits-left-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.benefits-right-chevron {
  padding-right: 16px;
  width: 35px;
  height: 40px;
}

.benifits-header-image img {
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0px 1px 4px 0px #30303029;
  background: #ffffff;
  border-radius: 2.5rem;
  /* padding: 0.625rem; */
}

.benifits-header-text {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  width: 6.5rem;
  height: 2.125rem;
}

.benifits-header-title {
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  text-align: left;
}

.benifits-header-org-name {
  font-family: Inter;
  font-size: 0.5rem;
  font-weight: 600;
  line-height: 0.75rem;
  letter-spacing: 0em;
  text-align: left;
  width: 3.063rem;
  height: 0.75rem;
  opacity: 40%;
}

.benifits-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  gap: 0.2rem;
  width: 90%;
  position: relative;
  padding: 15px 3px;
}

.benefits-body-header {
  display: flex;
  gap: 8px;
  align-items: center;
}

.benefits-body-header-text {
  font-size: 0.95rem;
  font-weight: 500;
  color: #374151;
}

.benefits-body-text {
  padding-top: 8px;
  font-size: 0.788rem;
  color: #4b5563;
}

.benifits-availabe-amount-header {
  font-size: 12px;
  font-weight: 400;
  color: #7b7b7b;
}

.benifits-amount {
  display: flex;
  align-items: center;
  height: 1.5rem;
  width: 100%;
}

.benifits-amount-available {
  font-size: 20px;
  font-weight: 600;
}

.benifits-total-amount {
  display: flex;
  padding-top: 4px;
  gap: 4px;
}

.benifits-amount-outof {
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
}

.benifits-amount-totalLoadedAmount {
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
}

.benifits-amount-target {
  font-size: 1rem;
  font-weight: 500;
  opacity: 40%;
}

.benifits-footer {
  margin-top: 10px;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
  padding-top: 12px;
}

.benifits-last-loaded-text {
  font-size: 12px;
  color: #4b5563;
  font-weight: 400;
}

.benifits-last-loaded-info {
  font-size: 12px;
  font-weight: 600;
  color: #101727;
}

.benefits-decimal-amount-available {
  padding-top: 4px;
}

/* benefits css end */

/* v3 multwiallet widget styles */
.v3-home-benefits-widget-background {
  background-color: var(--primary-color-4);
  padding: 28px 4px 36px 4px;
}

.v3-home-benefits-widget {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  gap: 14px;
  border-radius: 18px;
  padding: 15px 15px;
  position: relative;
  background-color: var(--leading-white);
  box-shadow: 0px 10px 31px -2px #00000017;
}

.v3-benefits-top-section {
  width: 100%;
}

.v3-benifits-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.v3-benifits-left-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.v3-benefits-right-chevron {
  height: 40px;
  margin-top: 5px;
}

.v3-benifits-header-image {
  width: 45px;
  height: 45px;
  border: 1px solid var(--primary-base-color-5);
  background: var(--leading-white);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v3-benifits-header-text {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  height: 2.125rem;
}

.v3-benifits-header-title {
  font-family: Inter;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  text-align: left;
}

.v3-benifits-header-org-name {
  font-family: Inter;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 0.75rem;
  letter-spacing: 0em;
  text-align: left;
  width: 3.063rem;
  height: 0.75rem;
  opacity: 40%;
}

.v3-benifits-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 5px;
  gap: 0.2rem;
  position: relative;
}

.v3-benefits-body-header-icon {
  padding-right: 4px;
}

.v3-benefits-body-header {
  display: flex;
  gap: 8px;
}

.v3-benefits-body-header-text {
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--primary-base-color-1);
}

.v3-benefits-body-text {
  font-size: 0.563rem;
  font-weight: 500;
  color: var(--primary-base-color-2);
  padding: 0px 15px;
}

.v3-benifits-availabe-amount-header {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--primary-base-color-2);
}

.v3-benifits-amount {
  display: flex;
  align-items: center;
  height: 1rem;
  width: 100%;
  gap: 4px;
}

.v3-benifits-amount-available {
  font-size: 20px;
  font-weight: 600;
}

.v3-benifits-total-amount {
  display: flex;
  padding-top: 4px;
  gap: 4px;
}

.v3-benifits-amount-outof {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-base-color-2);
}

.v3-benifits-amount-totalLoadedAmount {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-base-color-2);
}

.v3-benifits-amount-target {
  font-size: 1rem;
  font-weight: 500;
  opacity: 40%;
}

.v3-benifits-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  background-color: var(--primary-color-5);
  padding: 4px 10px 4px 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius: 8px;
}

.v3-benifits-last-loaded-text {
  font-size: 0.625rem;
  color: var(--primary-base-color-2);
  font-weight: 500;
}

.v3-benifits-last-loaded-info {
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--primary-base-color-1);
}

.v3-benefits-decimal-amount-available {
  padding-top: 4px;
}

/* v3 multiwallet widget styles end */

.rewards-earned-redeemed-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
}

.skeleton-benifits-left-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.skeleton-benifits-header-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.125rem;
  width: 6.5rem;
  height: 2.125rem;
}

.skeleton-benifits-header-title {
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  text-align: left;
}

.skeleton-benifits-header-org-name {
  width: 3.063rem;
  height: 0.75rem;
  opacity: 40%;
}

.skeleton-benifits-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  gap: 0.2rem;
  width: 90%;
  position: relative;
  padding: 15px 3px;
}

.skeleton-benefits-body-header {
  display: flex;
  gap: 8px;
  align-items: center;
}

.skeleton-benefits-body-header-text {
  font-size: 0.95rem;
  font-weight: 500;
  color: #374151;
}

.skeleton-benefits-body-text {
  padding-top: 8px;
  font-size: 0.788rem;
  color: #4b5563;
}

.skeleton-benifits-availabe-amount-header {
  font-size: 12px;
  font-weight: 400;
  color: #7b7b7b;
}

.skeleton-benifits-amount {
  display: flex;
  align-items: center;
  height: 1.5rem;
  width: 100%;
  gap: 4px;
}

.skeleton-benifits-amount-available {
  font-size: 20px;
  font-weight: 600;
}

.skeleton-benifits-total-amount {
  display: flex;
  padding-top: 4px;
  gap: 4px;
}

.skeleton-benifits-amount-outof {
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
}

.skeleton-benifits-amount-totalLoadedAmount {
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
}

.skeleton-benifits-amount-target {
  font-size: 1rem;
  font-weight: 500;
  opacity: 40%;
}

.skeleton-benifits-footer {
  margin-top: 10px;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
  padding-top: 12px;
}

.skeleton-benifits-last-loaded-text {
  font-size: 12px;
  color: #4b5563;
  font-weight: 400;
}

.skeleton-benifits-last-loaded-info {
  font-size: 12px;
  font-weight: 600;
  color: #101727;
}
/* Benefit Widget Skeletion end */

/* Benefit widget css begin*/

.home-benefits-widget {
  border: 1px solid #eeeeee;
  border-radius: 0.75rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  box-shadow: 0px 1px 4px 0px #30303029;
}

.benifits-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafaff;
  padding: 3px;
}

.benifits-left-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.benefits-right-chevron {
  padding-right: 16px;
  width: 35px;
  height: 40px;
}

.benifits-header-image img {
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0px 1px 4px 0px #30303029;
  background: #ffffff;
  border-radius: 2.5rem;
  /* padding: 0.625rem; */
}

.benifits-header-text {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  width: 6.5rem;
  height: 2.125rem;
}

.benifits-header-title {
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  text-align: left;
}

.benifits-header-org-name {
  font-family: Inter;
  font-size: 0.5rem;
  font-weight: 600;
  line-height: 0.75rem;
  letter-spacing: 0em;
  text-align: left;
  width: 3.063rem;
  height: 0.75rem;
  opacity: 40%;
}

.benifits-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  gap: 0.2rem;
  width: 90%;
  position: relative;
  padding: 15px 3px;
}

.benefits-body-header {
  display: flex;
  gap: 8px;
  align-items: center;
}

.benefits-body-header-text {
  font-size: 0.95rem;
  font-weight: 500;
  color: #374151;
}

.benefits-body-text {
  padding-top: 8px;
  font-size: 0.788rem;
  color: #4b5563;
}

.benifits-availabe-amount-header {
  font-size: 12px;
  font-weight: 400;
  color: #7b7b7b;
}

.benifits-amount {
  display: flex;
  align-items: center;
  height: 1.5rem;
  width: 100%;
}

.benifits-amount-available {
  font-size: 20px;
  font-weight: 600;
}

.benifits-total-amount {
  display: flex;
  padding-top: 4px;
  gap: 4px;
}

.benifits-amount-outof {
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
}

.benifits-amount-totalLoadedAmount {
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
}

.benifits-amount-target {
  font-size: 1rem;
  font-weight: 500;
  opacity: 40%;
}

.benifits-footer {
  margin-top: 10px;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
  padding-top: 12px;
}

.benifits-last-loaded-text {
  font-size: 12px;
  color: #4b5563;
  font-weight: 400;
}

.benifits-last-loaded-info {
  font-size: 12px;
  font-weight: 600;
  color: #101727;
}

.benefits-decimal-amount-available {
  padding-top: 4px;
}

/* benefits css end */

.rewards-earned-redeemed-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
}

.rewards-redeem-amount {
  font-weight: 600;
}
.rewards-earned-amount {
  font-weight: 600;
}

.mainContainer {
  padding: 0px 16px;
}

.widget {
  z-index: 1;
  padding: 24px 16px;
  border-radius: 12px;
  border: 1px;
  box-shadow: 0px 1px 4px 0px #30303029;
  width: 100%;
}
.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headingText {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.04em;
  text-align: left;
}
.headingBox {
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  background-color: #fadcd9;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.08em;
  text-align: left;
}
.subHeading {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 24px;
}
.radioButton {
  margin-bottom: 24px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.paymentGatewayBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 16px;
  max-width: 450px;
}
.inputContainer {
  border: 1px solid #cccccc;
  outline: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  background-color: #fff;
  width: 90%;
  margin: auto;
}
.currencyInput {
  color: #000;
  margin-right: 5px;
  /* font-size: 20px; */
}
.otherAmount {
  border: none;
  outline: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.optionsWidget {
  z-index: 1;
  padding: 24px 0px;
  border-radius: 12px;
  border: 1px;
  box-shadow: 0px 1px 4px 0px #30303029;
  width: 100%;
}
.optionsContainer {
  padding: 0px 16px;
}
.optionsHeading {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  opacity: 0.5;
}
.optionsAmount {
  margin-top: 10px;
  margin-bottom: 24px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
}
.borderLine {
  border: 1px solid #ececec;
}
.optionsHeading2 {
  margin: 24px 0px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: left;
}
.optionsContent {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px -6px;
}

.optionsContentpg {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px -16px;
  justify-content: center;
  margin-left: -21px;
}
.optionsContentHeading {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 5px;
}
.optionsContentText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  opacity: 0.5;
}
.borderLine2 {
  border: 1px solid #ececec;
  width: 90%;
  margin: auto;
}

.optionsUpiVerifyHeading2 {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-top: 25px;
  margin-left: 3px;
}

.inputContainerVerifyUpi {
  border: 2px solid #cccccc;
  outline: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 10px 10px 10px 10px;
  border-radius: 6px;
  background-color: #ffffff;
  margin: auto;
  margin-top: 15px;
}
.default-header-heading {
  width: 100%;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  text-align: center;
}
.home-transactionSection-unbilled-text-v2_1 {
  font-size: 12px;
  line-height: 16px;
  color: #676161;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -5px;
}

.home-transactionSection-unbilled-text-v2_1-viewHere {
  font-weight: 600;
}

.home-transactionSection-loadmore-text-v2_1 {
  font-size: 12px;
  line-height: 16px;
  color: #676161;
  text-align: center;
}

.home-transactionSection-loadmore-text-v2_1-viewHere {
  font-weight: 600;
  cursor: pointer;
}
.offerList-searchBox-div {
  outline: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 10px 10px 10px 10px;
  border-radius: 6px;
  background-color: #ffffff;
  margin: auto;
  margin-top: 15px;
}
.otherAmountVerifyUpi {
  border: none;
  outline: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  width: 71%;
}

.currencyInputVerifyUpi {
  cursor: pointer;
  color: #ec5b24;
  float: right;
  font-weight: bold;
}

.optionsWidgetVerifyUpi {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.verifyUpiTypesContainer {
  display: flex;
  overflow-x: scroll;
  margin-left: 12px;
  margin-right: 12px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.verifyUpiTypesContainer::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.verifyUpiTypes {
  padding: 4px 6px 4px 6px;
  margin: 5px;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #787878;
  font-size: 10px;
  margin-top: 15px;
}

.verifyUpiVerified {
  color: #25a977;
}

.verifyOtpAccNameHeading {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: 18px;
  color: #272727;
  margin-top: 30px;
}

.verifyOtpAccName {
  margin-left: 17px;
  font-weight: 600;
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;
}

.paymentReqTopContainer {
  margin-top: 40px;
  margin-left: 30px;
  margin-right: 30px;
}

.paymentReqText {
  font-size: 12px;
  color: #222222;
  text-align: center;
}

.paymentReqAmount {
  font-size: 24px;
  text-align: center;
  color: #ec5b25;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 600;
}

.paymentReqHighlight {
  color: #ec5b24 !important;
}

.paymentReqSent {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.paymentReqOpenAppText {
  margin: 8px;
  font-size: 12px;
  text-align: center;
  margin-top: 70px;
  color: #222222;
  font-weight: 400;
}
.paymentReqAppName {
  font-weight: 500;
}

.paymentReqTimeLineColored {
  height: 4px;
  display: inline-block;
  background: #ec5b24;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.paymentReqTimeLineGreyed {
  height: 4px;
  display: inline-block;
  background: #c6c3c3;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.paymentReqRetryPayment {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  color: #7b7b7b;
}
.paymentReqStepsContainer {
  background-color: #fefaf5;
  border-radius: 12px;
  padding: 10px;
  margin-top: 80px;
  padding-right: 15px;
}
.paymentReqStepsNumContainer {
}

.paymentReqStepsHeading {
  font-size: 14px;
  font-weight: 600;
  margin-left: 22px;
  padding-top: 6px;
}

.paymentReqStepsNum {
  color: #ec5b24;
  border: 1px solid #ececec;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  background: white;
  height: 32px;
}

.paymentReqStepsText {
  color: #000000;
  opacity: 1;
}

.paymentReqBottomImageContainer {
  display: flex;
  justify-content: space-between;
  margin: 30px;
  margin-left: 60px;
  margin-right: 60px;
}

.paymentSuccessContentContainer {
  margin: auto;
  margin-top: 22vh;
  width: 71%;
}

.paymentSuccessIcon {
  margin: auto;
  text-align: center;
}

.paymentSuccessTitle {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 23px;
}

.paymentSuccessDesc {
  text-align: center;
  font-size: 13px;
  margin-top: 5px;
}

.paymentSuccessAmountPaid {
  margin: auto;
  text-align: center;
  margin-top: 30px;
  padding: 10px;
  border: 1px solid #ececec;
  width: 75%;
  border-radius: 8px;
}

.paymentSuccessAmountPaidText {
  font-size: 14px;
  color: #7b7b7b;
  padding: 5px;
}

.paymentSuccessAmountPaidAmount {
  font-weight: 600;
  font-size: 16px;
  padding: 3px;
}

.paymentFailedBottomTextContainer {
  text-align: center;
  position: absolute;
  bottom: 85px;
  width: 100%;
  font-size: 16px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.paymentFailedBottomTextContainerText {
  font-weight: 500;
  font-size: 14px;
}

.offerList-searchbox-input {
  border: none;
  outline: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  width: 88%;
  margin-left: 10px;
  color: #272727;
}

.rewards-v2_1-note {
  font-weight: 400;
  color: #7b7b7b;
  font-size: 11px;
  line-height: 14px;
  margin-top: 14px;
}

.labelText {
  font-weight: 400;
}

.labelAmount {
  font-weight: 400;
}

.home-cardDetailsWidget-eyeicon-horizontal {
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
}

.generic-circular-loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin-generic-loader 1s linear infinite;
}

@keyframes spin-generic-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 340px) {
  .hf-btn {
    font-size: 13px !important;
  }
}
:root {
  --rsbs-backdrop-bg: var(--modal-overlay-bg, rgba(0, 0, 0, 0.86));
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

@media screen and (max-width: 390px) {
  .dateSelect-container-box-dropdown-contentOpen-year {
    width: 38%;
  }
  .dateSelect-container-box-dropdown-contentOpen-month {
    width: 38%;
  }
}

.v3-header-container {
  width: 100%;
  z-index: 10;
}
.v3-header-top-section {
  background-color: var(--primary-base-color-1);
  display: flex;
  width: 100%;
  height: 48px;
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  /* max-width: 450px; */
  margin: auto;
}
.v3-acs-desktop-header {
  max-width: 100%;
}
.v3-header-top-logo {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 100%;
  /* margin-left: 25%; */
}
.v3-header-top-logo-seperator {
  height: 100%;
  border-left: solid var(--primary-base-color-2) 1px;
  margin: 0px 5px;
}
.v3-header-top-logo-seperator {
  height: 100%;
  border-left: solid var(--primary-base-color-2) 1px;
  margin: 0px 5px;
}
.v3-header-top-logo > img {
  height: 100%;
}
.v3-header-top-logo-center {
  margin-right: auto;
  margin-left: auto;
}
.v3-header-top-text {
  color: var(--leading-white);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.v3-header-top-close {
  position: relative;
  right: -10px;
}
.v3-header-top-text-center {
  margin-left: 50%;
  transform: translateX(-50%);
}
.v3-loader-header-text-center {
  text-align: center;
}
.v3-header-home-text-center {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}
.v3-header-bottom-section {
  background-color: var(--primary-base-color-5);
  display: flex;
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  flex-shrink: 0;
  gap: 1%;
}
.v3-header-text {
  color: var(--tertiary-neutral-color-1);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.home-v3 {
  width: 100%;
  background-color: var(--leading-white);
}
.v3-home-cardDetails {
  padding: 42px 36px 56px 36px;
  background-color: var(--primary-color-4);
  position: relative;
}
.v3-home-cardDetails-overlay {
  transition: all 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--tertiary-neutral-color-1);
  opacity: 0.62;
}
.v3-home-drawer {
  display: flex;
  width: 100%;
  padding: 5px 0px 0px 0px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px 12px 0px 0px;
  background-color: var(--leading-white);
  margin-top: -16px;
}
.v3-home-drawer-section {
  transition: "margin 0.4s ease";
  z-index: 999;
  width: 100%;
  box-shadow: 0px 10px 31px -2px #00000017;
}
.v3-home-drawer-handle {
  margin: 12px auto 20px auto;
}
.v3-home-page {
  border-radius: none;
}
.v3-home-card-details-title {
  color: var(--leading-white);
  text-align: center;
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 15px;
}
.v3-home-card-details-action-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 79px;
}
.v3-home-card-details-action-icons-img {
  display: flex;
  padding: 9px;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background: #fff;
  flex-wrap: wrap;
  height: 42px;
  width: 42px;
  cursor: pointer;
  background-color: var(--leading-white);
  box-shadow: 0px 10px 31px -2px #00000017;
}
.v3-home-card-details-action-icons-text {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 12px */
  width: 50px;
  margin-top: 8px;
}
.v3-home-card-details-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
}
.v3-home-card-details-settings {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 18px;
  margin-top: 36px;
}

.v3-nudge {
  display: flex;
  width: 343px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  background: #fff;
  cursor: pointer;
  user-select: none;
  width: 100%;
}
.v3-nudge.type-1 {
  height: 70px;
  max-height: 70px;
}
.v3-nudge.type-2 {
  display: flex;
  width: 343px;
  padding: 8px 12px 8px 12px;
  justify-content: space-around;
  align-items: center;
  border-radius: 999px;
  border: 1px solid #dfdfdf;
  background: #fff;
  cursor: pointer;
  user-select: none;
  width: 100%;
  height: 70px;
  max-height: 70px;
}
.v3-nudge-icon {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-nudge-icon img {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
}
.v3-nudge-body {
  display: flex;
  width: 224px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 80%;
}
.v3-nudge-body.type-2 {
  display: flex;
  width: 224px;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 80%;
}
.v3-nudge.type-3 {
  border: none;
  gap: 8px;
  overflow-x: scroll;
}
.v3-nudge.type-3::-webkit-scrollbar {
  display: none;
}
.v3-nudge-container.type-3 {
  width: fit-content;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  min-height: 140px;
  min-width: 180px;
}
.v3-nudge-container-background-image.type-3 {
  position: absolute;
  right: 0;
  top: 0;
}
.v3-nudge-title.type-3 {
  font-size: 0.75rem;
  font-weight: 500;
  color: #323232;
  max-width: 80%;
}
.v3-nudge-body-text.type-3 {
  font-size: 0.725rem;
  font-weight: 400;
  color: #696969;
  display: block;
  text-align: justify;
  max-width: 70%;
}
.v3-nudge-body-image.type-3 {
  position: absolute;
  right: 2%;
  bottom: 5%;
}
.v3-nudge-body-image.type-3 img {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
}
.nudge-slider-container {
  max-width: 100% !important;
}
.v3-nudge-body-title {
  color: var(--color-static-UI-Header-text, #343434);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.v3-nudge-body-sub-title {
  color: var(--color-static-ui-sub-text-s, #323232);
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.v3-nudge-index {
  display: flex;
  width: 15px;
  height: 5px;
  align-items: center;
  gap: 2px;
  width: 5%;
}
.v3-line-breaker {
  display: flex;
  /* padding: 0px 0px; */
  align-items: center;
  align-self: stretch;
  justify-content: center;
}
.v3-line-breaker-line {
  width: 100%;
  height: 0.5px;
  background: var(--primary-base-color-4);
}
.v3-home-drawer-tabs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #0000001f;
  margin: 0px auto;
}
.v3-home-drawer-tabs-item {
  display: flex;
  height: 52px;
  padding: 14px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  width: 50%;
  cursor: pointer;
}
.v3-home-drawer-tab-item-active.v3-home-drawer-tabs-item {
  border-bottom: 3px solid var(--secondary-color-1);
}
.v3-home-drawer-tabs-item-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.v3-home-drawer-tabs-item-text {
  color: var(--tertiary-neutral-color-2);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px; /* 114.286% */
}
.v3-home-drawer-tab-item-active .v3-home-drawer-tabs-item-text {
  color: var(--secondary-color-1);
}
.v3-home-drawer-content {
  width: 100%;
  /* margin-top: 10px; */
}
.offers-container {
  opacity: 0;
  transition: all 0.5s;
}
.offers-container.active {
  background-color: var(--primary-color-5);
  opacity: 1;
  transition: all 0.5s;
}
.v3-offers-section-1-value-text {
  color: #75690b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}
.v3-offers-section-1-value-icon {
  width: 18px;
  height: 17px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v3-offers-section-1-value {
  display: flex;
  width: 67px;
  align-items: center;
  gap: 4px;
  justify-content: center;
}
.v3-offers-section-1-text {
  color: #574e01;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.v3-offers-section-header {
  display: flex;

  justify-content: space-between;
  align-items: center;
  margin: 0px 20px;
}
.v3-offers-section-header-title {
  color: var(--primary-base-color-1, #343434);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.v3-offers-section-header-cta {
  color: var(--tertiary-cta-text-color, #ff6b00);
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  text-decoration-line: underline;
  cursor: pointer;
}
.v3-offers-section-2 {
  padding: 12.5px 0px;
}

/* card settings v3 starts */

.v3-card-settings-transactions-daily-limit-text {
  align-self: center;
  color: var(--primary-base-color-2);
  opacity: 0.8;
}

/* other css properties of this class are same as of v2 */
.v3-cardSetting-settings-transaction-amount-section {
  /* margin-right: 10px; */
  text-align: right;
}

.v3-cardSetting-settings-transaction-slider-box-inner {
  width: 100%;
}
.v3-card-settings-daily-limit-amount-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.v3-cardSettings-domestic-international-container {
  margin-bottom: 20px;
}

.v3-cardSettings-domestic-international-topDiv-container {
  margin-top: 0px;
  padding: 0px;
}

.v3-cardSettings-domestic-international-topDiv {
  box-shadow: none;
}
.v3-cardSetting-settings-container {
  box-shadow: none;
  margin-bottom: 20px;
  border: var(--primary-base-color-4) 1px solid;
  border-radius: 12px;
  padding: 0px;
}

.v3-cardSetting-settings {
  border-bottom: none !important;
}

.v3-cardSettings-domestic-div,
.v3-cardSettings-international-div {
  padding: 14px 0 10px;
  font-family: Mulish;
  box-sizing: border-box;
}

.v3-cardSetting-settings-transaction-section-div {
  padding: 12px;
  border-bottom: var(--primary-base-color-4) 1px solid;
  /* padding-bottom: 5px; */
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  align-items: center;
}

.v3-card-settings-daily-limit-amount-div-container {
  padding: 16px;
}

.v3-card-settings-domestic-icon {
  margin-bottom: 2px;
  margin-right: 8px;
}
.v3-card-settings-international-icon {
  margin-bottom: 2px;
  margin-right: 8px;
}

/* card settings v3 ends */

/* otp input bottom sheet starts */
.v3-otp-input-container {
  display: flex;
}

.v3-otp-digit-input {
  width: 45%;
  height: 50px;
  font-size: 20px;
  text-align: center;
  margin: 10px 2%;
  border: none;
  outline: none;
  max-width: 75px;
  margin-bottom: 20px;
  border-bottom: solid gray 1px;
}

.v3-otp-digit-invalid {
  border-bottom: solid red 1px;
}

.v3-otp-bottomSheet-error-message {
  margin-left: 20px;
  font-size: 14px;
  color: red;
  margin-top: -20px;
  margin-bottom: 20px;
}

.v3-verify-otp-title {
  color: var(--tertiary-neutral-color-1, #343434);
  font-family: Mulish;
  width: 90%;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 32px;
  margin-left: 20px;
}
.v3-verify-mpin-title {
  color: var(--primary-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-top: 32px;
  margin-left: 20px;
  width: 90%;
}
.v3-verify-otp-title-customer {
  color: var(--neutral-1, #0e0e0e);
  font-size: 18px;
  letter-spacing: 0.18px;
  display: flex;
  justify-content: space-between;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 24px 26px 24px 28px;
}
.bottom-sheet-header {
  margin-bottom: 20px;
}
.v3-verify-otp-title-head {
  font-size: 18px;
  letter-spacing: 0.18px;
}
.v3-verify-otp-subtitle {
  color: #343434;
  font-size: 14px;
  margin-left: 20px;
}
.v3-verify-otp-subtitle-text {
  color: var(--tertiary-neutral-color-1);
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.5%;
  opacity: 0.5;
}

.v3-otp-input-bottom-sheet-content {
  padding: 10px;
}

.v3-verify-otp-timer {
  font-size: 14px;
  text-align: center;
}

.v3-verify-otp-resend-otp-text {
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}

.v3-opt-input-cta {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  justify-content: center;
}

.v3-verify-otp-bottomSheet-cta {
  padding: 16px;
  border-top: solid 1px var(--tertiary-neutral-color-3);
}

.v3-verify-otp-bottom-sheet-info-box {
  position: absolute;
  top: -80px;
  width: 85%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.16);
}

.v3-verify-otp-bottom-sheet-info-box > div {
  color: var(--leading-white);
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
}

.v3-verify-otp-bottom-sheet-info-box > img {
  width: 15.13px;
}

.v3-success-bottomSheet-cta {
  margin-bottom: 10px;
}

.v3-failure-bottom-sheet-text {
  margin: 22px auto 36px auto;
}

[data-rsbs-header]:before {
  visibility: hidden;
}

/* otp input bottom sheet ends */

/* success bottom sheet starts */

.v3-success-bottomSheet-icon-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.v3-success-bottomSheet-icon {
  width: 68px;
  text-align: center;
}

.v3-success-bottomSheet-title {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
  width: 80%;
  margin: auto;
}

.v3-success-bottomSheet-subtitle {
  color: var(--primary-base-color-1);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  opacity: 0.5;
  width: 80%;
  margin: 16px auto auto auto;
}

/* success bottom sheet ends  */

/* new card request v3 starts  */
.v3-newCardRequest {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: var(--leading-white);
}
.v3-newCardRequest-top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.v3-newCardRequest-content {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
}
.v3-newCardRequest-content-header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.v3-newCardRequest-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.v3-newCardRequest-image > img {
  width: 260px;
}
.v3-newCardRequest-content-header {
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: center;
  color: var(--primary-base-color-1);
}
.v3-newCardRequest-replacement-fee-text {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
  text-align: center;
  color: var(--primary-base-color-3);
}
.v3-newCardRequest-content-address-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}
.v3-newCardRequest-content-address-header {
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  color: var(--tertiary-neutral-color-1);
}
.v3-newCardRequest-content-address {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: center;
  color: var(--primary-color-1);
}
.v3-newCardRequest-content-help {
  text-align: center;
}
.v3-newCardRequest-needhelp-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
  color: var(--tertiary-neutral-color-1);
}
.v3-newCardRequest-request-support-text {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
  color: var(--tertiary-cta-text-color);
}
.v3-newCardRequest-tnc {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: auto;
  margin-bottom: 10px;
}
.v3-newCardRequest-tnc-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  text-align: left;
  color: #747474;
}
.v3-newCardRequest-tnc-checkbox {
  width: 16px !important;
  height: 18px !important;
}
.v3-newCardRequest-ctaSection {
  width: 100%;
  max-width: 450px;
  padding: 16px;
  background: var(--leading-white);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.14);
}
/* new card request v3 ends */

/* block card reason v3 starts*/

.blockCard-container {
  opacity: 0;
  transition: all 0.5s;
}
.blockCard-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.blockCardReason-container {
  opacity: 0;
  transition: all 0.5s;
}
.blockCardReason-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.v3-blockCardReason-ellipse-container {
  position: relative;
  height: 180px;
  margin-top: 0px;
}

.v3-blockCardReason-ellipse-svg {
  position: absolute;
  width: 100%;
  z-index: -1;
  border-radius: 15px;
}

.v3-blockCardReason-questionIcon-svg {
  position: absolute;
  left: 50%;
  margin-left: -47px;
  top: 19%;
}

.freezeInstead-container {
  opacity: 0;
  transition: all 0.5s;
}
.freezeInstead-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.v3-blockCardReason-bottomSheet-cardFreeze-svg {
  position: absolute;
  left: 50%;
  margin-left: -60px;
  top: 19%;
}

.v3-blockCardReason-bottomSheet-blockCard-svg {
  position: absolute;
  left: 50%;
  margin-left: -60px;
  top: 19%;
}

.v3-blockCardReason-faq-title {
  color: var(--tertiary-neutral-color-1);
  margin-left: 15px;
  font-weight: 700;
  font-size: 16px;
}
.v3-blockCardReason-Reason {
  margin: 20px 15px;
}
.v3-blockCardReason-faq-subtitle {
  font-size: 12px;
  font-weight: 400;
  margin: 10px auto 15px 15px;
}
.proceedToBloack-container {
  opacity: 0;
  transition: all 0.5s;
}
.proceedToBloack-container.active {
  opacity: 1;
  transition: all 0.5s;
}

.v3-blockCardReason-hr-line {
  height: 1px;
  color: #9e9a9ac7 !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
  width: 95%;
}

.v3-blockCardReason-reason-text {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: var(--tertiary-neutral-color-1);
}

.v3-blockCardReason-reason-right-arrow {
  float: right;
}

.v3-blockCardReason-close-btn {
  margin-top: 10px;
  z-index: 10;
  position: relative;
  float: right;
  margin-right: 20px;
}

.v3-blockCardReason-bottomSheet-cta-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  flex-direction: column;
  gap: 3px;
  align-items: center;
}

.v3-blockCardReason-bottomSheet-extra-space {
  height: 20px;
}

.v3-blockCardReason-bottomSheet-subtitle {
  color: var(--primary-base-color-2);
  font-size: 12px;
  font-weight: 400;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.v3-proceedToBlock-description-container {
  display: flex;
  margin-top: 5px;
}
.v3-proceedToBlock-description-img {
  margin: 10px;
  margin-left: 15px;
  max-width: 50px;
}

.v3-proceedToBlock-description-text {
  margin: 10px;
  font-weight: 500;
  font-size: 12px;
  margin-left: 0px;
  align-self: center;
}
/* block card reason v3 ends*/

/* v3 card blocked success screen start */
.cardBlocked-container {
  opacity: 0;
  transition: all 0.5s;
}
.cardBlocked-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.v3-card-blocked-success-img-container {
  display: flex;
  width: 50%;
  border-radius: 50%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  position: relative;
  aspect-ratio: 1/1;
}
.v3-card-blocked-img-effect-container {
  position: absolute;
  width: 100%;
}

.v3-card-blocked-img-effect1 {
  position: absolute;
  left: 18%;
  width: 41%;
}

.v3-card-blocked-img-effect2 {
  position: absolute;
  top: 46px;
  left: 34%;
  width: 50%;
}

.v3-card-blocked-img-effect3 {
  position: absolute;
  left: 17%;
  width: 20%;
}

.v3-card-blocked-img-effect4 {
  position: absolute;
  top: 154px;
  left: 70%;
}

.v3-card-blocked-success-img {
  margin: auto;
  width: 68%;
  margin-right: 12%;
}

.v3-card-blocked-success-title {
  font-size: 16px;
  font-weight: 700;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
  color: var(--primary-base-color-1);
}

.v3-card-blocked-success-subtitle {
  font-weight: 500;
  color: var(--primary-base-color-3);
  font-size: 12px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}

.v3-card-blocked-success-sub-text {
  color: var(--tertiary-neutral-color-1);
  font-size: 12px;
  font-weight: 400;
  margin-left: 25px;
  margin-right: 25px;
  text-align: center;
  margin-top: 20px;
}

.v3-card-block-success-hr-line {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 1px;
  border: 1px solid #b4afafac;
  color: #b4afafac !important;
}
.v3-cardBlocked-header {
  margin-bottom: 20px;
}
/* v3 card blocked success end  */

/* v3 reset mpin starts */
.v3-reset-mpin-text-title {
  margin-bottom: -20px;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 20px;
  font-weight: 700;

  color: var(--primary-base-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}

/* v3 reset mpin ends */

@media (min-width: 450px) {
  :root {
    --rsbs-max-w: 440px;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }
}

[data-rsbs-has-header="false"] [data-rsbs-header] {
  padding: 0px !important;
}

.activity-container {
  opacity: 0;
  transition: all 0.5s;
  /* margin: 0px 15px; */
}
.activity-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.v3-activity-section-summary {
  width: 90%;
}
.v3-activity-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 18px;
}
.v3-activity-section-header-text {
  color: var(--primary-base-color-1);
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
}
.v3-activity-section-header-toggle-button {
  display: flex;
  align-items: center;
  gap: 5px;
}
.v3-activity-section-header-toggle-button > div:first-child {
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
}
.v3-activity-section-header-toggle-button > div:last-child {
  display: flex;
  align-items: center;
}
.v3-activity-section-header-toggle-closed {
  color: var(--secondary-color-1);
}
.v3-activity-section-header-toggle-open {
  color: var(--tertiary-neutral-color-1);
}
.v3-activity-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--primary-base-color-1, #0d2950);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 18px;
  /* margin: 14px 0; */
  letter-spacing: 0.01em;
  text-align: left;
}
.v3-activity-section {
  display: flex;
  justify-content: space-between;
}
.v3-activity-section-btn {
  color: #ff6b00;
  text-decoration: underline;
  cursor: pointer;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  margin-right: 18px;
}
.v3-activity-section-summary-value-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 2px 0;
}

.v3-activity-section-summary-value-section-title {
  color: var(--primary-base-color-2, #697a92);
  text-align: center;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}
.v3-activity-section-summary-value-section-value {
  color: var(--tertiary-neutral-color-1);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
}
.v3-activity-section-summary-value-section-value-sub {
  color: var(--tertiary-neutral-color-2);
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.v3-activity-section-balance-widget-wrapper {
  position: relative;
  width: 90%;
  padding: 12px 12px 12px 18px;
  gap: 0px;
  border-radius: 18px;
  justify: space-between;
  background: var(--leading-white);
  /* box-shadow: 0px 4px 0px 0px var(--tertiary-neutral-color-3); */
  box-shadow: 0px 11.67px 36.18px -2.33px #00000017;
  z-index: 1;
}

.v3-activity-section-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
}
.v3-activity-section-balance-amount-section {
  display: flex;
  width: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 62%;
}
.v3-activity-section-balance-amount-section-value {
  color: var(--primary-base-color-1);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
}
.v3-activity-balance-amount-section-fully-paid-value {
  color: var(--tertiary-positive-color-1, #23951c);
}
.v3-activity-section-balance-amount-section-due {
  color: var(--tertiary-neutral-color-2);
  text-align: center;
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}
.v3-activity-section-balance-amount-section-overdue {
  color: var(--tertiary-negative-color-1);
  text-align: center;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}
.v3-activity-section-balance-amount-cta {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 48px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--secondary-color-1);
  /* box-shadow: 1px 5px 18px 0px var(--secondary-color-3); */
  color: var(--color-Dynamic-brand-button-text, #fff);
  text-align: center;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
  cursor: pointer;
}
.v3-activity-section-actions {
  width: 100%;
  padding: 28px 20px 28px 20px;
  background: var(--primary-color-5, #f1f6fc);
}
.v3-activity-section-actions > .v3-web-activity-section {
  background: var(--leading-white) !important;
  border-radius: 21px !important;
  box-shadow: 0px 10px 31px -2px #00000017;
}
.v3-activity-section-actions-body {
  display: flex;
  align-items: center;
  gap: 12px;
}
.v3-activity-section-actions-body-item {
  display: flex;
  height: 96px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 12px;
  background: var(--System-Palette-leading-white, #fff);
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
}
.v3-activity-section-actions-body-item-text {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  /* body - strong */
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.v3-activity-section-single-action {
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding: 10px;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 12px;
  background: var(--System-Palette-leading-white, #fff);
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
}
.v3-activity-section-single-action > div:first-child {
  display: flex;
  align-items: center;
  gap: 12px;
}
.v3-quick-action-text {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  width: 97px;
}
.v3-quick-action-description {
  color: var(--primary-base-2, var(--Colors-PRIMARY-BASE-2, #697a92));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.v3-quick-action-view {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--primary-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1px;
}
.v3-activity-section-actions-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  width: 100%;
  row-gap: 18px;
}
.v3-home-filler-image {
  width: 100%;
  margin-top: 105px;
}

.v3-home-hf-branding {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 24px 0;
  margin-top: auto;
}
.v3-home-hf-branding-text {
  color: #898989;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 120% */
}
.v3-home-hf-branding-logo {
}
.v3-home-transactionSection {
  padding: 28px 16px 30px 20px;
}
.v3-home-transaction-view-all {
  color: var(--tertiary-cta-text-color);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  text-decoration-line: underline;
  cursor: pointer;
}
.v3-home-transactionSection-transaction {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  /* padding: 8px 0; */
}
.v3-home-transactionSection-transaction-logo {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.v3-home-transactionSection-transaction-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 40%;
}

/* Botttomsheet position according to device virtual keypad */
div[data-rsbs-overlay="true"] {
  bottom: env(keyboard-inset-height, 0px);
}

.v3-home-transactionSection-transaction-desc-title {
  color: var(--color-static-ui-sub-text-s, #323232);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
}

.v3-home-transactionSection-transaction-desc-date {
  color: var(--color-static-UI-Light-text, #747474);
  text-align: center;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
}

.v3-home-transactionSection-transaction-value {
  display: flex;
  width: 143px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  width: 40%;
}
.v3-home-transactionSection-transaction-value-amount {
  color: var(--color-static-ui-sub-text-s, #323232);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
}
.v3-home-transactionSection-transaction-value-emi {
  color: var(--tertiary-neutral-color-2);
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  gap: 4px;
  align-items: center;
  justify-content: center;
}
.v3-transaction-emi-section {
  margin-top: 12px;
}

.v3-home-transactionSection-transaction-convertToEmi {
  width: 100%;
  border-radius: 8px;
  background: var(--primary-color-5);
  color: var(--tertiary-neutral-color-1);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.24px;
  padding: 8px 12px 8px 12px;
  display: flex;
  justify-content: space-between;
}
.v3-home-transactionSection-transaction-convertToEmi-inProgress {
  background: var(--tertiary-caution-color-3);
}
.v3-home-transactionSection-transaction-convertToEmi-active {
  background-color: var(--tertiary-neutral-color-3);
}
.v3-home-transactionSection-transaction-convertToEmi-failed {
  background: var(--tertiary-negative-color-3);
}
.v3-home-transactionSection-transaction-convertToEmi > div {
  display: flex;
  gap: 4px;
  align-items: center;
}
.v3-transactionsSection-conversionProgress-bottomSheet {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v3-transactionsSection-conversionProgress-bottomSheet > div:first-child {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color-5);
  margin-bottom: 16px;
}
.v3-transactionsSection-conversionProgress-bottomSheet > div:nth-child(2) {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
  margin-bottom: 24px;
}
.v3-transactionSection-convertionFailed-bottomSheet {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--leading-white);
  margin: 16px auto;
}
.v3-transactionSection-convertionFailed-bottomSheet-text {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
  margin-bottom: 12px;
}
.v3-transactionSection-convertionFailed-bottomSheet-button {
  margin: 16px auto;
}
.v3-home-card-details-bg-top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.v3-home-card-details-bg-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.v3-settings-home-bottom-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}
.v3-settings-home-bottom-container.active {
  opacity: 1;
  transition: all 0.5s;
}
.v3-settings-home-bottom {
  width: 100%;
  background-color: #000;
  z-index: 100;
  max-width: 450px;
  border-radius: 12px;
  background: #fff;
  padding: 24px 16px 40px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
}
.v3-settings-home-bottom-head {
  color: #343434;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.v3-settings-home-bottom-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ececec;
}
.v3-settings-home-bottom-item-icon {
  width: 10%;
}
.v3-settings-home-bottom-item-body {
  width: 75%;
}
.v3-settings-home-bottom-item-cta,
.v3-settings-home-button-item-cta-container {
  margin: auto;
}
.v3-settings-home-bottom-item-body-title {
  color: var(--Secondary-Colour, var(--color-static-UI-Header-text, #343434));
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.v3-settings-home-bottom-item-body-desc {
  color: #555;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
}
.v3-home-card-details-card {
  position: relative;
  cursor: pointer;
  z-index: 3;
  overflow: hidden;
  border-radius: 15px;
}
.v3-home-card-details-card-cta {
  position: absolute;
  border-radius: 100px;
  border: 0.2px solid rgba(255, 255, 255, 0);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  color: #fff;
  text-align: center;
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  z-index: 4;
  padding: 8px 10px;
  left: 50%;
  top: 50%;
  /* transform: translate(-50%, -50%); */
  width: 155px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 41%;
}
.v3-cardDetailsWidget-cardImage-vertical-container {
  position: relative;
  width: 181px;
  height: 288px;
}
.v3-cardDetailsWidget-cardImage-horizontal-container {
  position: relative;
  width: 288px;
  height: 180px;
}
.v3-cardDetailsWidget-cardImage-vertical {
  width: 100%;
  height: 100%;
}
.v3-cardDetailsWidget-cardImage-horizontal {
  width: 100%;
  height: 100%;
}
.v3-cardDetailsWidget-frozenCover {
  position: absolute;
  left: 0;
  top: 0;
}
.v3-cardDetailsWidget-frozenCover-horizontal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.v3-cardDetailsWidget-frozenCardDetails {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 288px;
}
.v3-cardDetailsWidget-frozenCardDetails > img {
  width: 40px;
}
.v3-cardDetailsWidget-frozenCardDetails-text1 {
  color: #ffffff;
  font-size: 10px;
  line-height: 12.5px;
  margin-top: 12px;
  text-align: center;
  font-weight: 600;
}
.v3-cardDetailsWidget-frozenCardDetails-text2 {
  color: #ffffff;
  font-size: 10px;
  line-height: 12.5px;
  margin-top: 5px;
  text-align: center;
  font-weight: 600;
}
/* All CVP Offers v3 starts */
.v3-allCvpOffers {
  margin: 20px 16px;
}
.v3-allCvpOffers > div:first-child {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: left;
}
.v3-allCvpOffers-offers-container {
  margin-top: 20px;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: start;
}
.v3-allCvpOffers-offerCard {
  margin: auto;
  margin-top: 0px;
  padding: 5px 5px 12px 5px;
  border-radius: 8px;
  box-shadow: 0px 4px 24px 0px #00000024;
  height: 100%;
  cursor: pointer;
  height: 198px;
  width: 100%;
}
.v3-allCvpOffers-offerCard-banner-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  border-radius: 4px;
  position: relative;
  height: 64px;
}
.v3-allCvpOffers-offerCard > div:first-child {
  position: relative;
}
.v3-allCvpOffers-offerCard-banner {
  width: 100%;
  border-radius: 4px;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /* max-height: 80px; */
}
.v3-allCvpOffers-offerCard-logo {
  width: 25%;
  left: 5%;
  bottom: 16%;
  display: flex;
  /* width: 40px;
  height: 40px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23.242px;
  position: absolute;
  /* left: 10px;
  bottom: 11.232px; */
  border-radius: 8px;
  border: 1px solid var(--leading-white, #fff);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.14);
  width: 40px;
  height: 40px;
}
.v3-allCvpOffers-offerCard > div:last-child {
  margin: 12px;
}
.v3-allCvpOffers-offerCard-tag {
  font-size: 9px;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 5px;
  opacity: 0.64;
}
.v3-allCvpOffers-offerCard-details {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  font-family: Mulish;
  font-weight: 700;
  line-height: 17.57px;
  letter-spacing: 0.01em;
}
/* All CVP Offers v3 end */
/* CVP Offer Details v3 start */
.v3-cvpOfferDetails {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.v3-cvpOfferDetails::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
.v3-cvpOfferDetails-banner-container {
  position: relative;
}
.v3-cvpOfferDetails-banner {
  width: 100%;
  max-height: 187px;
}
.v3-cvpOfferDetails-logo {
  position: absolute;
  left: 18px;
  bottom: -25px;
}
.v3-cvpOfferDetails-logo > img {
  width: 92px;
  height: 92px;
  border-radius: 18px;
  border: 2px solid var(--leading-white, #fff);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23.242px;
}
.v3-cvpOfferDetails-text-container {
  margin: 40px 18px 95px 18px;
}
.v3-cvpOfferDetails-text-description {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
.v3-cvpOfferDetails-text-accordian-container {
  border-radius: 12px;
  box-shadow: 0px 4px 24px 0px #00000024;
  padding: 10px 0px;
  padding-bottom: 3px;
}
.v3-cvpOfferDetails-cta {
  width: 100%;
  padding: 16px 20px;
  position: fixed;
  max-width: 450px;
  bottom: 0;
  box-shadow: 0px -4px 4px 0px #dfdfdf40;
}
/* CVP Offer Details v3 end */
/* Offer Details Accordian start */
.v3-offerDetails-accordian {
  margin: 18px;
  margin-bottom: 0px;
  padding: 0px 22px 0px 22px;
}
.v3-offerDetails-accordian-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}
.v3-offerDetails-accordion-points-container {
  display: flex;
  gap: 15px;
  align-items: flex-start;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px;
}
.v3-offerDetails-accordian-bullet {
  height: 7px;
  width: 7px;
  border-radius: 100%;
  margin-top: 4px;
  box-shadow: 0px 1px 5px 0px #00000052;
}
.v3-offerDetails-accordian-point {
  width: 95%;
  gap: 0px;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
}
.v3-offerDetails-accordian-header > div {
  cursor: pointer;
}

.v3-offerDetails-accordian-dangerously-rendered-points {
  width: 95%;
  gap: 0px;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  display: block;
}

.v3-offerDetails-accordian-dangerously-rendered-points ul {
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.v3-offerDetails-accordian-dangerously-rendered-points ol {
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 15px;
}

.v3-offerDetails-accordian-dangerously-rendered-points ul li {
  list-style: none;
  position: relative;
  margin-left: 18px;
}

.v3-offerDetails-accordian-dangerously-rendered-points ul li span {
  width: fit-content;
}

.v3-offerDetails-accordian-dangerously-rendered-points ul li::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--primary-color-1);
  box-shadow: 0px 1px 5px 0px #00000052;
  top: 4px;
  left: -18px;
}

/* Offer Details Accordian end */
/* Offer Details Milestone card start */
.v3-offerDetails-PendingMilestoneCard {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background: #ffffff;
  border: 2px solid;
  box-shadow: 0px 4px 24px 0px #00000024;
}
.v3-offerDetails-PendingMilestoneCard > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 7px; */
}
.v3-offerDetails-pendingMilestoneCard-image {
  width: 52px;
}
.v3-offerDetails-pendingMilestoneCard-text {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.v3-offerDetails-pendingMilestoneCard-amountContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 11px;
  letter-spacing: 0px;
  text-align: left;
}
/* Offer Details Milestone card end */
/* Offer Details Progress bar start */
.v3-offerDetails-progressBar {
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.v3-offerDetails-progress {
  height: inherit;
  border-radius: inherit;
  position: relative;
  overflow: hidden;
}
.v3-offerDetails-progressbar-shadow {
  height: inherit;
  position: absolute;
  animation: slide 3s ease infinite;
  animation-delay: 3s;
  right: 110%;
}
@keyframes slide {
  0% {
    right: 110%;
  }
  50% {
    right: -10%;
  }
  100% {
    right: 110%;
  }
}

/* Offer Details Progress bar end */
/* Offer Details Completed Milstone Card start */
.v3-cvpOfferDetails-completedMilestoneCard {
  overflow: hidden;
  position: relative;
  padding: 22px;
  margin-bottom: 12px;
  box-shadow: 0px 4px 24px 0px #00000024;
}
.v3-cvpOfferDetails-completedMilestoneCard-lockImage {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 2;
}
.v3-cvpOfferDetails-completedMilestoneCard-lock {
  position: absolute;
  left: -7%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 145px;
  height: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v3-cvpOfferDetails-completedMilestoneCard-lock > div {
  width: 145px;
  height: 145px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: shrink 2s ease infinite;
}
@keyframes shrink {
  0% {
    width: 145px;
    height: 145px;
  }
  50% {
    width: 40px;
    height: 40px;
  }
  100% {
    width: 145px;
    height: 145px;
  }
}
.v3-cvpOfferDetails-completedMilestoneCard-lock > div > div {
  width: 65%;
  height: 65%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v3-cvpOfferDetails-completedMilestoneCard-lock > div > div > div {
  width: 60%;
  height: 60%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v3-cvpOfferDetails-completedMilestoneCard-text {
  z-index: 3;
  position: relative;
  left: 28%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.v3-cvpOfferDetaiv3-descrete-progressBarls-completedMilestoneCard-text
  > div:first-child {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 6px;
}
.v3-offerDetails-descreteProgressBar-active-bar {
  background-color: var(--primary-color-1);
}
.v3-offerDetails-descreteProgressBar-bar {
  background-color: var(--primary-color-4);
}
.v3-cvpOfferDetails-completedMilestoneCard-completedText1 {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.v3-cvpOfferDetails-completedMilestoneCard-completedText2 {
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.v3-cvpOfferDetails-completedOffer-infoCard {
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 0px 4px 24px 0px #00000024;
}
.v3-cvpOfferDetails-completedOffer-infoCard > div {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px;
  border-radius: 4px;
}

.v3-cvpOfferDetails-completedOffer-infoCard > img {
  width: 18px;
}
.v3-cvpOfferDetails-completedOffer-infoCard > div {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
/* Offer Details Completed Milstone Card end */
/* Offer Details All Campaign offers start */
.v3-AllCampaignOffers-offersContainer {
  margin: 28px 16px;
}
.v3-allCampaignOffers-campaignOfferCard {
  min-height: 114px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  /* margin-bottom: 20px; */
  gap: 15px;
  box-shadow: 0px 4px 24px 0px #00000024;
  position: relative;
  padding: 16px 23px 16px 16px;
  cursor: pointer;
}

.multiple-cards {
  width: 280px;
  height: 114px;
  flex-shrink: 0;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.14);
  padding: 16px 23px 16px 16px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 16px;
  gap: 15px;
}
.v3-allCampaignOffers-campaignOfferCard-backgroundPattern {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
}
.v3-allCampaignOffers-campaignOfferCard-backgroundPattern > img {
  width: 110%;
}
.v3-allCampaignOffers-campaignOfferCard-brandLogo {
  width: 82px;
  border-radius: 12px;
  overflow: hidden;
  z-index: 2;
}
.v3-allCampaignOffers-campaignOfferCard-offerDescription {
  opacity: 0.82;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: var(--leading-white, #fff);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}
.v3-allCampaignOffers-campaignOfferCard-subText {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}
.v3-allCampaignOffers-campaignOfferCard-pendingText {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}
.v3-allCampaignOffers-campaignOfferCard-text {
  color: var(--leading-white);
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  flex: 1;
}
.v3-allCampaignOffers-campaignOfferCard-milestoneComplete {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}
.v3-allCampaignOffers-campaignOfferCard-milestoneComplete > div {
  display: inline-flex;
  align-items: center;
  gap: 7px;
  padding: 5px 18px 5px 5px;
  border-radius: 30px;
  background: #0000004d;
}
.v3-allCampaignOffers-campaignOfferCard-badge {
  position: absolute;
  z-index: 10;
  left: -1px;
  top: -2px;
  padding-top: 4px;
}
.v3-allCampaignOffers-campaignOfferCard-badge-expire {
  position: absolute;
  z-index: 10;
  left: -1px;
  top: -2px;
  padding-top: 1px;
}
.v3-allCampaignOffers-campaignOfferCard-badge-expires {
  position: absolute;
  top: 24px;
  font-size: 8px;
  font-weight: 700;
  line-height: 9px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  transform: rotate(-45deg);
  text-align: left;
}
.v3-allCampaignOffers-campaignOfferCard-badge-expires-inDays {
  top: 20px;
  right: 1px;
  left: -6px;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard-opt-in-container {
  padding: 4px 8px;
  background-color: var(--primary-base-color-5);
  border-radius: 4px;
  font-size: 0.563rem;
  font-weight: 500;
  color: var(--primary-base-color-1);
  position: absolute;
  top: 6px;
  left: 7px;
}
.v3-AllCampaignOffers-cvpOffersContainer-offerCard-opt-in-container {
  padding: 4px 8px;
  background-color: var(--primary-base-color-5);
  border-radius: 4px;
  font-size: 0.563rem;
  font-weight: 500;
  color: var(--primary-base-color-1);
  position: absolute;
  top: 18px;
  right: 11px;
}
.v3-allCampaignOffers-campaignOfferCard-opt-in-container {
  margin-bottom: 13px;
}
.v3-AllCampaignOffers-campaignOffersContainer-offerCard-opt-in-container {
  padding: 4px 8px;
  background-color: var(--primary-base-color-5);
  border-radius: 4px;
  font-size: 0.563rem;
  font-weight: 500;
  color: var(--primary-base-color-1);
  width: fit-content;
}
.v3-AllCampaignOffers-campaignOffersContainer-offerCard-opted-in-container {
  padding: 4px 8px;
  background-color: var(--primary-base-color-5);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard-opted-in-container {
  padding: 4px 8px;
  background-color: var(--primary-base-color-5);
  border-radius: 4px;
  position: absolute;
  top: 6px;
  left: 7px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.v3-AllCampaignOffers-cvpOffersContainer-offerCard-opted-in-container {
  padding: 4px 8px;
  background-color: var(--primary-base-color-5);
  border-radius: 4px;
  position: absolute;
  top: 18px;
  right: 19px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard-opted-in-container-text {
  font-size: 0.563rem;
  font-weight: 500;
  color: var(--tertiary-positive-color-1);
}
.v3-AllCampaignOffers-cvpOffersContainer-offerCard-opted-in-container-text {
  font-size: 0.563rem;
  font-weight: 500;
  color: var(--tertiary-positive-color-1);
}
.v3-AllCampaignOffers-campaignOffersContainer-offerCard-opted-in-container-text {
  font-size: 0.563rem;
  font-weight: 500;
  color: var(--tertiary-positive-color-1);
}
.v3-AllCampaignOffers-merchantOffersContainer {
  margin-top: 50px;
  margin-bottom: 20px;
}
.v3-AllCampaignOffers-merchantOffersContainer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px;
}
.v3-AllCampaignOffers-merchantOffersContainer-header > div:first-child {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.16px;
}
.v3-AllCampaignOffers-merchantOffersContainer-header > div:last-child {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  text-decoration: underline;
  cursor: pointer;
}
/* Offer Details All Campaign offers end */
/* Offer Details Descrete Progress bar start */
.v3-offerDetails-descreteProgressBar {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
}
.v3-offerDetails-descreteProgressBar > div {
  height: inherit;
  flex-grow: 1;
  border-radius: 18px;
}
/* Offer Details Descrete Progress bar end */
/* Campaign Offer Details start */
.v3-campaignOfferDetails-banner-container {
  position: relative;
  min-height: 170px;
  overflow: hidden;
}
.v3-campaignOfferDetails-backgroundPattern {
  position: absolute;
  left: 0;
  top: 0;
  width: 115%;
}
.v3-campaignOfferDetails-Logo {
  width: 85px;
  border-radius: 12px;
}
.v3-campaignOfferDetails-offerBanner {
  position: relative;
  width: 90%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 15px;
  z-index: 2;
}
.v3-campaignOfferDetails-offerBanner-text > div {
  font-size: 20px;
  font-weight: 700;
  /* line-height: 24px; */
  /* letter-spacing: 0em; */
  text-align: left;
  color: #fff;
  font-family: Mulish;
  font-style: normal;
  line-height: 130%; /* 26px */
  letter-spacing: 0.2px;
}
.v3-campaignOfferDetails-offerBanner-text > span {
  font-size: 10px;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: 0px;
  text-align: left;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  border-radius: 12px;
  padding: 7px 9px;
  background: #0e0e0e5c;
  margin-top: 12px;
}
.v3-campaignOfferDetails-progressContainer {
  margin: auto;
  margin-top: -30px;
  margin-bottom: 20px;
  z-index: 10;
  position: relative;
}
.v3-campaignOfferDetails-details {
  margin: 0px 18px 95px 18px;
}
.v3-campaignOfferDetails-nonMilestoneOffer-infoCard {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border: 2px solid;
  border-radius: 18px;
  width: 95%;
  margin: auto;
  box-shadow: 0px 4px 24px 0px #00000024;
}
.v3-campaignOfferDetails-nonMilestoneOffer-infoCard > img {
  width: 54px;
  height: 54px;
  border-radius: 100%;
  overflow: hidden;
}
.v3-campaignOfferDetails-nonMilestoneOffer-infoCard > div {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  width: 75%;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard {
  color: var(--tertiary-neutral-color-1);
  width: 164px;
  min-height: 195px;
  padding-top: 15px;
  cursor: pointer;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard-description {
  font-size: 14px;
  font-weight: 500;
}

@media all and (max-width: 350px) {
  .v3-AllCampaignOffers-merchantOffersContainer-offerCard {
    padding-right: 15px;
  }
}

.v3-AllCampaignOffers-merchantOffersContainer-offerCard-countdown {
  color: var(--secondary-color-1);
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard > div:first-child {
  height: 132px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard-illustration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 164px;
  height: 132px;
  position: relative;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard-illustration {
  width: 100%;
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard-logo {
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 3.5px;
  background: var(--leading-white);
  bottom: 8px;
  left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard-logo > img {
  width: 42px;
  height: 42px;
  border-radius: 4px;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard > div:nth-child(2) {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.v3-AllCampaignOffers-merchantOffersContainer-offerCard > div:last-child {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
}
/* Campaign Offer Details end */
/* All Merchant offers start */
.v3-allMerchantOffers-searchBarContainer {
  margin: 12px 16px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background: var(--leading-white);
  margin-top: 18px;
}
.v3-allMerchantOffers-searchBarContainer > div:first-child {
  flex-grow: 1;
}
.v3-allMerchantOffers-FilterButton {
  border: solid 1px;
  padding: 6px 12px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}
.v3-allMerchantOffers-FilterButton-selected {
  border: solid 2px;
  padding: 2px 12px;
}
.v3-allMerchantOffers-searchInput {
  border: solid 1px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  border-radius: 100px;
}

.v3-allMerchantOffers-searchInput > input {
  outline: none;
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  text-overflow: ellipsis;
  width: 100%;
}
.v3-allMerchantOffers-searchInput > input:focus::placeholder {
  text-overflow: ellipsis;
}
.v3-allMerchantOffers-searchInput > input ::placeholder {
  color: var(--tertiary-neutral-color-2);
}
.v3-allMerhantOffers-categoriesContainer {
  margin: 12px 0px;
  margin-bottom: 0px;
  overflow: auto;
  display: flex;
  gap: 8px;
  padding-left: 16px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background: var(--leading-white);
  padding-bottom: 4px;
}
.v3-allMerhantOffers-categoriesContainer::-webkit-scrollbar {
  display: none;
}
.v3-allMerhantOffers-categoriesContainer-category {
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
  border-radius: 100px;
  border: 1px solid;
  width: fit-content;
  text-wrap: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.v3-allMerchantOffers-categoriesContainer-selected {
  border: 2px solid;
}
.v3-allMerhantOffers-categoriesContainer-category > div {
  transform: scale(0.7);
}
.v3-allMerchantOffers-highlightedOffers {
  padding: 28px 0px;
  padding-bottom: 0px;
}
.v3-allMerchantOffers-highlightedOffers-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
}
.v3-allMerchantOffers-highlightedOffers-carouselContainer {
  margin-top: 12px;
  --swiper-pagination-bullet-width: 14px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-border-radius: 2px;
  --swiper-pagination-bullet-inactive-color: #ffffff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: #4b49498d;
}
.v3-allMerchantOffers-highlightedOffers-offerContainer {
  width: 315px;
  min-height: 140px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  gap: 10px;
  position: relative;

  margin: auto;
}
.v3-allMerchantOffers-highlightedOffers-offerContainer-textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 54%;
  padding: 15px 16px;
}
.v3-allMerchantOffers-highlightedOffers-offerContainer-textContainer
  > div:first-child {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.09px;
  text-transform: uppercase;
  opacity: 0.8;
}
.v3-allMerchantOffers-highlightedOffers-offerContainer-textContainer
  > div:first-child
  > img {
  width: 24px;
  height: 24px;
}
.v3-allMerchantOffers-highlightedOffers-offerContainer-textContainer
  > div:nth-child(2) {
  font-size: 12px;
  font-weight: 500;
  margin-top: 6px;
}
.v3-allMerchantOffers-highlightedOffers-offerContainer-couponContainer {
  margin-top: 12px;
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
}
.v3-allMerchantOffers-highlightedOffers-offerContainer-imageContainer {
  position: absolute;
  right: 0;
  width: 50%;
  height: 250px;
  display: flex;
  align-items: center;
  border-top-left-radius: 118px;
  border-bottom-left-radius: 118px;
  overflow: hidden;
}
.v3-allMerchantOffers-highlightedOffers-offerContainer-imageContainer > img {
  height: 65%;
}
.v3-allMerchantOffers-highlightedOffers-offerContainer-couponContainer-couponBg {
  position: absolute;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-allMerchantOffers-highlightedOffers-offerContainer-couponTextContainer {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 9px;
  font-weight: 700;
  padding: 8px 10px;
}
.v3-allMerchantOffers-allMerchantOffers {
  margin: 16px;
  margin-bottom: 0px;
}
.v3-allMerchantOffers-allMerchantOffers-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.42px;
}
.v3-allMerchantOffers-allMerchantOffers-offerContainer {
  margin: 16px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
.v3-allMerchantOffers-merchantOfferCard {
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0.5px solid;
  background: #fff;
  min-height: 228px;
  position: relative;
  cursor: pointer;
}
.v3-allMerchantOffers-merchantOfferCard-imageContainer {
  width: 100%;
  min-height: 120px;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-allMerchantOffers-merchantOfferCard-imageContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.v3-allMerchantOffers-merchantOfferCard-imageContainer-div {
  position: absolute;
  bottom: 13px;
  left: 13px;
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background: #fff;
  mix-blend-mode: hard-light;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-allMerchantOffers-merchantOfferCard-imageContainer-div > img {
  width: 85%;
  border-radius: 4px;
}
.v3-allMerchantOffers-merchantOfferCard-textContainer {
  margin: 0px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.v3-allMerchantOffers-merchantOfferCard-textContainer > div:first-child {
  font-size: 9px;
  font-weight: 600;
  line-height: 11px; /* 122.222% */
  text-transform: uppercase;
  opacity: 0.64;
}
.v3-allMerchantOffers-merchantOfferCard-textContainer > div:last-child {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
}
.v3-allMerchantOffers-expiryTag {
  width: fit-content;
  font-size: 12px;
  color: #fff;
  position: absolute;
  z-index: 3;
  top: 12px;
  right: -4px;
  display: flex;
  align-items: center;
  padding: 0px 4px;
}
.v3-allMerchantOffers-expiryTag-background {
  position: absolute;
  right: 0;
  width: 100%;
  top: -4px;
  z-index: 1;
}
.v3-allMerchantOffers-expiryTag > div {
  position: relative;
  z-index: 5;
}
.v3-allMerchantOffers-filteredOffer-text {
  color: var(--tertiary-neutral-color-1);
  font-size: 12px;
  font-weight: 500;
}
.v3-allMerchantOffers-headerWrapper {
  /* padding-bottom: 16px; */
  position: sticky;
  top: 0;
  z-index: 5;
  background: var(--leading-white);
}
.v3-allMerchantOffers-noOffers-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
}
/* All Merchant offers end */

/* Merchant Offer Details start */
.v3-merchantOfferDetails-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-merchantOfferDetails-illustrationImage {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-merchantOfferDetails-illustrationImage > img {
  width: 100%;
}
.v3-merchantOfferDetails-logo {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  background: var(--leading-white);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: -40px;
  z-index: 1;
  position: relative;
}
.v3-merchantOfferDetails-logo > img {
  width: 64px;
  height: 64px;
}
.v3-merchantOfferDetails-contentContainer {
  margin: 18px 20px;
  margin-bottom: 90px;
}
.v3-merchantOfferDetails-description {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
}
.v3-merchantOfferDetails-offerValidity {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #ed5b27;
  font-size: 10px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 24px;
}
.v3-merchantOfferDetails {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.v3-merchantOfferDetails::-webkit-scrollbar {
  display: none;
}
.v3-merchantOfferDetails-coupon {
  width: 266px;
  height: 80px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: -40px;
  background: transparent;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.14);
}
.v3-merchantOfferDetails-coupon > img {
  position: absolute;
  width: 100%;
}
.v3-merchantOfferDetails-coupon-merchantLogo {
  position: relative;
  height: inherit;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}
.v3-merchantOfferDetails-coupon-merchantLogo > img {
  width: 48px;
  height: 48px;
}
.v3-merchantOfferDetails-coupon-divider {
  position: relative;
  height: inherit;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-merchantOfferDetails-coupon-divider > img {
  height: 43px;
}
.v3-merchantOfferDetails-coupon-text {
  position: relative;
  height: inherit;
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v3-merchantOfferDetails-coupon-text > img {
  width: 100%;
}
.v3-merchantOfferDetails-coupon-couponCode {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.v3-merchantOfferDetails-coupon-couponCode-background {
  position: absolute;
  width: 100%;
}
.v3-merchantOfferDetails-coupon-couponCode-text {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 130%; /* 26px */
  letter-spacing: 0.2px;
}
.v3-merchantOfferDetails-coupon-copyToClipboard {
  transform: scale(2.4);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.v3-merchantOfferDetails-lottieFile {
  position: absolute;
  z-index: 45;
  width: 100%;
  overflow: hidden;
  background: var(--modal-overlay-bg, rgba(14, 14, 14, 0.86));
  display: flex;
  align-items: center;
  justify-content: center;
}
.v3-merchantOfferDetails-coupon-animation {
  animation: scale 6s ease infinite;
}

@keyframes ease {
  10% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(2);
  }
}
/* .swiper-slide {
  padding-bottom: 40px;
} */
.swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 24px;
}

/* Merchant Offer Details end */
.v3-setMpin-loader-container {
}
.v3-setMpin-loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
}
.v3-setMpin-loader-container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.v3-activationAuthHeader-container {
  display: flex;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: var(--primary-base-color-5);
  border-bottom: var(--primary-color-1);
  border-width: thick;
  align-items: center;
}
.v3-activationAuthHeader-container-right {
  padding-left: 16px;
  align-items: center;
}

.handPickedCard {
  display: flex;
  padding: 20px 16px;
  justify-content: space-between;
  background-color: #f8f3fd;
  border-radius: 0.5rem;
}

.handPickedCard-content {
  /* display: flex;
  flex-direction: column; */
  margin-left: 20px;
}

.handPickedCard-topSection {
  margin: 4px auto 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.handPickedCard-topSection-left {
  background-color: #f1e4ff;
  padding: 2px;
  border-radius: 0.5rem;
  color: #45008c;
  font-size: 9px;
}

.handPickedCard-topSection-right {
  color: #e00000;
  font-size: 9px;
}

.handPickedCard-title {
  color: #323232;
  font-size: 14px;
  font-weight: bold;
}
.handPickedCard-details {
  color: #323232;
  margin-top: 8px;
  font-size: 12px;
  margin-right: 32px;
}

.brandCard {
  display: flex;
  flex-direction: column;
  margin: 8px 4px;
}

.brandcard-image {
  margin: 8px auto;
}

.brandCard-content_title {
  color: #323232;
  font-size: 14px;
  letter-spacing: 3%;
  margin-right: 32px;
}

.brandCard-content_subcontent {
  color: #ff6b00;
  font-size: 12px;
  padding-top: 4px;
  margin-left: 2px;
}

.brandCard-content_sub {
  display: flex;
  align-items: center;
}

.v3-otp-input-sheet-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: auto;
}
.v3-otp-input-sheet-txn {
  width: 90%;
  margin: auto;
}
.v3-verify-otp-subtitle-login {
  color: var(--primary-base-color-2);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}
.v3-authentication-login-text {
  color: var(--primary-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.16px;
}
.v3-verify-otp-title-login {
  color: var(--tertiary-neutral-color-1);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5px;
}
.v3-authentication-login-mobile-text {
  color: var(--tertiary-neutral-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 8px;
  width: 317px;
}
.v3-header-login-otp {
  padding: 32px;
  padding-top: 25px;
  text-align: center;
  position: relative;
}
.v3-authentication-login {
  text-align: left;
}
.v3-verify-otp-resend-otp-text-login {
  color: var(--tertiary-neutral-color-2);
  text-align: center;

  /* Mulish/Caption */
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
  margin-top: 15px;
}

.v3-login-otp-cta {
  position: fixed;
  bottom: 12px;
  display: flex;
  width: 100%;
  max-width: 450px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3px;
  left: 0;
  right: 0;
  margin: auto;
}
.v3-authCustomer-heading {
  color: var(--color-static-UI-Header-text, #343434);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 32px;
}
.v3-authCustomer-input-label {
  color: var(--color-static-ui-sub-text-s, #323232);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.v3-cardSettings {
  position: relative;
}
.v3-cardSettings::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.v3-cardSettings {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.v3-cardSettings-btn-save {
  width: 100%;
  padding: 16px 0px;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: var(--leading-white);
  box-shadow: 0px -4px 4px 0px #dfdfdf40;
}
.v3-cardSettings-btn-info {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  align-self: flex-start;
  margin: 0px 24px;
  color: var(--primary-base-color-2);
}
.v3-paymentGateway-bottomSheet {
  margin: 24px;
  margin-bottom: 16px;
}
.v3-paymentGateway-bottomSheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0px;
}
.v3-paymentGateway-bottomSheet-header > div:first-child {
  color: var(--neutral-1, #0e0e0e);
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.2px;
}
.v3-paymentGateway-bottomSheet-dues-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 20px 0px;
}
.v3-paymentGateway-bottomSheet-dues-container > div:first-child {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.v3-paymentGateway-bottomSheet-dues-container > div:last-child {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
}
.v3-paymentGateway-bottomSheet-dues-container-emiText > div:first-child {
  color: var(--tertiary-neutral-color-1);
}
.v3-paymentGateway-bottomSheet-dues-container-emiText > div:last-child {
  color: var(--primary-color-1);
  text-align: center;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.24px;
  margin-top: 8px;
}
.v3-paymentGateway-bottomSheet-dues-container > div:last-child {
  flex-direction: column;
  gap: 5px;
}
.v3-paymentGateway-bottomSheet-dues-container-emi > div:first-child {
  display: flex;
  align-items: center;
  gap: 12px;
}
.v3-paymentGateway-bottomSheet-dues-container-emi > div:last-child {
  color: var(--primary-base-color-2);
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.24px;
}
.v3-paymentGateway-bottomSheet-minimumDues {
  margin-bottom: 30px;
}
.v3-paymentGateway-bottomSheet-customAmount {
  margin: 30px 0px;
}
.v3-paymentGateway-bottomSheet-customAmount-text {
  margin-top: 24px;
  color: #323232;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  opacity: 0.8;
}
.v3-paymentGateway-bottomSheet-customAmount-amountInput {
  margin: 12px 0px 32px 0px;
  color: #343434;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 83.333% */
  display: flex;
  align-items: center;
}
.v3-paymentGateway-bottomSheet-customAmount-amountInput-error {
  margin-bottom: 10px;
}
.v3-paymentGateway-amount-input {
  border: none;
  outline: none;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}
.v3-paymentGateway-options {
  /* background: rgba(254, 250, 245, 1); */
  background-color: var(--leading-white);
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  align-items: center;
  position: relative;
  z-index: 1;
  box-shadow: 0px -13px 32px -3px #0000000f inset;
  padding-top: 36px;
  padding-bottom: 36px;
}
.v3-paymentGateway-options > div:first-child {
  color: var(--primary-base-color-2);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
}
.v3-paymentGateway-options > div:nth-child(2) {
  color: var(--primary-base-color-1);
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0px;
}
.v3-paymentGateway-options > div:nth-child(3) {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  color: var(--tertiary-neutral-color-2);
}
.v3-paymentGateway-options > div:nth-child(3) > span {
  font-weight: 600;
  color: var(--tertiary-neutral-color-1);
}
.v3-paymentGateway-options-bgPatternLeft {
  position: absolute;
  left: 0;
  overflow: hidden;
  z-index: -1;
}
.v3-paymentGateway-options-bgPatternRight {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.v3-paymentGateway-options-chooseOptions {
  padding: 16px;
}
.v3-paymentGateway-options-chooseOptions > div:first-child {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  color: var(--tertiary-neutral-color-2);
}
.v3-paymentGateway-options-chooseOptions > div:nth-child(2) {
  margin-top: 25px;
}
.v3-paymentGateway-options-chooseOptions-option > div:first-child {
  display: flex;
  align-items: center;
  gap: 15px;
}
.v3-paymentGateway-options-chooseOptions-option-image {
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 8px;
  border: 1px solid rgba(222, 222, 222, 1);
}
.v3-paymentGateway-options-chooseOptions-option-text {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  color: var(--tertiary-neutral-color-1);
}
.v3-paymentGateway-options-chooseOptions-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}
.v3-paymentGateway-options-chooseOptions-option > div:last-child {
  cursor: pointer;
}
.v3-paymentUpiVerify-header {
  margin: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--tertiary-neutral-color-1);
}
.v3-paymentUpiVerify-header > div:first-child {
  font-size: 16px;

  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}
.v3-paymentUpiVerify-header > div:nth-child(2) {
  cursor: pointer;
}
.v3-paymentUpiVerify-content {
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--primary-base-color-2);
}
.v3-paymentUpiVerify-upiId-input {
  border: none;
  border-bottom: 1px solid var(--tertiary-neutral-color-2);
  outline: none;
  letter-spacing: 0.02em;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  margin: 10px 0px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 15px;
  color: var(--tertiary-neutral-color-1);
}
.v3-paymentUpiVerify-upiId-input:focus {
  border-bottom-color: var(--primary-color-1);
}
.v3-paymentUpiVerify-upiId-input-error {
  border-bottom: 1px solid rgba(224, 0, 0, 0.4);
}
.v3-paymentUpiVerify-invalid-upiId {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--tertiary-neutral-color-1);
}
.v3-paymentRequest-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
.v3-paymentRequest-container-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(116, 116, 116, 1);
  width: 70%;
  margin: auto;
  margin-bottom: 35px;
}
.v3-paymentRequest-container-warning {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: center;
  color: rgba(116, 116, 116, 1);
  width: 70%;
  margin: auto;
  margin-top: 35px;
}
.v3-paymentRequest-progressContainer {
  width: 50%;
  margin: auto;
  margin-bottom: 25px;
  position: relative;
}
.v3-paymentRequest-countdownTimer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.v3-paymentRequest-countdownTimer > div:first-child {
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}
.v3-paymentRequest-countdownTimer > div:last-child {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: center;
  margin-top: 10px;
}
.v3-paymentRequest-container-text > span {
  color: rgba(50, 50, 50, 1);
}
.v3-paymentSuccess-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--tertiary-neutral-color-1);
  margin: auto;
  margin-top: 30px;
  width: 80%;
}
.v3-paymentSuccess-description > span {
  color: var(--tertiary-neutral-color-1);
  font-weight: 700;
}
.v3-paymentSuccess-contentContainer {
  margin-top: 30vh;
}
.v3-paymentExpired-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
.v3-paymentExpired-image {
  display: flex;
  justify-content: center;
}
.v3-paymentExpired-text {
  margin: 30px auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--tertiary-neutral-color-1);
  width: 75%;
}
.v3-paymentExpired-changePaymentMode {
  margin-top: 30px;
}
.v3-paymentExpired-changePaymentMode > div:first-child {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--tertiary-neutral-color-1);
}
.v3-paymentExpired-changePaymentMode > div:last-child {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--tertiary-cta-text-color);
  text-decoration: underline;
  cursor: pointer;
}
.v3-paymentRequest-cancelPaymentBottomSheet {
  margin: 24px 16px;
}
.v3-paymentRequest-cancelPaymentBottomSheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 20px;
}
.v3-paymentRequest-cancelPaymentBottomSheet-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(116, 116, 116, 1);
  width: 80%;
  margin-bottom: 20px;
}
/* .swiper-slide {
  padding-bottom: 40px;
} */
.swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 24px;
}

.v3_mpin_image {
  position: absolute;
  top: -32%;
  margin: auto;
  left: 50%;
  transform: translate(-50%, 4%);
  z-index: 999;
}

.v3_mpin_page_image {
  top: -49%;
}

.v3_mpin_content_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.v3_mpin_second_half_container {
  /* padding-top: 15%; */
  /* height: 65vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* position: relative; */
  z-index: 1;
  background-color: var(--leading-white);
  padding-top: 25px;
  font-family: Mulish;
}

.v3_mpin_header_text {
  color: var(--primary-base-color-1);
  font-size: 14px;
  font-weight: 700;
  line-height: 20.08px;
  letter-spacing: 0.02em;
  text-align: center;
}

.v3_mpin_input_widget {
  padding-top: 15px;
  width: 85%;
}

.v3_mpin_forgot_pin_container {
  color: var(--tertiary-neutral-color-2);
  font-size: 14px;
  font-weight: 600;
  line-height: 17.57px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top: 10px;
}

.v3_mpin_header {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: var(--tertiary-neutral-color-1);
  margin-top: 30px;
}

.v3_mpin_verify_button {
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 10px;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  max-width: 450px;
}

.v3_mpin_reset_link {
  color: var(--tertiary-cta-text-color);
  text-decoration: underline;
}

.v3-setMpin-loader-container-content-title {
  color: var(--color-static-ui-sub-text-s, #323232);
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  width: 75%;
}
.v3-setMpin-loader-container-content-sub {
  color: var(--color-static-Campaign-Offers-Green-500, #177411);
  text-align: center;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  margin-top: 24px;
  width: 75%;
}
.v3-mpinIntro-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.v3-mpinIntro-top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 12px;
  padding-top: 11%;
}
.v3-mpinIntro-header-title {
  color: var(--tertiary-positive-color-1);
  text-align: center;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  margin-top: 12px;
}
.v3-mpinIntro-header-desc {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  margin-top: 6px;
}
.v3-login-journey-hero-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -100px;
}
.v3-mpinIntro-hero-background {
  position: relative;
  width: 65%;
  margin: auto;
}
.v3-mpinIntro-hero-background > img:first-child {
  width: 100%;
}
.v3-mpinIntro-hero-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  /* margin-top: 49px; */
}
.v3-mpinIntro-hero-fuel {
  position: absolute;
  left: -10%;
  top: 55%;
  width: 50px;
  z-index: 6;
}
.v3-mpinIntro-hero-bag {
  position: absolute;
  top: 0;
  left: 8%;
  width: 50px;
  z-index: 6;
}
.v3-mpinIntro-hero-offers {
  position: absolute;
  top: 3%;
  right: 0;
  width: 60px;
  z-index: 6;
}
.v3-mpinIntro-hero-popcorn {
  position: absolute;
  right: -10%;
  top: 55%;
  width: 40px;
  z-index: 6;
}
.v3-mpinIntro-hero-star {
  position: absolute;
  left: -20%;
  top: 50px;
}
.v3-mpinIntro-hero-small-star {
  position: absolute;
  right: -16%;
  top: 100px;
}
.v3-mpinIntro-hero-disc {
  position: absolute;
  bottom: -20px;
  width: 120%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.v3-mpinIntro-hero-card-vertical {
  position: absolute;
  width: 50%;
  transform: translateX(-50%) rotate(-10deg);
  top: 30px;
  left: 50%;
  z-index: 5;
}
.v3-mpinIntro-hero-card-vertical-stock {
  position: absolute;
  width: 40%;
  transform: translateX(-50%) rotate(-10deg);
  top: 245px;
  left: 50%;
  z-index: 5;
}
.v3-mpinIntro-hero-card-horizontal {
  position: absolute;
  width: 80%;
  transform: translateX(-50%) rotate(-10deg);
  top: 65px;
  left: 50%;
  z-index: 5;
}
.v3-mpinIntro-hero-card-horizontal-stock {
  position: absolute;
  width: 85%;
  transform: translateX(-50%) rotate(-10deg);
  top: 60px;
  left: 50%;
  z-index: 5;
}
.v3-login-journey-hero-card-horizontal-stock {
  position: absolute;
  width: 65%;
  transform: translateX(-50%) rotate(-5.676deg);
  top: 260px;
  left: 50%;
  z-index: 5;
  border-radius: 14px;
  border: 2px solid rgba(0, 0, 0, 0.07);
  background: var(--Colors-PRIMARY-ACCENT-1, #1965cf);
  box-shadow: 0px 64px 42px -30px rgba(0, 0, 0, 0.28);
}
.v3-login-journey-hero-card-horizontal {
  position: absolute;
  width: 65%;
  transform: translateX(-50%) rotate(-5.676deg);
  top: 260px;
  left: 50%;
  z-index: 5;
  border-radius: 14px;
}
.v3-mpinIntro-content {
  /* margin-top: 52px; */
  padding-top: 6%;
}
.v3-mpinIntro-content-title {
  color: var(--tertiary-neutral-color-2);
  text-align: center;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
}
.v3-mpinIntro-content-text-1 {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
.v3-mpinIntro-content-text-2 {
  color: var(--primary-color-1);
  font-family: Mulish;
  font-size: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.v3-mpinIntro-bottom {
  position: fixed;
  bottom: 0;
  background-color: var(--primary-color-5);
  max-width: 450px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 10px;
}
.v3-mpinIntro-cta-container-text {
  color: var(--tertiary-neutral-color-1);
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.v3-mpinIntro-cta-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
}
.v3-verify-otp-sub-text {
  color: var(--color-static-UI-Light-text, #747474);

  /* Mulish/Caption */
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
  margin-top: 8px;
  margin-bottom: 24px;
}
.v3-setMpin-cta-container1 {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  padding-bottom: 15px;
}

.setMpin-cta-tnc {
  padding-top: 16px;
}

.v3-container {
  border-radius: 8px;
  width: 181px;
  font-size: 14px;
  height: 288px;
  background-size: cover;
  background-position: center;
  /* margin-bottom: 20px; */
  position: relative;
}

.v3-horizontal-container {
  border-radius: 8px;
  width: 288px;
  font-size: 14px;
  height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5px;
  /* transform: scale(1.3); */
}

.v3-horizontal-card-name {
  padding-left: 8px;
}

.v3-close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.v3-vertical-close-icon {
  position: absolute;
  right: 10px;
  top: 18px;
  cursor: pointer;
}

.v3-copy-icon {
  margin-left: -5px;
  margin-top: 25px;
}
.v3-eye-icon-1 {
  margin-left: -14px;
  margin-top: 25px;
  width: 18px;
}
.v3-expcvv-container {
  display: flex;
  margin-left: 2px;
}
.v3-copy-icon-horizontal {
  margin-left: 8px;
  margin-top: -5px;
}
.v3-eye-icon-horizontal {
  margin-left: 10px;
  margin-top: -5px;
}
.v3-eye-icon {
  margin-left: 8px;
  margin-top: -15px;
}

.v3-horizontal-close-icon {
  margin-top: -20px;
}
.v3-horizontal-bottom-card {
  display: flex;
  margin-top: -4px;
}

.v3-card-name {
  padding-left: 12px;
  margin-top: 23px;
  width: 45%;
  font-weight: 700;
}

.v3-card-number {
  padding-left: 10px;
  padding-right: 39px;
  margin-top: 45px;
}
.v3-card-number-horizontal-number {
  display: flex;
  align-items: center;
}
.v3-card-number-horizontal-label {
  font-size: 8px;
  margin-bottom: -12px;
}
.v3-card-vertical-number {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 39px;
  /* margin-top: 45px; */
  padding-top: 87px;
}

.v3-cvv-expiry {
  padding-left: 10px;
  padding-right: 10px;
}

.v3-expiry-cvv {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: -12px;
}
.v3-card-expiry-number {
  margin-top: -12px;
  font-weight: 700;
}

.v3-horizontal-expiry-cvv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  gap: 10px;
  margin-top: -15px;
}
.v3-horizontal-expiry {
  margin-top: -15px;
  font-weight: 600;
}

.v3-cvv-icon-container {
  display: flex;
  align-items: center;
  margin-top: -18px;
  width: 18px;
}

.v3-horizontal-cvv-icon-container {
  display: flex;
  align-items: center;
  margin-top: -20px;
  width: 18px;
}

.v3-no-transaction-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 30px 15px;
}

.v3-no-transaction-text {
  font-size: 1rem;
  font-weight: 700;
  color: var(--primary-base-color-1);
  color: var(--primary-base-color-1);
  text-align: center;
}

/* view all transactions */
/* .v3-view-all-transaction-button {
  position: fixed; 
  bottom: 20px; 
  left: 0;
  right: 0;
  margin-left: auto;
} */

/* transaction section  */
.v3-transactions-section {
  /* position: absolute; */
  padding: 28px 20px 30px 20px;
  width: 100%;
}

.v3-transactions-section-header {
  padding-bottom: 10px;
  font-weight: 700;
  color: var(--primary-base-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.16px;
}

.v3-transaction-section {
  display: flex;
  justify-content: space-between;
}

.v3-transaction-content {
  position: relative;
  border-bottom: 1px solid var(--tertiary-neutral-color-3);
  padding-top: 12px;
  padding-bottom: 12px;
}

.v3-transaction-left-section {
  display: flex;
  align-items: center;
  gap: 12px;
}
.v3-transaction-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.v3-transaction-logo-image {
  width: 100%;
}
.v3-transaction-title {
  font-size: 0.85rem;
  color: var(--tertiary-neutral-color-1);
  font-weight: 600;
}

.v3-transaction-created-date {
  font-size: 0.85rem;
  color: var(--tertiary-neutral-color-2);
  font-weight: 500;
  display: flex;
  gap: 8px;
  align-items: center;
}

.v3-transaction-date-and-type-section {
  display: flex;
  gap: 8px;
  height: 15px;
}
.v3-transaction-credit-type {
  padding: 2px 4px 2px 4px;
  font-size: 0.563rem;
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v3-transaction-bottomsheet {
  padding: 28px 0px 14px 0px;
}

.v3-transaction-bottomsheet-header-section {
  padding: 0px 26px 28px 28px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--tertiary-neutral-color-3);
}

.v3-transaction-bottomsheet-header-container {
  display: flex;
  align-items: center;
  gap: 18px;
}

.v3-transaction-bottomsheet-header-text {
  color: var(--primary-base-color-2);
  font-weight: 500;
  font-size: 1rem;
}

.v3-transaction-bottomsheet-header-amount {
  font-size: 1.75rem;
  color: var(--primary-base-color-1);
  font-weight: 700;
  gap: 4px;
}

.v3-transaction-bottomsheet-txn-nature-image {
  padding-bottom: 10px;
}

.v3-transaction-bottomsheet-amount-section {
  display: flex;
  align-items: center;
  gap: 4px;
}

.v3-transaction-bottomsheet-body-section {
  padding: 32px 24px 32px 24px;
  border-bottom: 1px solid var(--tertiary-neutral-color-3);
}

.v3-transaction-bottomsheet-detials-container,
.v3-transaction-bottomsheet-detials-container-last {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.v3-transaction-bottomsheet-detials-container-last {
  padding-bottom: 0;
}

.v3-transaction-bottomsheet-details-key {
  color: var(--tertiary-neutral-color-1);
  font-size: 0.875rem;
  font-weight: 700;
}

.v3-transaction-bottomsheet-details-value {
  color: var(--tertiary-neutral-color-2);
  font-size: 0.875rem;
  font-weight: 700;
}

.v3-transaction-bottomsheet-footer-section {
  padding: 16px;
  padding-bottom: 0;
  text-align: center;
}

.v3-transaction-bottomsheet-converted-to-emi-container {
  text-align: center;
}

.v3-transaction-bottomsheet-converted-to-emi-container {
  color: var(--tertiary-neutral-color-2);
  font-size: 0.75rem;
  font-weight: 500;
  padding-bottom: 12px;
}

.v3-transaction-bottomsheet-converted-to-emi-icon {
  padding-right: 4px;
}

.v3-home-transaction-refund {
  color: #1965cf;
  font-weight: 700;
  background-color: #acc8ee;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  font-size: 0.563rem;
  display: flex;
  align-items: center;
}

.v3-home-transaction-cashback {
  color: #ffffff;
  font-weight: 700;
  background-color: #1965cf;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  font-size: 0.563rem;
  display: flex;
  align-items: center;
}

.v3-transaction-amount {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--tertiary-neutral-color-1);
}

.v3-transaction-reward-point {
  font-size: 0.625rem;
  font-weight: 600;
  color: var(--primary-color-1);
  float: right;
  background-color: var(--primary-color-5);
  border-radius: 50px;
  padding: 2px 4px 2px 4px;
}

.v3-transaction-cashback-amount {
  font-size: 0.625rem;
  font-weight: 600;
  color: #23951c;
  background-color: #f2f9f1;
  border-radius: 50px;
  padding: 2px 4px 2px 4px;
}

.v3-transaction-right-section {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.v3-transaction-emi-failed-nudge {
  padding: 8px 12px 8px 12px;
  background-color: #fef2f2;
  border-radius: 8px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v3-transaction-emi-failed-nudge-left-section {
  display: flex;
  align-items: center;
  gap: 4px;
}

.v3-transaction-emi-failed-text {
  font-size: 0.75rem;
}

.v3-transaction-emi-failed-logo-retry-text {
  font-weight: 700;
  color: #0e0e0e;
}

.v3-transaction-emi-failed-retry-logo {
  height: 28px;
}

.v3-transaction-emi-failed-logo {
  height: 28px;
}

/* view all transactions */
.v3-view-all-transaction-button {
  position: fixed;
  bottom: 0px;
  height: 40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-decoration: underline;
  color: var(--tertiary-cta-text-color);
  cursor: pointer;
  font-size: 0.875rem;
  background-color: var(--leading-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75px;
}

.v3-card-number,
.v3-cvv-expiry {
  color: white;
}

.v3-card-exipry {
  font-size: 8px;
}

.v3-cvv-label {
  font-size: 8px;
}

.v3-horizontal-cvv-label {
  font-size: 8px;
  margin-top: 10px;
  color: white;
}

.v3-horizontal-cvv-expiry {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -10px;
}

.v3-vertical-card-number {
  /* padding-top: 10px; */
  color: white;
  font-weight: 600;
  font-size: large;
  width: 80%;
}

.v3-card-vertical-number-section {
  display: flex;
  flex-direction: column;
}

.v3-card-vertical-number-label {
  margin-bottom: 0;
  font-size: 8px;
}

.v3-horizontal-card-number {
  color: white;
  margin-top: 10px;
  font-weight: 600;
}

.v3-vertical-cvv {
  color: white;
  font-weight: 700;
}
.v3-horizontal-cvv {
  color: white;
  /* margin-top: -10px; */
}

@media screen and (max-width: 374px) {
  .horizontal-card-width-height-for-all {
    width: 300px !important;
    height: 175px !important;
  }

  .horizontal-card-width-height-view-card-for-all {
    width: 255px !important;
    height: 130px !important;
  }
}
@media screen and (max-width: 330px) {
  .horizontal-card-width-height-for-all {
    width: 260px !important;
    height: 135px !important;
  }
  .horizontal-card-width-height-view-card-for-all {
    width: 190px !important;
    height: 90px !important;
  }
}
.v3-convert-to-emi {
  font-size: 0.75rem;
  color: #1965cf;
  min-width: 84px;
  font-weight: 500;
  cursor: pointer;
}

/* show all transaction v3 styles  */
.v3-filters-section {
  margin: 0 auto;
  padding-top: 2px;
  margin-top: 3%;
  overflow-x: scroll;
}

.v3-filters-section::-webkit-scrollbar {
  display: none;
}

.v3-filters-container {
  display: flex;
  gap: 12px;
  padding-left: 21px;
}

.v3-filters-card {
  font-size: 0.75rem;
  padding: 6px 12px;
  border: 1.6px solid var(--tertiary-neutral-color-3);
  border-radius: 100px;
  display: flex;
  align-items: center !important;
  gap: 4px;
  min-height: 1.2rem;
  cursor: pointer;
  line-height: 20px;
  font-weight: 600;
}

.v3-filters-card.active {
  color: var(--secondary-color-1);
  font-weight: 600;
  background-color: var(--secondary-color-4);
  /* box-shadow: 0px 1px 4px 0px #30303029; */
  min-height: 1.2rem;
  border-color: var(--secondary-color-2);
}

.v3-filters-card-cross {
  /* padding-top: 3px; */
}

.v3-all-transactions-cycle-section {
  padding: 18px 20px 10px 20px;
  color: var(--tertiary-neutral-color-2);
  font-size: 0.75rem;
}

.v3-all-transaction-cycle-date {
  color: var(--tertiary-neutral-color-1);
  font-weight: 500;
}

.v3-all-transactions-section {
  padding: 8px 20px 20px 20px;
}

/* new user page styles for statement summary  */
.v3-statement-new-user-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 70%;
  margin-top: 62px;
}

.v3-statement-new-user-image-bg {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 15%;
  z-index: -1;
}

.v3-statement-new-user-text-section {
  position: absolute;
  text-align: center;
  top: 488px;
}

.v3-statement-new-user-header-text {
  font-weight: 700;
  font-size: 1rem;
  color: var(--primary-base-color-1, #0d2950);
}

.v3-statement-new-user-description-text {
  font-weight: 500;
  font-size: 0.75rem;
  color: var(--tertiary-neutral-color-2, #a8a8a8);
  padding: 10px 40px;
}
.v3-statement-transaction-zero-state-wrapper {
  margin-top: 12px;
}
/* set/Reset MPIN screen start */
.v3-setMpin-info-widget {
  width: 90%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin: auto;
  border-radius: 8px;
  margin-top: 8px;

  color: var(--tertiary-neutral-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.v3-setMpin-info {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  color: var(--tertiary-neutral-color-2);
}
/* set/Reset MPIN screen End */

/* mpin blocked sections v3 */
.v3_mpin_blocked_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - 48px);
  height: calc(100vh - 48px);
}
.v3_mpin_block_image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 28px;
}
.v3_mpin_block_reset_mpin_button {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 22px;
  position: absolute;
  bottom: 10px;
}
.v3_mpin_block_card_header_text {
  text-align: center;
  padding-bottom: 8px;
  color: var(--tertiary-neutral-color-1);
  font-weight: 700;
  font-size: 1rem;
}
.v3_mpin_block_card_description_text,
.v3_mpin_block_card_description_timelefttext {
  text-align: center;
  padding: 0 22px;
  color: var(--tertiary-neutral-color-2);
  font-weight: 400;
  font-size: 0.875rem;
}
.v3_mpin_block_card_description_timelefttext {
  padding-top: 32px;
}
.v3-mpin_expired_image_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 80%;
  margin-top: -90px;
}
.v3-mpin-expired-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}
.v3-mpin-expired-image {
  width: 120px;
  margin-left: 25px;
}
.v3-mpin-expired-text > div:first-child {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 20px;
  font-weight: 700;
  line-height: 130%; /* 26px */
  letter-spacing: 0.2px;
}
.v3-mpin-expired-text > div:last-child {
  color: var(--primary-base-color-3);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
}
.v3_mpin_expired_reset_button_container {
  padding: 16px;
  bottom: 0;
  border-top: 1px solid var(--tertiary-neutral-color-3);
  background: var(--leading-white);
}
.v3_otp_block_contact_support_section {
  color: var(--tertiary-neutral-color-1);
  font-weight: 700;
  font-size: 0.875rem;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 730px;
}
.v3_otp_block_contact_support_text {
  color: var(--tertiary-cta-text-color);
}
.v3-auth-header-bg {
  position: absolute;
  height: 30vh;
  width: 100%;
  overflow: hidden;
}
.v3-reg-verify-mpin-error-msg {
  display: flex;
  gap: 5px;
  padding: 8px 12px;
  background: #fff4f7;
  width: 85%;
  font-size: 12px;
  border-radius: 8px;
  margin: auto;
  margin-bottom: 10px;
  align-items: center;
}

.v3-error-msg {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  background: #fff4f7;
  width: 85%;
  font-size: 12px;
  border-radius: 8px;
  margin: auto;
}
.v3-otp-bottomSheet-error {
  margin-bottom: 10px;
  margin-top: -15px;
}
.v3-otp-bottomSheet-resend-text {
  color: var(--tertiary-neutral-color-2);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
}
.v3-otp-bottomSheet-resend-time {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
}
.v3-otp-bottomSheet-resend-otp-button {
  color: var(--secondary-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
}

.v3-verify-mpin-bottomsheet-header {
  display: flex;
  justify-content: space-between;
  padding: 24px 26px 28px 28px;
  align-items: center;
}
.v3-otp-input-bottom-sheet-content-mpin {
  padding: 28px 20px 28px 20px;
}
.v3-verify-mpin-title {
  color: var(--neutral-1, #0e0e0e);
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}
.v3-home-transactions-zero-state {
  height: 20vh;
}
.v3-home-transaction-zero-state-container {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 1px 8px 1px 1px;
  border: 1px solid var(--primary-base-color-4);
  border-radius: 12px;
}
.v3-verify-mpin-title-BottomSheet {
  color: var(--neutral-1, #0e0e0e);
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}
/* .v3-customer-auth-text {
  width: 235px;
} */

.v3-home-transaction-text-section {
  padding: 12px 4px 12px 4px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.v3-home-transaction-header-text {
  color: var(--primary-base-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
}
.v3-home-transaction-description-text {
  font-weight: 500;
  color: var(--primary-base-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-weight: 500;
}

/* Info Prompt */
.v3-info-prompt {
  background-color: var(--tertiary-neutral-color-3);
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  font-size: 12px;
  border-radius: 8px;
  margin: auto;
}
.v3-info-propmt-text {
  color: var(--tertiary-neutral-color-1);
}
.v3-cardSettings-info-prompt {
  margin-top: 10px;
  font-weight: 500;
  size: 10px;
}

/* Pwa loader styles  */
@keyframes slidesUp {
  from {
    transform: translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.v3-pwa-redirection-loader-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 180px;
  height: 95vh;
  position: relative;
}

.v3-pwa-redirection-loader-animation {
  animation: slidesUp 0.4s ease-out forwards;
}
.v3-pwa-redirection-loader-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v3-pwa-redirection-loader-image {
  height: 175.5px;
  width: 175.5px;
}
.v3-pwa-redirection-loader-header-text {
  text-align: center;
  font-size: 1rem;
  font-weight: 800;
  color: var(--tertiary-neutral-color-1);
}
.v3-pwa-redirection-loader-header-text-description {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--primary-base-color-1);
}
.v3-pwa-redirection-loader-footer-section {
  position: absolute;
  bottom: 0px;
  height: 180px;
  width: 100%;
}
.v3-pwa-redirection-loader-branding-logo-section {
  text-align: center;
  color: var(--primary-base-color-4);
}
.v3-pwa-redirection-loader-hyperface-logo {
  padding-top: 12px;
}
.v3-virtual-card-flip-second {
  animation: flip-card-second 0.8s forwards;
}

.v3-virtual-card-flip-first {
  animation: flip-card-first 0.6s backwards;
}

@keyframes flip-card-second {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes flip-card-first {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.customerAuth {
  width: 90%;
}
.fkw-web-filter {
  width: 100%;
}
.mpin-error-strip-bs {
  width: 93%;
}
.mpin-error-strip-bs {
  width: 93%;
}
.v3-rewards-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 50%;
  padding: 3px;
}

/* V3 Rewards and Cashbacks homepage start */
.v3-rewards-cashbacks-container {
  padding: 20px;
  background: var(--leading-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.v3-rewards-cashback-text-container {
  display: flex;
  gap: 5px;
  align-items: center;
}
.v3-rewards-cashback-text-number {
  color: var(--tertiary-neutral-color-1);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
}
.v3-rewards-cashback-text {
  color: var(--primary-base-color-2);
  font-size: 12px;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
}
.v3-rewards-cashback-left-container {
  display: flex;
  align-items: center;
  gap: 6px;
}
.v3-rewards-cashback-view-btn-text {
  color: var(--primary-color-1);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1px;
}
.v3-rewards-cashback-view-btn {
  display: flex;
  align-items: center;
  gap: 2px;
}
.v3-rewards-cashback-arrow-right {
  transform: scale(0.5);
}
.v3-rewards-cashback-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.v3-rewards-cashback-wrapper-width {
  width: 100%;
}

/* Tokenisation start */

.tokenisation-merchant-card-box {
  width: 48%;
  border-radius: 12px;
  margin-bottom: 12px;
  border: 1px solid var(--primary-base-color-4, #d3d8df);
  background: var(--leading-white, #fff);
}

.tokenisation-merchant-card-inside-first {
  margin-top: 8.2%;
}
.tokenisation-merchant-card-inside-second {
  margin-top: 8.2%;
  padding-top: 4.1%;
  padding-left: 4.1%;
  padding-bottom: 4.1%;
  border-radius: 17px;
  width: fit-content;
  padding-right: 7%;
}
.tokenisation-merchant-card-inside-icon {
  border-radius: 50%;
  width: 21%;
  border: 1px solid;
  border-color: var(--tertiary-neutral-color-3);
}
.tokenisation-add-card-inside-icon {
  border-radius: 50%;
  width: 10%;
  border: 1px solid;
  border-color: var(--tertiary-neutral-color-3);
}
.tokenisation-add-card-inside-text {
  font-weight: 700;
  font-size: 0.75rem;
  margin-left: 8px;
  color: var(--neutral-1);
}
.tokenisation-add-card-inside-check-container {
  margin-left: auto;
  margin-right: 6px;
}
.tokenisation-merchant-card-inside-name {
  font-size: 0.875rem;
  color: var(--neutral-1);
  font-family: Mulish;
  font-weight: 700;
  padding-right: 1px;
}

.tokenisation-merchant-card-box {
  padding-left: 3.5%;
}

.v3-add-card-to-merchant-container {
  padding-bottom: 180px;
}

.tokenisation-add-card-merchant-container {
  display: flex;
  margin: 20px;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 13px;
}
.tokenisation-add-card-search-merchant-container {
  margin: 20px;
}
.tokenisation-add-card-popular-merchant-container {
  margin: 20px;
  font-size: 0.875rem;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 24px;
  color: var(--neutral-1);
}
.tokenisation-add-card-divider-container {
  width: 80%;
  margin: auto;
}
.tokenisation-add-card-all-merchants-container {
  margin: 20px;
  font-size: 0.875rem;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 24px;
  color: var(--neutral-1);
}
.tokenisation-add-card-cta-container,
.tokenisation-saved-card-cta-container-div {
  display: flex;
  margin-left: 8%;
  margin-right: 8%;
  font-weight: 500;
  font-size: 0.75rem;
  margin-bottom: 18px;
}
.tokenisation-add-card-cta-text,
.tokenisation-saved-card-cta-text {
  margin-left: 8px;
  color: var(--primary-color-1);
}
.tokenisation-merchant-card-inside-div {
  margin-bottom: 10.9%;
}
.tokenisation-merchant-card-inside-menu-icon {
  float: right;
  margin-right: 10px;
  margin-top: 2.5%;
}
.tokenisation-merchant-card-inside-second-icon {
  display: flex;
  align-items: center;
}
.tokenisation-merchant-card-inside-second-text {
  font-weight: 500;
  font-size: 0.75rem;
  margin-left: 4px;
}
.tokenisation-merchant-card-inside-second-retry-text {
  font-size: 0.625rem;
  font-weight: 700;
  margin-top: 12px;
  color: var(--primary-color-1);
}
.v3-manage-cards-active-title {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.v3-manage-cards-active-title-text {
  font-size: 0.875rem;
  font-weight: 700;
  justify-content: space-between;
}
.v3-manage-cards-active-title-arrow-container {
  cursor: pointer;
}
.v3-manage-cards-merchant-list-container {
  margin: 20px;
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5px;
}
.tokenisation-saved-card-merchant-container {
  margin-bottom: 200px;
}
/* Tokensation ends */
.v3-rewards-cashback-text-container-small {
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
}
.v3-rewards-cashback-text-small {
  margin-top: -3px;
  display: flex;
  gap: 4px;
}
.v3-rewards-cashback-divider {
  border-left: 1px solid var(--tertiary-neutral-color-3);
  align-self: stretch;
}
/* V3 Rewards and Cashbacks homepage end */
/* V3 Rewards and Cashbacks start */
.v3-rewards-cashback-points-section {
  padding: 24px 20px;
  position: relative;
}
.v3-rewards-cashback-points-section-background-btm {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  background-color: var(--primary-base-color-5);
  left: 0;
}
.v3-rewards-cashback-points-banner-wrapper {
  border-radius: 24px;
  border: 1px solid var(--primary-base-color-4);
  background: var(--leading-white, #fff);
  overflow: hidden;
  position: relative;
  z-index: 5;
  padding-bottom: 9px;
}
.v3-rewards-cashback-points-banner-background > img {
  width: 100%;
}
.v3-rewards-cashback-points-banner-icon {
  width: fit-content;
  border-radius: 50%;
  margin: auto;
  padding: 7px;
  transform: scale(2.5);
  margin-top: -16px;
}
.v3-rewards-cashback-points-banner-cashback-icon {
  width: fit-content;
  border-radius: 50%;
  margin: auto;
  padding: 7px;
  transform: scale(2);
  margin-top: -16px;
  background-color: var(--leading-white);
}
.v3-rewards-cashback-points-banner-points-container {
  width: 100%;
  margin-top: 28px;
  margin-bottom: 20px;
}
.v3-rewards-cashback-points-banner-points-container > div:first-child {
  color: var(--primary-color-1);
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 130%; /* 36.4px */
  letter-spacing: 0.28px;
}
.v3-rewards-cashback-points-banner-points-container > div:last-child {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
}
.v3-rewards-cashback-points-lifetime-points-container {
  display: flex;
  width: fit-content;
  align-items: center;
  margin: 18px auto 28px auto;
  border-radius: 18px;
  background: var(--primary-color-5);
  padding: 4px 18px;
  gap: 12px;
}
.v3-rewards-cashback-points-lifetime-points-left {
  display: flex;
  align-items: center;
  gap: 0px;
}
.v3-rewards-cashback-points-lifetime-points-text {
  color: var(--primary-base-color-2);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
}
.v3-rewards-cashback-points-lifetime-points-icon {
  padding: 0px;
  transform: scale(0.6);
}
.v3-rewards-cashback-points-lifetime-points {
  color: var(--tertiary-neutral-color-1);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
}
.v3-rewards-cashback-points-bottom-section {
  padding: 20px;
  border-top: solid var(--primary-base-color-5) 1px;
}
.v3-rewards-cashback-points-expiring {
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--tertiary-negative-color-1);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1px;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
}
.v3-rewards-cashback-points-earn-banner {
  margin: 20px;
  position: relative;
  aspect-ratio: 4.7/1;
  display: flex;
  align-items: center;
}
.v3-rewards-cashback-points-earn-banner > img {
  width: 100%;
  border-radius: 12px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.v3-rewards-cashback-points-earn-banner-text {
  margin-left: 23%;
  margin-right: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 21px;
}
.v3-rewards-cashback-points-earn-banner-text > div:first-child {
  color: var(--primary-base-color-2);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
  width: 60%;
}
.v3-rewards-cashback-points-earn-banner-text > div:last-child {
  color: var(--primary-color-1);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1px;
  display: flex;
  gap: 3px;
}
.v3-rewards-cashback-history {
  background: var(--leading-white);
}
.v3-rewards-cashback-history-sticky-header {
  position: sticky;
  top: 0;
  background: var(--leading-white);
}
.v3-rewards-cashback-history-header {
  margin: 0px 20px 14px 20px;
  padding-top: 28px;
  color: var(--primary-base-color-1);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.16px;
}
.v3-rewards-cashback-history-filters {
  margin: 14px 0px 10px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-left: 20px;
  padding-right: 20px;
}
.v3-rewards-cashback-history-filters-text {
  margin: 10px 20px 0px 20px;
  padding-bottom: 5px;
  color: var(--primary-base-color-2);
  font-size: 12px;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  font-family: var(--Font-Family, Mulish);
}
.v3-rewards-cashback-history-filters-text > span {
  font-weight: 700;
  letter-spacing: 0.12px;
}
.v3-rewards-cashback-history-transactions {
  margin: 25px 20px 0px 20px;
}
.v3-rewards-cashback-transaction {
  padding-bottom: 12px;
  border-bottom: solid 1px var(--tertiary-neutral-color-3);
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}
.v3-rewards-cashback-transaction-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.v3-rewards-cashback-transaction-logo > img {
  width: 100%;
  height: 100%;
}
.v3-rewards-cashback-transactions-details {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: flex-start;
}
.v3-rewards-cashback-transactions-details-text {
  width: 67%;
}
.v3-rewards-cashback-transactions-details-text > div:first-child {
  color: var(--tertiary-neutral-color-1);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v3-rewards-cashback-transactions-details-text > div:last-child {
  color: var(--tertiary-neutral-color-2);
  font-size: 12px;
  font-weight: 500;
}
.v3-rewards-cashback-transactions-points {
  display: flex;
  align-items: center;
  gap: 3px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
}
.v3-reward-redeemed-icon {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--primary-color-4);
}
.v3-rewards-cashback-bottom-sheet-header-container {
  position: absolute;
  top: 0;
  width: 100%;
  background: var(--leading-white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}
.v3-rewards-cashback-bottom-sheet-header {
  padding: 28px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid var(--tertiary-neutral-color-3);
}
.v3-rewards-cashback-bottom-sheet-header-content {
  display: flex;
  align-items: center;
  gap: 18px;
  flex-grow: 1;
}
.v3-rewards-cashback-bottom-sheet-header-merchant-logo {
  width: 68px;
  height: 68px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.v3-rewards-cashback-bottom-sheet-header-merchant-logo > img {
  width: 100%;
  height: 100%;
}
.v3-rewards-cashback-bottom-sheet-header-text {
  width: 60%;
}
.v3-rewards-cashback-bottom-sheet-header-text > div:first-child {
  color: var(--primary-base-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}
.v3-rewards-cashback-bottom-sheet-header-text > div:last-child {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: var(--Font-Family, Mulish);
  font-size: 28px;
  font-weight: 700;
  line-height: 130%; /* 36.4px */
}
.v3-rewards-cashback-header-redeemed-icon {
  transform: scale(1.6);
}
.v3-rewards-cashback-default-icon {
  padding: 0px;
  transform: scale(2.3);
}
.v3-reward-cashback-btmSheet-cashback-icon {
  transform: scale(1.7);
}
.v3-rewards-cashback-accordian {
  padding: 24px;
}
.v3-rewards-cashback-accordian-content {
  margin-top: 24px;
}
.v3-rewards-cashback-accordian-transaction-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.v3-rewards-cashback-accordian-transaction-details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.v3-rewards-cashback-accordian-transaction-details > div > div:first-child {
  color: var(--tertiary-neutral-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
}
.v3-rewards-cashback-accordian-transaction-details > div > div:last-child {
  color: var(--tertiary-neutral-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
  /* For transaction id and copy to clipboard */
  display: flex;
  align-items: center;
  gap: 8px;
}
.v3-rewards-cashback-accordian-copy-to-clipboard {
  display: flex;
  align-items: center;
}
.v3-rewards-cashback-accordian-redemption-details {
  margin-top: 24px;
}
.v3-rewards-cashback-accordian-redemption-details > div:first-child {
  color: var(--tertiary-neutral-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
  margin-bottom: 8px;
}
.v3-rewards-cashback-accordian-redemption-details > div:last-child {
  color: var(--tertiary-neutral-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
}
.v3-rewards-cashback-bottom-sheet-cta-container {
  border-top: 1px solid var(--tertiary-neutral-color-3);
  padding: 16px;
  background: var(--leading-white);
  position: absolute;
  bottom: 0;
  width: 100%;
}
.v3-rewards-cashback-transaction-bottom-sheet-cta-container {
  border-top: 1px solid var(--tertiary-neutral-color-3);
  padding: 16px;
  background: var(--leading-white);
}
.v3-rewards-bottomm-sheet-header {
  padding: 28px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid var(--tertiary-neutral-color-3);
}
.v3-rewards-bottomm-sheet-header > div:first-child {
  display: flex;
  align-items: center;
  gap: 12px;
}
.v3-rewards-bottom-sheet-header-text {
  color: var(--primary-base-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 16px;
  font-weight: 500;
}
.v3-rewards-bottom-sheet-header-points {
  font-family: var(--Font-Family, Mulish);
  font-size: 28px;
  font-weight: 700;
  line-height: 130%; /* 36.4px */
}
.v3-rewards-bottom-sheet-reward-icon-container {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-rewards-bottom-sheet-reward-icon {
  padding: 0px;
  transform: scale(1.8);
}
.v3-rewards-bottom-sheet-hourglass {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
}
.v3-rewards-lifetime-points-details {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.v3-rewards-lifetime-points-details > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v3-rewards-lifetime-points-details > div > div:first-child {
  color: var(--tertiary-neutral-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
}
.v3-rewards-lifetime-points-details > div > div:last-child {
  color: var(--tertiary-neutral-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
}
.v3-rewards-lieftime-points-cta-container {
  border-top: 1px solid var(--System-Palette-neutral-3, #f1f1f1);
  padding: 16px;
}
.v3-rewards-expiring-transactions-container {
  padding: 22px 24px;
  padding-bottom: 10px;
  background: var(--primary-base-color-5);
  padding-bottom: 80px;
  padding-top: 130px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.v3-rewards-cashback-transaction-expiring {
  align-items: center;
  border-bottom: 1px solid var(--primary-base-color-4);
}
.v3-rewards-cashback-transaction-expiring-last {
  border-bottom: none;
  padding-bottom: 0px;
}
.v3-rewards-cashback-transaction-details-expiring {
  align-items: center;
}
.v3-rewards-transaction-filter-bottom-sheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 20px 12px 30px;
}
.v3-rewards-transaction-filter-bottom-sheet-header > div:first-child {
  color: var(--tertiary-neutral-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
}
.v3-rewards-transaction-filter-tabs {
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--tertiary-neutral-color-2);
}
.v3-rewards-transaction-filter-tabs > div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 14px 0px;
}
.v3-rewards-transaction-filter-tabs-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v3-rewards-transaction-filter-tabs-icon-image {
  transform: scale(1.5);
}
.v3-rewards-transaction-filter-tabs-text {
  font-family: var(--Font-Family, Mulish);
  font-size: 16px;
}
.v3-rewards-transaction-filter-cta-container {
  padding: 16px;
  border-top: 1px solid var(--tertiary-neutral-color-3);
}
.v3-rewards-transaction-filter-billing-cycles {
  padding: 18px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.v3-rewards-transaction-filter-billing-cycles > div {
  justify-content: space-between;
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;

  margin: 0px 12px;
  padding: 7px 8px 7px 28px;
  display: flex;
  border-radius: 8px;
}
.v3-rewards-cashback-milestone-icon {
  transform: scale(1.6);
}
.v3-rewards-cashback-url-text {
  color: var(--tertiary-neutral-color-1);
  font-size: 12px;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
}
.v3-rewards-cahsback-no-lifetime-points {
  color: var(--primary-base-color-3);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.16px;
  margin: 20% 83px;
}
.v3-rewards-cashback-history-no-transactions {
  margin: 53px auto 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 53px;
}
.v3-rewards-cashback-history-no-transactions > img {
  height: 160px;
}
.v3-rewards-cashback-history-no-transactions > div {
  text-align: center;
  color: var(--primary-base-color-1);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.16px;
}
.v3-rewards-transaction-filter-custom-range {
  border-radius: 0px;
  width: fit-content;
  padding: 10px 28px;
  gap: 32px;
}
.v3-rewards-transaction-filter-custom-range-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
}
.v3-rewards-transaction-filter-custom-range-input {
  width: 100px;
  height: 32px;
  padding: 8px 12px;
  border: none;
  outline: none;
  border-bottom: 0.5px solid var(--color-static-UI-Separator, #dedede);
}
.v3-rewards-transaction-filter-custom-range-input::placeholder {
  color: var(--tertiary-neutral-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
}
.v3-Rewards-zero-state-transaction {
  display: flex;
  align-items: center;
  gap: 11px;
  border-radius: 12px;
  border: 1px solid var(--primary-base-color-4);
  margin: 24px 20px;
  background-color: var(--leading-white);
}
.v3-Rewards-zero-state-transaction-text {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0px 8px 0px 0px;
}
.v3-Rewards-zero-state-transaction-text > div:first-child {
  color: var(--primary-base-color-1);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
}
.v3-Rewards-zero-state-transaction-text > div:last-child {
  color: var(--primary-base-color-2);
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-weight: 500;
}
/* V3 Rewards and Cashbacks end */

/* V3 filters start */
.v3-filter-wrapper {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 4px;
  padding: 0px 12px;
  border-radius: 100px;
  min-height: 35px;
}
.v3-filter-text {
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  letter-spacing: 0.12px;
  flex-shrink: 0;
  white-space: nowrap;
}
/* V3 filters end */
.v3-mpinIntro-animation-bg-2 {
  position: absolute;
  width: 100%;
  top: -90px;
}

.v3-mpinIntro-animation-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 280px;
}

.v3-mpin-success-card {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 38%;
}

.v3-mpinIntro-animation-bg-1 {
  position: absolute;
  width: 100%;
}

.v3-mpinIntro-content-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 160px;
}

.v3-mpinIntro-animation-card {
  position: absolute;
  top: 145px;
  left: 38%;
}

.v3-mpinIntro-animation-card-image {
  width: 120px;
  opacity: 0;
  transition: all 0.8s ease;
  transform: scale(1) rotate(5deg);
}

.v3-mpinIntro-animation-card.animate .v3-mpinIntro-animation-card-image {
  transform: scale(2) rotate(-11.51deg);
  opacity: 1;
  box-shadow: 0px 4px 24px 0px #00000024;
}

.v3-mpinIntro-animation-vertical-card {
  position: absolute;
  top: 142px;
  left: 52%;
}

.v3-mpinIntro-animation-vertical-card-image {
  width: 120px;
  opacity: 0;
  transition: all 0.8s ease;
  transform: translateX(0) scale(1) rotate(108deg);
  box-shadow: 0px 4px 24px 0px #00000024;
}

.v3-mpinIntro-animation-vertical-card.animate
  .v3-mpinIntro-animation-vertical-card-image {
  transform: translateX(-60px) scale(2) rotate(79deg);
  opacity: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 27vh;
}

.v3-mpinSuccess-content-background {
  position: relative;
  animation: zoomInOutBg 0.5s ease;
}

@keyframes zoomInOutBg {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.v3-success-bottomSheet-cta-primary,
.v3-success-bottomSheet-cta-secondary {
  display: flex;
  justify-content: center;
}

.v3-success-bottomSheet-textOver-cta {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  gap: 4px;
  align-items: center;
}

.v3-success-bottomSheet-textOver-cta-text {
  color: var(--primary-base-color-1);
  font-size: 12px;
  font-weight: 700;
}

.v3-failure-bottom-sheet-cta-container {
  padding: 16px;
  border-top: 1px solid var(--tertiary-neutral-color-3);
  background: var(--leading-white);
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.v3-allCvpOffers-offers-hf-branding {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 32px;
}

.v3-pwa-redirection-logos {
  display: flex;
  width: 100%;
  height: 48px;
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.v3-transaction-skeleton-loader {
  display: flex;
  align-items: center;
  gap: 9px;
}

.v3-quick-action-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background-color: var(--primary-color-5);
}

/* Error screen start */
.v3-error-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 450px;
  margin: auto;
}

.v3-error-screen-cta-container {
  background-color: var(--leading-white);
  width: 100%;
  padding: 16px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.14);

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.v3-error-screen-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.v3-error-screen-text > div:first-child {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
}

.v3-error-screen-text > div:last-child {
  color: var(--primary-base-color-1);
  text-align: center;
  width: 75%;
  margin: auto;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  margin-top: 11px;
  opacity: 0.5;
}

.v3-warning-background {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--tertiary-negative-color-3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
}

.v3-warning-icon-bottomSheet {
  transform: scale(0.65);
}
/* Error screen end */

.v3-infinite-scroll-transaction-loader {
  padding-bottom: 5px;
}

/* hide scrollbar in bottom sheet */
[data-rsbs-scroll] {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
[data-rsbs-scroll]::-webkit-scrollbar {
  display: none;
}

.v3-filter-by-date-bottomsheet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0px 24px;
}

.v3-filter-by-date-bottomsheet-header-text {
  font-size: 18px;
  color: var(--neutral-1);
  font-weight: 600;
}

.v3-filter-by-date-bottomsheet-date-types {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
}

.v3-filter-by-date-bottomsheet-cycles {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 0px 14px 0px;
  width: 100%;
  justify-content: center;
}

.v3-filter-by-date-bottomsheet-duration {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 0px 14px 0px;
  width: 100%;
  justify-content: center;
}

.v3-filter-by-date-bottomsheet-cycles-text {
  font-size: 16px;
  font-weight: 500;
  color: var(--tertiary-neutral-color-2);
}

.v3-filter-by-date-bottomsheet-duration-text {
  font-size: 16px;
  font-weight: 500;
  color: var(--tertiary-neutral-color-2);
}

.v3-custom-range-from-label {
  display: flex;
  align-items: center;
  /* gap: 8px; */
  width: 50px;
}

.v3-custom-range-container {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-top: 8px;
  padding: 10px 28px 10px 28px;
}

.v3-custom-range-date-text {
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
}

[data-rsbs-backdrop] {
  background-color: #000;
  opacity: 1;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.v3_mpin_slide_up {
  animation: slideUp 0.4s ease-out;
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    /* opacity: 0; */
  }
}

.v3_mpin_slide_right {
  animation: slideOutLeft 0.4s linear forwards;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.v3_mpin_slide_left {
  animation: slideInFromRight 0.4s linear forwards;
  height: calc(100vh - 48px);
}
.v3_otp_slide_left {
  animation: slideInFromRight 0.4s linear forwards;
}

.v3-mpin-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cube-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cube-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40%;
}
.cube-loader-text {
  margin-top: -40px;
}
.cube-loader-text-1 {
  color: var(--tertiary-neutral-color-1);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  letter-spacing: 0.32px;
}

.cube-loader-text-2 {
  color: var(--primary-base-color-1);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.v3-home-overlay {
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0.05%,
    rgba(0, 0, 0, 0.88) 87.65%
  );
}
.v3-swipe-load {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  width: 193px;
  height: 131px;
  transform: translate(-50%, -50%);
}
.v3-overlay-btn-container {
  position: absolute;
  left: 50%;
  transform: translate(-45%);
  bottom: 70px;
  width: 100%;
}
.v3-overlay-btn-ok {
  width: 91.5%;
  padding: 10px 18px;
  text-align: center;
  border-radius: 12px;
  background: none;
  border: 1px solid var(--System-Palette-leading-white, #fff);
  color: var(--System-Palette-leading-white, #fff);
}

.v3-clipboard-toast-content {
  display: grid;
  grid-template-columns: 1fr 10fr;
}
.v3-clipboard-toast-icon {
  margin-top: 0px;
  margin-bottom: -3px;
}

.hf-toast-warning {
  background-color: var(--tertiary-caution-color-1, #ffcd00) !important;
  width: 90%;
  margin: auto;
  border-radius: 12px !important;
  bottom: 12px;
}
.hf-toast-warning-body {
  color: var(--tertiary-neutral-color-1) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-top: -2px;
  font-family: var(--Font-Family, Mulish);
  margin-bottom: -6px !important;
}

.v3-modelNudges-overlay {
  left: 0;
  right: 0;
  height: 100dvh;
  position: absolute;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.88);
}

.v3-modelNudges-container {
  background: white;
  opacity: 1;
  height: 65%;
  width: 90%;
  margin: auto;
  margin-top: 30%;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.v3-modelNudges-banner {
  height: 45%;
  width: 100%;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  position: relative;
}

.v3-modelNudges-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.v3-modelNudges-content {
  display: flex;
  padding: 24px 26px 32px 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
}

.v3-modelNudges-headline {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-family: var(--Font-Family, Mulish);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  width: 100%;
  box-sizing: border-box;
}

.v3-modelNudges-content-text {
  color: var(--tertiary-neutral-color-2, #a8a8a8);
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.v3-modelNudges-btn {
  display: flex;
  justify-content: center;
  padding: 16px 0;
}
.v3-modelNudges-close-btn {
  position: absolute;
  z-index: 1;
  right: 35px;
  margin-top: 10px;
}
.v3-modelNudges-slides-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;
  box-sizing: border-box;
  margin-top: 30px;
}
.v3-modelNudges-slide-count {
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  padding: 9px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.v3-modelNudges {
  animation: slideUp 0.5s ease-out;
  height: 100%;
}
.v3-modelNudges-left-slider.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.v3-bottomDrawer-nudges-container {
  display: flex;
  padding: 24px 26px 42px 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
}
.v3-bottomDrawer-nudges-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 9;
}
.v3-bottomDrawer-nudges-img {
  display: flex;
  width: 84px;
  height: 84px;
  /* padding: 21px; */
  justify-content: center;
  align-items: center;
  gap: 21px;
  flex-shrink: 0;
  /* background: var(--primary-color-4); */
  border-radius: 20px;
}
.v3-bottomDrawer-nudges-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.v3-bottomDrawer-nudges-title {
  color: var(--tertiary-neutral-color-1);
  font-family: Mulish;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.28px;
  width: 278px;
}
.v3-bottomDrawer-nudges-description {
  color: var(--tertiary-neutral-color-1);
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 324px;
  opacity: 0.5;
}
.v3-bannerNudges-swiper {
  padding: 28px 20px;
  width: 100%;
  background: var(--caution-feedback-3, #fdfaf0);
}
.v3-bannerNudges-container {
  display: flex;
  width: auto;
  padding: 28px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.v3-bannerNudges-inner-container {
  display: flex;
  width: 100%;
  height: 112px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.14);
}
.v3-bannerNudges-content {
  display: flex;
  width: 50%;
  padding: 0px 0px 2px 18px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 12px;
}
.v3-bannerNudges-title {
  color: var(--neutral-1, #0e0e0e);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.14px;
}
.v3-bannerNudges-description {
  color: var(--primary-base-color-2, #697a92);
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.v3-bannerNudges-img {
  width: 45%;
  flex-shrink: 0;
}
.v3-bannerNudges-2 {
  height: auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 90%;
  padding: 18px 0px;
  align-items: center;
  gap: 8px;
  margin: auto;
}
.v3-bannerNudges-btn2 {
  margin: auto;
}
.bannerNudges-title-2 {
  width: 100%;
}
.bannerNudges-description-2 {
  width: 100%;
}
.bannerNudges-content-2 {
  width: 100%;
}
.v3-bannerNudges-3 {
  padding-right: 18px;
}
.v3-all-nudges-head {
  display: grid;
  grid-template-columns: 1fr 12fr;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  background: var(--primary-base-5, #f0f2f4);
}
.v3-all-nudges-head-text {
  color: var(--neutral-1, #0e0e0e);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
}
.v3-all-nudges-card {
  display: flex;
  padding: 14px 29px 10px 29px;
  align-items: flex-start;
  gap: 10px;
  border-top: 1px solid var(--neutral-3, #f1f1f1);
  background: var(--leading-white, #fff);
}
.v3-saved-cards-empty-img-container {
  display: flex;
  width: 343px;
  padding: 80px 0px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  margin: auto;
}
.v3-saved-cards-empty-text {
  color: var(--primary-base-1, var(--Colors-PRIMARY-BASE-1, #0d2950));
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  letter-spacing: 0.32px;
  width: 251px;
  margin-top: -90px;
}
.v3-saved-cards-empty-line-container {
  display: flex;
  width: 85%;
  padding: 8px 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: var(--primary-color-5, #f1f6fc);
  margin: auto;
}
.v3-saved-cards-empty-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--primary-color-1, var(--primary-color-1, #1965cf));
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.v3-saved-cards-empty-btn {
  display: flex;
  justify-content: center;
}
.v3-manage-cards-show-all {
  color: var(--secondary-1, var(--Colors-SECONDARY-1, #ed5b27));
  font-size: 12px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

/* ////////////////web view tranasction section /////////////////////////////////////// */
.v3-recent-transaction-table-container {
  border-radius: 21px;
  box-shadow: 0px 10px 31px -2px #00000017;
  background-color: var(--leading-white);
  padding: 20px;
  margin-bottom: 20px;
}
.v3-recent-transaction-header-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.v3-recent-transaction-header-text {
  font-weight: 800;
  font-size: 1.169rem;
  color: var(--primary-base-color-1);
  font-family: var(--Font-Family, Mulish);
  padding-top: 6px;
  padding-left: 12px;
}
.v3-recent-transaction-header-viewall-container {
  display: flex;
  gap: 2.31px;
  align-items: center;
  padding-top: 9px;
  padding-right: 9px;
  cursor: pointer;
}
.v3-recent-transaction-viewall-text {
  font-size: 0.822rem;
  font-weight: 700;
  color: var(--primary-color-1);
  font-family: var(--Font-Family, Mulish);
}
.v3-recent-transaction-table-body {
  width: 100%;
}
.v3-recent-transaction-table-section {
  border-radius: 12px;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.v3-recent-transaction-table-section {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.v3-recent-transaction-table-section::-webkit-scrollbar {
  display: none;
}
.v3-web-transaction-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.v3-recent-transaction-table-header th {
  padding: 12px 16px;
  background-color: var(--primary-base-color-5);
  color: #b5b7c0;
  font-weight: 600;
  text-align: left;
}
.v3-recent-transaction-table-header th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.v3-recent-transaction-table-header th:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.v3-recent-transaction-table-header-text {
  font-weight: 500;
  font-size: 0.75rem;
  background-color: var(--primary-base-color-5);
}

.v3-recent-transaction-table-body {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  max-height: 80vh;
}

.v3-recent-transaction-table-body td {
  padding: 12px 16px;
  /* background-color: #ffffff; */
  text-align: left;
  border: none;
}

.v3-recent-transaction-table-body tr {
  /* background-color: #ffffff; */
  border-radius: 8px;
}

.v3-recent-transaction-table-body tr:not(:last-child) {
  margin-bottom: 8px;
}

.v3-recent-transaction-table-body tr td:first-child {
  display: flex;
  align-items: center;
}

.v3-recent-transaction-table-body img {
  margin-right: 10px;
  border-radius: 50%;
}
.v3-account-summary-highlight {
  border-radius: 0px 0px 18px 18px;
  background: var(--primary-color-2, #70a0e1);
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  display: flex;
  height: 61px;
  padding: 36px 16px 12px 16px;
  justify-content: center;
  align-items: center;
  gap: -6px;
  align-self: stretch;
  width: 90%;
  margin: auto;
  margin-top: -30px;
  color: var(--leading-white, var(--System-Palette-leading-white, #fff));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.v3-recent-transaction-table-body td:nth-child(5) {
}

.v3-recent-transaction-table-body .status-due {
  background-color: #ffcc00;
}

.v3-recent-transaction-table-body .status-repaid {
  background-color: var(--tertiary-positive-color-2);
}

.v3-account-summary-highlight-fully-paid {
  display: flex;
  height: 61px;
  padding: 36px 16px 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0px 0px 18px 18px;
  background: var(--primary-color-4, #d6e3f6);
  color: var(--primary-color-1, var(--primary-color-1, #1965cf));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.92;
}
.v3-account-summary-highlight-days-fully-paid-days {
  color: var(--primary-color-1, var(--primary-color-1, #1965cf));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
  gap: 4px;
  margin-bottom: 28px;
}

.v3-recent-transaction-table-body .status-repayment {
  color: var(--tertiary-positive-color-1);
}
.v3-recent-transactions-transaction-due-status {
  text-align: center;
  padding: 2px 13px 2px 13px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 0.725rem;
  background-color: #ffcc00;
  width: fit-content;
}
.v3-recent-transactions-transaction-repaid-status {
  text-align: center;
  padding: 2px 13px 2px 13px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 0.725rem;
  background-color: var(--tertiary-positive-color-2);
  width: fit-content;
}
.v3-recent-transactions-transaction-delivered-status {
  text-align: center;
  padding: 2px 13px 2px 13px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 0.725rem;
  background-color: var(--primary-color-2);
  width: fit-content;
}
.v3-recent-transactions-transaction-repayment-status {
  text-align: center;
  padding: 2px 13px 2px 13px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 0.725rem;
  background-color: var(--tertiary-positive-color-2);
  width: fit-content;
}
.v3-recent-transactions-transaction-overdue-status {
  text-align: center;
  padding: 2px 13px 2px 13px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 0.725rem;
  background-color: #fef2f2;
  color: #e72b2b;
  width: fit-content;
}
.v3-recent-transactions-transaction-type {
  color: var(--neutral-1);
  font-weight: 600;
  font-size: 0.875rem;
}
.v3-recent-transactions-transaction-order-id,
.v3-recent-transactions-transaction-date {
  font-weight: 400;
  color: var(--System-Palette-neutral-1);
  font-size: 0.75rem;
}
.v3-recent-transactions-transaction-amount {
  font-weight: 600;
  font-size: 0.875rem;
}
.v3-recent-transactions-transaction-logo-text {
  cursor: pointer;
}

/* //////////////////////////////////// web all transactions styles ///////////////////////////////////////////// */
.v3-web-all-transactions-section {
  display: flex;
  justify-content: center;
  background-color: var(--primary-base-color-5);
  min-height: 100vh;
  height: fit-content;
}
.v3-web-all-transactions-transaction-table-container {
  width: 70%;
}
.v3-web-all-transactions-container {
  display: flex;
  gap: 2%;
  margin-top: 20px;
  width: 100% !important;
  max-width: 80vw;
  justify-content: center;
}
.v3-web-all-transactions-filter-container {
  background-color: var(--leading-white);
  border-radius: 21px;
  width: 20%;
  height: fit-content;
  box-shadow: 0px 10px 31px -2px #00000017;
}
.v3-web-all-transactions-filter-parent-container,
.v3-web-all-transactions-checkbox-container {
  padding: 20px;
  position: relative;
}
.v3-web-all-transactions-filter-parent-container {
  margin-bottom: 14px;
}
.v3-web-all-transactions-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v3-web-all-transactions-filter-header-text {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--neutral-1);
}
.v3-web-all-transactions-dropdown-container {
  position: absolute;
  background-color: var(--leading-white);
  padding: 8px 20px 8px 20px;
  z-index: 999;
  width: 94%;
  border-radius: 12px;
  box-shadow: 0px 4px 24px 0px #00000024;
  top: 89%;
}
.v3-web-all-transactions-dropdown-filter {
  padding: 10px 0px 10px 0px;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--primary-base-color-1);
  cursor: pointer;
}
.v3-web-all-transactions-date-range-container {
  padding-top: 20px;
}
.v3-web-all-transactions-date-range-header {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--tertiary-neutral-color-2);
}
.v3-web-all-transactions-date-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 0px;
  border-bottom: 0.5px solid var(--color-static-UI-Separator, #dedede);
}
.v3-web-all-transactions-date-selector-month-name {
  font-weight: 600;
  font-size: 0.75rem;
}
.v3-web-all-transactions-checkbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v3-web-all-transactions-checkbox-header-text {
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--neutral-1);
}
.v3-web-all-transactions-transacitontype-list-container {
  padding-top: 14px;
}
.v3-web-all-transactions-transactiontype-list {
  display: flex;
  gap: 10px;
  align-items: center;
}
.v3-web-all-transactions-transactiontype-label {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--neutral-1);
}
.v3-recent-transaciton-all-transaction-header {
  font-size: 0.875rem;
  color: var(--neutral-1);
}
.v3-recent-transaction-total-count {
  font-weight: 500;
}
.v3-web-zero-transaction-container {
  display: flex;
  padding: 2.21px 17.72px 2.21px 0px;
  border-radius: 12px;
  background-color: var(--primary-base-color-4);
  border: 1px solid var(--primary-base-color-4);
  align-items: center;
  gap: 25px;
}
.v3-web-zero-transaction-description-header {
  font-weight: 600;
  color: var(--primary-base-1);
  font-size: 1.75rem;
  width: 60%;
}
.v3-web-zero-transaction-description-date-range {
  font-weight: 400;
  font-size: 1.375rem;
  color: var(--primary-base-color-2);
}
.v3-web-zero-transaction-description-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.v3-web-zero-transaction-date-range {
  font-weight: 600;
}
/* /////////////////////////////////////////////////  transaction pay now  ////////////////////////////////////////////////////////// */
.v3-web-transaction-sidebar-container {
  width: 28%;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 50px;
  height: 100%;
  border-left: 1px solid #ccc;
  font-family: Arial, sans-serif;
  z-index: 3;
}

.v3-web-transaction-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.v3-web-transaction-sidebar-header h2 {
  font-size: 1.125rem;
  font-weight: bold;
  margin: 0;
}

.v3-web-transaction-close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #999;
}

.v3-web-transaction-close-btn:hover {
  color: #333;
}

.v3-web-transaction-credit-info {
  margin-bottom: 32px;
}

.v3-web-transaction-available-credit-label {
  color: var(--neutral-1);
  font-size: 0.75rem;
  font-weight: 600;
}

.v3-web-transaction-available-credit-amount {
  font-size: 0.875rem;
  /* margin-top: 5px; */
  color: var(--tertiary-neutral-color-1);
}

.v3-web-transaction-highlighted-amount {
  color: var(--primary-color-1);
  font-weight: bold;
  font-size: 1.25rem;
}

.v3-web-transaction-progress-bar {
  height: 6px;
  background-color: var(--tertiary-neutral-color-3);
  border-radius: 3px;
  margin-top: 10px;
  position: relative;
}

.v3-web-transaction-filled-bar {
  width: 70%;
  height: 100%;
  background-color: var(--primary-color-1);
  border-radius: 3px;
}

.v3-web-transaction-outstanding-info {
  background-color: #f7f7f7;
  padding: 12px 16px;
  border-radius: 18px;
  margin-bottom: 20px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v3-web-transaction-outstanding-info p {
  margin: 0;
}

.v3-web-transaction-outstanding-info-text {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--neutral-1);
  margin: 0;
}

.v3-web-transaction-outstanding-amount {
  color: red;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.v3-web-transaction-pay-now-btn {
  background-color: var(--secondary-color-1);
  color: var(--neutral-1);
  border: none;
  padding: 10px 12px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.v3-web-transaction-pay-now-btn:hover {
  background-color: var(--secondary-color-1);
}

/* /////////////////////////////////////////  transaction details sidebar  ////////////////////////////////////////////////////// */
.v3-web-transaction-sidebar-container {
  width: 28%;
  background-color: var(--leading-white);
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 50px;
  height: 100%;
}

.v3-web-transaction-sidebar-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
}
.v3-web-transaction-sidebar-header-text {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--neutral-1);
}

.v3-web-transaction-close-btn {
  cursor: pointer;
}

.v3-web-amount-arrow {
  color: #ff5e57;
  font-size: 1.2rem;
}

.v3-web-transaction-details {
  margin-bottom: 16px;
}

.v3-web-transaction-detail {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}

.v3-web-detail-value {
  font-size: 0.875rem;
  color: var(--tertiary-neutral-color-2);
  font-weight: 600;
}

.v3-web-detail-label {
  font-weight: 600;
  color: var(--neutral-1);
  font-size: 0.875rem;
}

.v3-web-detail-value-due {
  font-size: 0.875rem;
  color: var(--tertiary-caution-color-1);
  font-weight: 600;
}

.v3-web-detail-value-repaid {
  font-size: 0.875rem;
  color: var(--tertiary-positive-color-1);
  font-weight: 600;
}

.v3-web-transaction-note {
  background-color: var(--tertiary-positive-color-3);
  padding: 6px 10px 6px 10px;
  border-radius: 4px;
  font-size: 0.75rem;
  color: var(--tertiary-positive-color-1);
  text-align: center;
}

.v3-web-transaction-sidebar-header-txn-details {
  display: flex;
  gap: 18px;
  align-items: center;
}

.v3-web-transaction-sidebar-header-txn-type-text {
  font-size: 0.875rem;
  color: var(--primary-base-color-1);
  font-weight: 400;
}

.v3-web-transaction-sidebar-header-txn-amount {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--primary-base-color-1);
  display: flex;
  gap: 8px;
}

.v3-web-all-transactions-custom-range-from-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.v3-web-all-transactions-range-date-text {
  color: var(--tertiary-neutral-color-2);
  font-size: 0.75rem;
}

/* ////////////////////////////////// FKW program details //////////////////////////////////////// */
.v3-web-about-program-container {
  height: 100vh;
  display: flex;
  justify-content: center;
}
.v3-web-about-program-card {
  border-radius: 21px;
  padding: 31px;
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  background-color: var(--leading-white);
  max-height: fit-content;
  min-width: 74vw;
  max-width: 74vw;
  margin-top: 24px;
}
.v3-web-about-program-card-top-text-point {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--neutral-1);
}
.v3-web-about-program-card-bottom-alert-container {
  display: flex;
  align-items: center;
  background-color: var(--tertiary-negative-color-3);
  border-radius: 21px;
  margin-top: 35px;
}
.v3-web-about-program-card-bottom-alert-text-header {
  font-size: 1.125rem;
  font-weight: 500;
  padding-left: 13px;
}
.v3-web-about-program-card-bottom-alert-text-point-list {
  color: var(--tertiary-negative-color-1);
}
.v3-web-about-program-card-bottom-alert-text-point {
  font-size: 0.875rem;
  font-weight: 400;
}
/* block card styles  */
.v3-blocked-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 93vh;
}

.v3-blocked-screen-body-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.v3-blocked-screen-card-image {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.v3-blocked-screen-body-header {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-base-color-1);
  padding-top: 18px;
  padding-bottom: 18px;
}

.v3-blocked-screen-body-description-text,
.v3-blocked-screen-body-cta-text,
.v3-blocked-screen-body-phone,
.v3-blocked-screen-body-email-header,
.v3-blocked-screen-body-email-text {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: var(--neutral-1);
  font-weight: 500;
}

.v3-blocked-screen-body-description-text {
  padding-bottom: 18px;
}

.v3-blocked-screen-body-phone,
.v3-blocked-screen-body-email-text {
  font-weight: 700;
}

.v3-blocked-screen-body-email-header {
  padding-top: 18px;
}

.v3-blocked-screen-cta-container {
  display: flex;
  justify-content: center;
  padding: 16px;
  box-shadow: 0px 4px 24px 0px #00000024;
  margin-top: 16px;
}

.v3-settings-home-button-item-cta-container {
  display: flex;
  align-items: center;
  /* gap: 4px;/ */
}
.v3-account-summary-highlight {
  border-radius: 0px 0px 18px 18px;
  background: var(--primary-color-2, #70a0e1);
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  display: flex;
  height: 61px;
  padding: 36px 16px 12px 16px;
  justify-content: center;
  align-items: center;
  gap: -6px;
  align-self: stretch;
  width: 90%;
  margin: auto;
  margin-top: -30px;
  color: var(--leading-white, var(--System-Palette-leading-white, #fff));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.v3-account-summary-highlight-days {
  color: var(--leading-white, var(--System-Palette-leading-white, #fff));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.92;
}
.v3-account-summary-highlight-fully-paid {
  display: flex;
  height: 61px;
  padding: 36px 16px 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0px 0px 18px 18px;
  background: var(--primary-color-4, #d6e3f6);
  color: var(--primary-color-1, var(--primary-color-1, #1965cf));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.92;
}
.v3-account-summary-highlight-days-fully-paid-days {
  color: var(--primary-color-1, var(--primary-color-1, #1965cf));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
  gap: 4px;
  margin-bottom: 28px;
}
.v3-saved-cards-empty-container {
  height: calc(100vh - 200px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.tokenisation-saved-card-cta-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.v3-MerchantBottomSheet-header-img {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}
.v3-MerchantBottomSheet-header-img img {
  border-radius: 8px;
}
.v3-MerchantBottomSheet-header-icon {
  width: 48px;
  height: 48px;
}
.v3-MerchantBottomSheet-header-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.v3-MerchantBottomSheet-header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}
.v3-MerchantBottomSheet-header-text1 {
  width: 211px;
  color: var(--tertiary-neutral-color-2, #a8a8a8);
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.v3-MerchantBottomSheet-header-text2 {
  width: 211px;
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-family: var(--Font-Family, Mulish);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 36.4px */
}
.v3-MerchantBottomSheet-header {
  display: flex;
  justify-content: space-between;
}
.v3-MerchantBottomSheet-body {
  display: flex;
  width: 323px;
  padding: 16px 18px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: 12px;
  background: var(--primary-color-5, #f1f6fc);
  margin: auto;
}
.v3-MerchantBottomSheet-body-line-container {
  display: flex;
  padding: 2px 14px 2px 0px;
  align-items: center;
  gap: 6px;
}
.v3-MerchantBottomSheet-body-line-icon {
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.v3-MerchantBottomSheet-body-line {
  color: var(--primary-base-1, var(--Colors-PRIMARY-BASE-1, #0d2950));
  opacity: 0.92;
}
.v3-activity-section-summary-credit-balance-section {
  display: flex;
  justify-content: center;
  padding: 15px;
  margin: auto;
  color: var(--primary-base-2, var(--Colors-PRIMARY-BASE-2, #697a92));
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.v3-MerchantBottomSheet-body-line-email {
  color: var(--primary-base-1, var(--Colors-PRIMARY-BASE-1, #0d2950));
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.v3-MerchantBottomSheet-footer {
  display: flex;
  padding: 32px 24px 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-left: 0px;
}
.v3-MerchantBottomSheet-footer-section {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.v3-MerchantBottomSheet-footer-section-icon {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 662.38px;
  background: var(--System-Palette-neutral-3, #f1f1f1);
}
.v3-MerchantBottomSheet-footer-section-text-container {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
  display: flex;
  justify-content: space-between;
  width: 290px;
}

.v3-activity-section-summary-credit-balance-span {
  color: var(--primary-base-1, var(--Colors-PRIMARY-BASE-1, #0d2950));
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
  margin-left: 5px;
  margin-top: -1px;
}
.v3-MerchantBottomSheet-footer-section-text2 {
  color: var(
    --negative-feedback-1,
    var(--System-Palette-negative-feedback-1, #e72b2b)
  );
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}
.v3-MerchantBottomSheet-footer-section-text {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}
.v3-MerchantBottomSheet-footer-btn {
  margin: auto;
  width: 100%;
}
.v3-MerchantBottomSheet-heading {
  margin-left: 5px;
  margin-top: -1px;
}
.v3-activity-section-summary-credit-arrow-span {
  margin-left: 5px;
}
.v3-summary-bottomSheet-title {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-family: var(--Font-Family, Mulish);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  width: 214px;
}
.v3-MerchantBottomSheet-body2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  padding: 12px 24px 0px 24px;
  padding-left: 0px;
}
.v3-MerchantBottomSheet-body2-icon {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  flex-direction: column;
}
.v3-MerchantBottomSheet-body2-text {
  color: var(--primary-base-2, var(--Colors-PRIMARY-BASE-2, #697a92));
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  align-self: stretch;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 235px;
}
.v3-summary-bottomSheet-subtitle {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  align-self: stretch;
}
.v3-summary-bottomSheet-value {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
  opacity: 0.6;
  padding-top: 5px;
}
.v3-summary-bottomSheet-section-value-sub {
  color: var(--primary-color-1, var(--primary-color-1, #1965cf));
  font-family: var(--Font-Family, Mulish);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 111%;
  letter-spacing: 0.2px;
}
.v3-summary-bottomSheet-statusBar {
  width: 100%;
  height: 8px;
  flex-shrink: 0;
  border-radius: 25px;
  background: var(--System-Palette-neutral-3, #f1f1f1);
  position: relative;
}

.v3-summary-bottomSheet-statusBar-filled {
  height: 100%;
  border-radius: 25px;
  background: var(--primary-color-1, #1965cf);
  position: absolute;
  top: 0;
  left: 0;
}
.v3-summary-bottomSheet-stroke {
  margin: auto;
  padding: 16px;
}
.v3-summary-bottomSheet-total-outStanding-transactions {
  border-radius: 18px;
  background: var(--Colors-PRIMARY-BASE-5, #f0f2f4);
  display: flex;
  width: 335px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  margin: auto;
  width: 100%;
}
.v3-summary-bottomSheet-total-outStanding-value {
  color: var(
    --negative-feedback-1,
    var(--System-Palette-negative-feedback-1, #e72b2b)
  );
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.2px;
}
.v3-summary-bottomSheet-total-outStanding-container {
  display: flex;
  padding: 0px 26px 0px 18px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.v3-summary-bottomSheet-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.v3-summary-bottomSheet-latest-bill {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: var(--System-Palette-leading-white, #fff);
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  width: 90%;
  margin: auto;
  margin-top: 10px;
}
.v3-summary-bottomSheet-unbilled {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: var(--System-Palette-leading-white, #fff);
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  width: 90%;
  margin: auto;
  margin-top: 5px;
}
.v3-summary-bottomSheet-latest-bill-container {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  gap: 10px;
}
.v3-summary-bottomSheet-latest-bill-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.v3-summary-bottomSheet-latest-bill-text-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.v3-summary-bottomSheet-latest-bill-text-sub {
  display: flex;
  align-items: center;
  gap: 2px;
}
.v3-summary-bottomSheet-latest-bill-partially-paid {
  color: var(
    --positive-feedback-1,
    var(--System-Palette-positive-feedback-1, #23951c)
  );
  text-align: right;
  font-family: var(--Font-Family, Mulish);
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.v3-summary-bottomSheet-latest-bill-partially-paid-value {
  color: var(--tertiary-neutral-color-2, #a8a8a8);
  text-align: right;
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}
.v3-summary-bottomSheet-latest-bill-text-title {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}
.v3-summary-bottomSheet-latest-bill-text-statement {
  color: var(--primary-base-2, var(--Colors-PRIMARY-BASE-2, #697a92));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}
.v3-summary-bottomSheet-latest-bill-value {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  text-align: right;
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}
.v3-summary-bottomSheet-latest-bill-arrow {
  display: flex;
  margin-top: -1px;
}

/* /////////////////////////////////////////////////////HOME web ////////////////////////////////////////////////// */
.v3-webview-home-container {
  height: fit-content;
  background-color: var(--primary-base-color-5);
  display: flex;
  justify-content: center;
  padding-top: 20px;
  overflow-x: hidden;
  gap: 2%;
  width: 75%;
  max-width: 80vw;
  /* align-items: center; */
}

.v3-webview-home-left-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
}

.v3-webview-home-rigt-section {
  width: 70%;
}
/* //////////////////////////////fkw transaction////////////////////////////////////// */
.v3-fkw-transaction-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v3-fkw-transaction-left-conainer {
  display: flex;
  gap: 12px;
  align-items: center;
}
.v3-fkw-transaction-left-header {
  display: flex;
  gap: 2px;
  align-items: center;
}
.v3-fkw-transaction-left-header-type {
  font-weight: 600;
  font-size: 0.75rem;
}
.v3-fkw-transaction-left-header-date {
  font-weight: 600;
  font-size: 0.75rem;
}
.v3-fkw-transaction-left-description {
  font-weight: 600;
  font-size: 0.625rem;
  color: var(--tertiary-neutral-color-2);
}
.v3-fkw-transaction-right-amount {
  font-weight: 600;
  font-size: 0.875rem;
}
.v3-fkw-transaction-right-status {
  display: flex;
  justify-content: right;
}
.v3-fkw-transaction-right-status-container {
  padding: 2px 8px 2px 8px;
  border-radius: 50px;
  background-color: var(--secondary-color-1);
  font-size: 0.625rem;
  font-weight: 600;
}

/* ///////////////////////////////////////Date picker styles///////////////////////////////////////// */
.custom-input {
  background-color: #f8f9fa; /* Light gray background */
  border: 1px solid #ced4da; /* Gray border */
  color: #495057; /* Dark text color */
  font-size: 0.875rem; /* Small font size */
  border-radius: 0.375rem; /* Rounded corners */
  width: 100%; /* Full width */
  padding: 0.35rem 0.35rem;
  transition:
    border-color 0.2s,
    box-shadow 0.2s; /* Smooth transition */
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  border-bottom: 0.5px solid var(--color-static-UI-Separator, #dedede);
}

.custom-input:focus {
  border-color: #007bff; /* Blue border on focus */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Slight shadow */
  outline: none; /* Remove default outline */
}

.custom-input:disabled {
  background-color: #e9ecef; /* Disabled background color */
  cursor: not-allowed; /* Not allowed cursor */
}

.custom-input::placeholder {
  color: #6c757d; /* Placeholder text color */
}

.custom-input.dark-mode {
  background-color: #343a40; /* Dark mode background */
  border-color: #495057; /* Dark mode border */
  color: #f8f9fa; /* Dark mode text color */
}

.custom-input.dark-mode:focus {
  border-color: #80bdff; /* Light blue border on focus in dark mode */
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.25); /* Shadow in dark mode */
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
}

.v3-web-all-transactions-custom-date-select-container {
  margin-top: 15px;
}

.v3-web-custom-range-error-container {
  margin-top: 9px;
}
.v3-activity-section-minimum-amount-due {
  color: var(--primary-base-2, var(--Colors-PRIMARY-BASE-2, #697a92));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 5px;
}
.v3-home-logo-left {
  margin-right: 70%;
}
.v3-header-home-text-left {
  position: absolute;
  left: 12%;
}

/* ////////////////////////////////////////benifit-analytics-css//////////////////////////////////////////////////// */
.v3-spend-analytics-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v3-spend-analytics-chart-1-container {
  border-radius: 24px;
  box-shadow: 0px 10px 31px -2px #00000017;
  margin-top: 24px;
  width: 90%;
  padding-bottom: 24px;
  z-index: 2;
  background-color: var(--leading-white);
}
.v3-spend-analytics-chart-1-header {
  padding-top: 20px;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--primary-base-color-1);
}
.v3-spend-analytics-chart-1-filter-container {
  padding-top: 18px;
}
.v3-spend-analytics-chart-1-filter-container {
  display: flex;
  gap: 12px;
  justify-content: center;
}
.v3-spend-analytics-chart-1-overview-period {
  width: 34%;
}
.v3-spend-analytics-chart-1-overview-period-header,
.v3-spend-analytics-chart-1-category-trend-header {
  font-size: 0.563rem;
  color: var(--primary-base-color-3);
  font-weight: 400;
  padding-bottom: 4px;
}
.v3-spend-analytics-chart-1-overview-period-filter-tab,
.v3-spend-analytics-chart-1-category-trend-fitler-tab {
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--primary-base-5, #f0f2f4);
  padding: 6px 10px;
  justify-content: space-between;
  align-items: center;
}
.v3-spend-analytics-chart-1-overview-period-value,
.v3-spend-analytics-chart-1-category-trend-value {
  font-size: 0.625rem;
  color: var(--primary-base-color-1);
  font-weight: 600;
}
.v3-spend-analytics-chart-1-category-trend {
  width: 46%;
}
.v3-spend-analytics-chart-1-category-trend-filter-tab-left {
  display: flex;
  align-items: center;
  gap: 12px;
}
.v3-spend-analytics-chart-1-widget {
  padding-top: 28px;
}
.v3-spend-analytics-chart-1-widget-container {
  overflow-x: auto;
  width: 100%;
  max-width: 400px;
}
.v3-spend-analytics-chart-1-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.v3-spend-analytics-chart-1-info-text {
  font-size: 0.625rem;
  color: var(--primary-base-color-3);
  font-weight: 600;
}
.v3-spend-analytics-part-1-graph-bg {
  position: absolute;
  z-index: 1;
}
.v3-spend-analytics-bg-block {
  position: absolute;
  background-color: var(--primary-color-5, #f1f6fc);
  z-index: 1;
  height: 218px;
  width: 100%;
  top: 29%;
}
.v3-spend-analytics-category-split-container {
  padding-top: 68px;
  min-width: 90%;
  position: relative;
  z-index: 2;
}
.v3-spend-analytics-category-split-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}
.v3-spend-analytics-category-split-primary-header-text {
  font-size: 1rem;
  color: var(--primary-base-1);
  font-weight: 600;
}
.v3-spend-analytics-category-split-secondary-header-text {
  font-size: 0.563rem;
  color: var(--primary-base-color-2);
}
.v3-spend-analytics-category-split-filter-tab {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--tertiary-neutral-color-3);
  border-radius: 100px;
  padding: 6px 12px;
}
.v3-spend-analytics-category-split-filter-text {
  font-size: 0.75rem;
  color: var(--tertiary-neutral-color-1);
  padding-top: 1px;
  font-weight: 400;
}
.v3-spend-analytics-category-split-filter-selected-container {
  border: 1px solid var(--primary-base-5);
  border-radius: 18px;
  background-color: var(--primary-base-color-5);
  font-size: 0.563rem;
  padding: 8px 0px;
  display: flex;
  justify-content: center;
}
.v3-spend-analytics-category-split-chart-2-container {
  min-width: 90%;
  border-radius: 18px;
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  margin-top: 12px;
  position: relative;
  z-index: 2;
  background-color: var(--leading-white);
}
.v3-spend-analytics-category-split-chart-2-header {
  padding: 12px 18px;
  background-color: var(--primary-base-color-1);
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.v3-spend-analytics-category-split-chart-2-left-section,
.v3-spend-analytics-category-split-chart-2-right-section {
  display: flex;
  justify-content: center;
  gap: 4px;
}
.v3-spend-analytics-category-split-chart-2-categories-count,
.v3-spend-analytics-category-split-chart-2-categories-count-value,
.v3-spend-analytics-category-split-chart-2-total-spent,
.v3-spend-analytics-category-split-chart-2-total-spent-value {
  font-size: 0.625rem;
  color: var(--leading-white);
  font-weight: 400;
}
.v3-spend-analytics-category-split-chart-2-categories-count-value,
.v3-spend-analytics-category-split-chart-2-total-spent-value {
  font-weight: 500;
}
.v3-spend-analytics-category-split-chart-2-widget {
  margin-top: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}
.v3-spend-analytics-category-split-chart-2-category-container {
  display: flex;
  justify-content: space-between;
  min-width: 90%;
  align-items: center;
}
.v3-spend-analytics-category-split-chart-2-category-label-bar {
  display: flex;
  gap: 4px;
  width: 100%;
}
.v3-spend-analytics-category-split-chart-2-category-label {
  display: flex;
  justify-content: space-between;
  width: 43%;
  min-width: 43%;
  align-items: center;
  padding: 4px 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: 0.15s ease-in;
}
.v3-spend-analytics-category-split-chart-2-category-bar {
  width: 40%;
  background-color: var(--primary-color-3);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: 0.15s ease-in;
}
.v3-spend-analytics-category-split-chart-2-category-label-text {
  font-size: 0.75rem;
  color: var(--primary-base-color-1);
  font-weight: 600;
}
.v3-spend-analytics-category-split-chart-2-bar-value {
  font-size: 0.625rem;
  color: var(--primary-base-color-1);
  font-weight: 600;
  position: absolute;
  right: 5%;
}
.v3-spend-analytics-category-split-chart-2-widget-info-text {
  margin-top: 36px;
  text-align: center;
  font-size: 0.625rem;
  color: var(--primary-base-color-3);
  font-weight: 600;
  padding-bottom: 18px;
}
.v3-spend-analytics-category-split-chart-2-widget-carousel-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  padding-bottom: 36px;
  background-color: var(--leading-white);
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide {
  border-radius: 18px;
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  width: 80%;
  background-color: var(--primary-color-1);
  transition: 0.1s ease-in;
  position: relative;
  min-height: 169px;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part {
  /* padding: 18px; */
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-color-1);
  background: linear-gradient(
    118deg,
    rgba(0, 0, 0, 0) 3.38%,
    rgba(0, 0, 0, 0.3) 95.41%
  );
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category {
  display: flex;
  gap: 4px;
  align-items: center;
  padding-bottom: 4px;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-count {
  font-size: 0.563rem;
  color: var(--primary-color-3);
  font-weight: 600;
  padding-bottom: 2px;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-count-nr {
  color: var(--primary-color-5);
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-name {
  color: var(--leading-white);
  font-size: 0.875rem;
  font-weight: 600;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-value {
  background-color: var(--leading-white);
  padding: 1px 4px;
  border-radius: 4px;
  color: var(--primary-base-color-1);
  font-size: 0.563rem;
  font-weight: 600;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-txn-redirection {
  color: var(--primary-color-3);
  font-size: 0.625rem;
  font-weight: 600;
  padding-bottom: 16px;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants-count,
.v3-spend-analytics-category-insight-merchants-count {
  font-size: 0.563rem;
  color: var(--leading-white);
  font-weight: 600;
}
.v3-spend-analytics-category-insight-transactions {
  min-width: 90%;
  padding: 8px 20px 20px 20px;
  background-color: var(--leading-white);
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants,
.v3-spend-analytics-category-insight-merchants {
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 30px;
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 3px 10px 3px 3px;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants-logos,
.v3-spend-analytics-category-insight-merchants-logos {
  display: flex;
  align-items: center;
  position: relative;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants-second-logo {
  position: absolute;
  left: 50%;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left {
  padding: 18px;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-right {
  display: flex;
  justify-content: center;
  /* padding-right: 1%; */
  overflow: hidden;
  position: absolute;
  width: 50%;
  right: 0;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-right
  > img {
  transform: rotate(6.82deg);
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part {
  background-color: var(--leading-white);
  padding: 0px 12px 0px 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  min-height: 34px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part-reward-text {
  font-size: 0.563rem;
  font-weight: 600;
  color: var(--neutral-1);
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part-reward-count {
  display: flex;
  align-items: center;
  gap: 2px;
}
.v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part-reward-count-value {
  font-size: 0.625rem;
  font-weight: 600;
  color: var(--neutral-1);
}
.v3-spend-analytics-top-transactions-container {
  margin-top: 48px;
  min-width: 90%;
  padding: 28px 20px 30px 20px;
  background-color: var(--leading-white);
}
.v3-spend-analytics-top-transactions-header {
  margin-bottom: 24px;
  color: var(--primary-base-color-1);
  font-weight: 600;
  font-size: 1rem;
}
.v3-spend-analytics-category-bottomsheet-header {
  padding: 24px 26px 28px 28px;
}
.v3-spend-analytics-category-bottomsheet-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
}
.v3-spend-analytics-category-bottomsheet-primary-header {
  color: var(--tertiary-neutral-color-1);
  font-size: 1.125rem;
  font-weight: 600;
}
.v3-spend-analytics-category-bottomsheet-secondary-header {
  font-size: 0.625rem;
  color: var(--tertiary-neutral-color-1);
  font-weight: 400;
  opacity: 0.4;
  width: 80%;
}
.v3-spend-analytics-category-bottomsheet-body-container {
  padding: 0px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.v3-spend-analytics-category-bottomsheet-filter {
  padding: 12px 24px;
}
.v3-spend-analytics-category-bottomsheet-cta-container {
  padding: 16px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--System-Palette-neutral-3, #f1f1f1);
}
.v3-spend-analytics-category-bottomsheet-filter {
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v3-spend-analytics-category-insight-container {
  background-color: #175cbb;
}
.v3-spend-analytics-category-insight-header {
  padding: 28px 20px 42px 20px;
  background: linear-gradient(
    198deg,
    rgba(0, 0, 0, 0) 13.52%,
    rgba(0, 0, 0, 0.3) 89.03%
  );
}
.v3-spend-analytics-category-insight-header-text {
  display: flex;
  justify-content: center;
  color: var(--primary-color-3);
  font-weight: 500;
  font-size: 0.75rem;
  padding-bottom: 12px;
}
.v3-spend-analytics-category-insight-card {
  padding: 14.5px 16px 15px 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 18px;
  background-color: var(--leading-white);
  box-shadow: 0px 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.v3-spend-analytics-category-insight-category-name {
  font-size: 1.125rem;
  color: var(--neutral-1);
  font-weight: 500;
  padding-bottom: 12px;
}
.v3-spend-analytics-category-insight-merchants {
  background-color: var(--primary-color-4);
}
.v3-spend-analytics-category-insight-merchants-count {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--primary-color-1);
  opacity: 0.84;
}
.v3-spend-analytics-category-insight-right {
  max-width: 41%;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.v3-spend-analytics-category-insight-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.v3-spend-analytics-category-split-filter-tab-icon-and-text {
  display: flex;
  gap: 4px;
}
.v3-spend-analytics-category-split-filter-selected-date {
  font-size: 0.625rem;
  font-weight: 600;
  color: var(--neutral-1);
  padding-left: 2px;
}
.v3-spend-analytics-category-insight-reward-cashback-container {
  padding: 8px 0px;
  display: flex;
  justify-content: center;
  color: var(--primary-base-color-2);
  font-weight: 600;
  font-size: 0.625rem;
  background-color: var(--leading-white);
  border-radius: 18px;
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  align-items: center;
  gap: 2px;
}
.v3-spend-analytics-category-insight-card {
  margin-bottom: 12px;
}
.v3-spend-analytics-category-insight-reward-cashback-amount {
  color: var(--tertiary-neutral-color-1);
  font-size: 600;
}
.v3-spend-analytics-category-filter-seciton {
  padding: 18px 20px;
  display: flex;
  gap: 8px;
  background-color: var(--leading-white);
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  overflow-x: scroll;
}
.v3-spend-analytics-category-filter-seciton {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.v3-spend-analytics-category-filter-seciton::-webkit-scrollbar {
  display: none;
}
.v3-spend-analytics-category-filter,
.v3-spend-analytics-category-filter-active {
  padding: 6px 12px;
  border-radius: 100px;
  border: 1.6px solid var(--tertiary-neutral-color-3);
  display: flex;
  gap: 4px;
  color: var(--tertiary-neutral-color-1);
  font-size: 0.75rem;
  font-weight: 400;
  background-color: var(--leading-white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3-spend-analytics-category-filter-active {
  background-color: var(--secondary-color-4);
  border: 1.6px solid var(--secondary-color-2);
  font-weight: 600;
}
.v3-spend-analytics-category-insight-total-txn-container {
  display: flex;
  justify-content: space-between;
  padding: 4px 20px;
  align-items: center;
  min-height: 50px;
  background-color: var(--primary-color-5);
}
.v3-spend-analytics-category-insight-total-txn-count {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--primary-base-color-2);
}
.v3-spend-analytics-category-insight-total-txn-amount-text {
  font-size: 0.563rem;
  font-weight: 400;
  color: var(--primary-base-color-2);
}
.v3-spend-analytics-category-insight-total-txn-amount-value {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--primary-color-1);
}
.v3-spend-analytics-category-insight-bottomsheet-filter {
  display: flex;
  justify-content: space-between;
  padding: 8px 24px;
  align-items: center;
  border-radius: 8px;
}
.v3-spend-analytics-category-insight-left-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.v3-spend-analytics-category-insight-merchant-logo > img {
  border-radius: 100%;
}
.v3-spend-analytics-category-insight-merchant-name {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--tertiary-neutral-color-1);
}
.v3-spend-analytics-empty-container {
  display: flex;
  justify-content: center;
  min-height: 88vh;
}
.v3-spend-analytics-empty-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 44px;
  padding-top: 20%;
}
.v3-spend-analytics-empty-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.v3-spend-analytics-empty-body-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}
.v3-spend-analytics-empty-body-info-header {
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  color: var(--primary-base-color-1);
  max-width: 60%;
}
.v3-spend-analytics-empty-body-info-description {
  font-size: 0.625rem;
  font-weight: 500;
  color: var(--primary-base-color-3);
  opacity: 0.8;
  text-align: center;
  max-width: 60%;
}
.v3-spend-analytics-empty-header {
  font-size: 1.75rem;
  font-weight: 500;
  color: var(--primary-color-1);
}
.v3-spend-analytics-category-split-chart-2-empty-state {
  padding: 36px 0px;
  text-align: center;
  color: var(--primary-base-color-2);
  font-weight: 600;
}
.v3-spend-analytics-category-split-chart-2-single-category {
  border-radius: 18px;
  background-color: var(--primary-color-1);
  position: relative;
  z-index: 2;
}
.v3-spend-analytics-category-split-chart-2-single-category-header {
  background-color: var(--primary-base-color-1);
  display: flex;
  justify-content: center;
  gap: 4px;
  padding: 12px 14px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.v3-spend-analytics-category-split-chart-2-single-category-header-text {
  color: var(--leading-white);
  opacity: 0.72;
  font-size: 0.625rem;
}
.v3-spend-analytics-category-split-chart-2-single-category-header-value {
  font-weight: 600;
  color: var(--leading-white);
  font-size: 0.625rem;
}
.v3-spend-analytics-category-split-chart-2-single-category-body-merchant-header {
  font-size: 0.563rem;
  color: var(--primary-color-5);
}
.v3-spend-analytics-category-split-chart-2-single-category-body-left {
  padding: 18px;
  padding-left: 14px;
}
.v3-spend-analytics-category-split-chart-2-single-category-body-merchant-name {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--leading-white);
  padding-bottom: 6px;
}
.v3-spend-analytics-category-split-chart-2-single-category-body {
  display: flex;
  justify-content: space-between;
  align-items: end;
  background: linear-gradient(
    118deg,
    rgba(0, 0, 0, 0) 3.38%,
    rgba(0, 0, 0, 0.3) 95.41%
  );
}
.v3-spend-analytics-category-split-chart-2-single-category-body-right {
  padding-right: 10%;
}
.v3-spend-analytics-category-split-chart-2-single-category-footer {
  display: flex;
  gap: 4px;
  padding: 0px 12px 0px 14px;
  align-items: center;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  background-color: var(--leading-white);
  min-height: 36px;
}
.v3-spend-analytics-bg-section-1,
.v3-spend-analytics-bg-section-2 {
  position: absolute;
  min-height: 200px;
  background-color: var(--primary-color-5);
  z-index: -1;
  max-width: 450px;
}
.v3-spend-analytics-bg-section-1 {
  top: 380px;
}
.v3-spend-analytics-bg-section-2 {
  min-height: 160px;
  bottom: 367px;
}
.v3-spend-analytics-category-filter-text {
  font-size: 0.75rem;
}
.v3-spend-analytics-top-transaction-all-cta {
  padding-top: 26px;
  padding-bottom: 31px;
  text-align: center;
  font-size: 0.625rem;
  font-weight: 600;
  text-decoration: underline;
  color: var(--secondary-color-1);
}
.v3-tokenisation-hf-branding-container {
  position: fixed;
  bottom: 122px;
  width: 100%;
  max-width: 450px;
  z-index: 1;
}
.v3-tokenization-hf-branding {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0px;
  background-color: #fff;
  z-index: 1;
}

.v3-tokenization-hf-branding-text {
  margin-right: 10px;
}

.v3-tokenisation-empty-hf-branding-container {
  bottom: 140px;
}

.v3-color-button-redirection-icon {
  position: absolute;
  right: 18px;
  top: 20%;
}

.v3-web-header-container {
  padding-top: 19px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--primary-base-color-5);
}

.v3-web-header-container-wrapper {
  width: 73%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v3-web-header-left-section {
  display: flex;
  gap: 12px;
  align-items: center;
}

.v3-web-header-text,
.v3-web-header-right-section {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--primary-base-color-1);
}

.v3-webview-home-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary-base-color-5);
  min-height: fit-content;
  width: 100%;
}
.v3-web-view-activity-container {
  background: var(--primary-color-5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 28px 0;
}
.v3-web-redirection-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
  position: relative;
}
.v3-web-redirection-loader-animation-container {
  width: 274px;
  height: 274px;
}
.v3-web-redirection-loader-bottom-section {
  position: absolute;
  bottom: 20px;
}
.v3-web-redirection-loader-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}
.v3-web-redirection-loader-text-header {
  font-size: 1.563rem;
  font-weight: 700;
  color: var(--tertiary-neutral-color-1);
}
.v3-web-redirection-loader-text-description {
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--primary-base-1);
  opacity: 0.5;
}
.v3-web-redirection-loader-top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v3-web-redirection-loader-bottom-section {
  display: flex;
  align-items: center;
  gap: 11px;
}
.v3-cube-web-app-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.v3-web-exception-screen-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  position: relative;
}
.v3-web-exception-screen-top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;
}
.v3-web-exception-screen-text-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}
.v3-web-exception-screen-header-text {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--tertiary-neutral-color-1);
}
.v3-web-exception-screen-description-text {
  font-size: 1rem;
  color: var(--primary-base-1);
  opacity: 0.5;
  font-weight: 400;
  max-width: 75%;
  text-align: center;
}
.v3-web-exception-screen-cta-section {
  display: flex;
  align-items: center;
  gap: 14px;
  width: 40%;
  position: absolute;
  bottom: 95px;
}
.v3-web-exception-screen-primary-cta {
  padding: 10px 18px;
  border-radius: 12px;
  background-color: var(--secondary-color-1);
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  width: 50%;
  color: var(--tertiary-neutral-color-1);
  cursor: pointer;
}
.v3-web-exception-screen-secondary-cta {
  padding: 10px 18px;
  border-radius: 12px;
  border: 1px solid var(--tertiary-neutral-color-1);
  text-align: center;
  width: 50%;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--tertiary-neutral-color-1);
  cursor: pointer;
}
.v3-session-expired-web-container {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.v3-session-expired-web-cta-container {
  position: absolute;
  bottom: 95px;
  width: 20%;
  cursor: pointer;
}
.v3-session-expired-web-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.v3-session-expired-web-header-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.v3-session-expired-web-primary-header-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--neutral-1);
}
.v3-session-expired-web-secondary-header-text {
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary-base-1);
  opacity: 0.5;
}
.v3-session-expired-web-cta-text {
  padding: 10px 18px;
  border-radius: 12px;
  background-color: var(--secondary-color-1);
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--tertiary-neutral-color-1);
  text-align: center;
}
.v3-acs-transactionSection-authentication-header {
  display: flex;
  height: 48px;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #e6f4ff;
}
.v3-acs-transactionSection-authentication-header-text {
  color: #8f8f8f;
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
}
.v3-acs-transactionSection-authentication-header-span {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
}
.v3-acs-transactionSection-authentication-body {
  display: flex;
  padding: 28px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
}
.v3-acs-transactionSection-authentication-body-text {
  display: flex;
  padding: 0px var(--8, 8px);

  flex-direction: column;
  justify-content: center;
  gap: var(--8, 8px);

  align-self: stretch;
}
.v3-verify-otp-btn {
  width: 100%;
}
.v3-acs-transactionSection-authentication-body-title {
  color: var(--color-brand-Blue---500, #027cd5);
  font-family: var(--Font-Family, Mulish);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
  width: 317px;
}
.v3-acs-transactionSection-authentication-body-desc {
  color: var(--tertiary-neutral-color-2, #a8a8a8);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  width: 317px;
}
.v3-authenticationTxnSuccess-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}
.v3-acs-loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.v3-acs-loader-text-amount {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.36px;
}
.v3-acs-loader-text-name {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  text-align: center;
  font-family: var(--Font-Family, "IBM Plex Sans");
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.v3-acs-loader-text-sub {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  text-align: center;
  font-family: var(--Font-Family, "IBM Plex Sans");
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.v3-acs-loader-text-date {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  text-align: center;
  font-family: var(--Font-Family, "IBM Plex Sans");
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.v3-acs-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 15%;
  width: 100%;
}
.v3-acs-loader-desktop-price-container {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: var(--4, 4px) var(--8, 8px);
  gap: 12px;
  border-radius: var(--8, 8px);
  padding-top: 10px;
}
.v3-acs-loader-desktop-header {
  color: var(--black-100, #1c1c1c);
  text-align: center;
  font-feature-settings:
    "ss01" on,
    "cv01" on,
    "cv11" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 2px;
}
.v3-acs-loader-desktop-header-span {
  color: var(--black-100, #1c1c1c);
  font-feature-settings:
    "ss01" on,
    "cv01" on,
    "cv11" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.v3-acs-loader-desktop-price {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  text-align: right;
  font-family: var(--Font-Family, Mulish);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}
.v3-authenticationTxnSuccess-desktop-container {
  border-radius: 0px 0px var(--8, 8px) var(--8, 8px);
  background: var(--primary-base-color-5, #f0f2f4);
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.v3-acs-loader-desktop-body-container {
  border-radius: 21.04px;
  background: #fff;
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  width: 70%;
  height: 70%;
  flex-shrink: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
}
.v3-acs-loader-desktop-body-container-success {
  border-radius: 21.04px;
  background: #fff;
  box-shadow: 0px 10px 31px -2px rgba(0, 0, 0, 0.09);
  width: 70%;
  height: 70%;
  flex-shrink: 0;
  margin: auto;
  margin-top: 42px;
  overflow: hidden;
}
.v3-acs-loader-desktop-animation {
  width: fit-content;
}
.v3-acs-loader-desktop-animation-success {
  margin-top: 84px;
  margin: auto;
  width: fit-content;
  padding-top: 30px;
}
.v3-acs-loader-desktop-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1px;
  padding-top: 30px;
}
.v3-acs-loader-text-name-desktop {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  text-align: center;
  font-family: var(--Font-Family, "IBM Plex Sans");
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.v3-acs-loader-text-date-desktop {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  text-align: center;
  font-family: var(--Font-Family, "IBM Plex Sans");
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}

.v3-verify-otp-btn-desktop {
  margin: auto;
  margin-top: 120px;
  width: 35%;
}

.v3-MerchantBottomSheet-body {
  display: flex;
  width: 323px;
  padding: 16px 18px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: 12px;
  background: var(--primary-color-5, #f1f6fc);
  margin: auto;
}

.v3-acs-web-authentication {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}
.v3-acs-authentication-desktop-image {
  background-color: var(--color-brand-Blue---500, #027cd5);
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.v3-acs-authentication-desktop-image img {
  width: 369.997px;
  height: 357.634px;
  flex-shrink: 0;
  margin: auto;
}
.v3-acs-authentication-desktop-content {
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
}
.v3-acs-authentication-desktop-header {
  color: var(--color-brand-Blue---500, #027cd5);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  letter-spacing: 0.4px;
  padding: 10px;
  padding-top: 15px;
}
.v3-acs-authentication-desktop-desc {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  width: 80%;
  margin: auto;
  padding-bottom: 40px;
}
.v3-acs-authentication-desktop-btn {
  padding-top: 38px;
}
.v3-acs-authentication-desktop-note {
  padding-top: 30px;
}
.v3-acs-authentication-desktop-note-head-text {
  color: var(--primary-base-color-1, #0d2950);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
}
.v3-acs-authentication-desktop-note-text {
  color: var(--tertiary-neutral-color-2, #a8a8a8);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding-top: 20px;
}
.v3-acs-authentication-note-text {
  display: flex;
  width: 375px;
  padding: 28px 20px;
  flex-direction: column;
  justify-content: center;
  gap: var(--4, 4px);
}
.v3-acs-authentication-note-text-span {
  color: var(--tertiary-neutral-color-2, #a8a8a8);
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.v3_authentication_txn_blocked_retry {
  color: var(--tertiary-neutral-color-1, #a8a8a8);
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-top: 15px;
}
.v3_authentication_txn_blocked_contact_support-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.v3_authentication_txn_blocked_contact_support {
  color: var(--secondary-1, var(--Colors-SECONDARY-1, #ed5b27));
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.v3_authentication_txn_blocked_contact {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.v3_mpin_block_container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.v3_authentication_txn_blocked {
  min-height: 740px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.v3_authentication_txn_blocked_btn_container {
  padding: 0 5% 2%;
}
.customerAuthOTP {
  width: 100%;
}
.acsAuthOTP {
  width: 100%;
}

.v3-allCvpOffers-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.v3-no-transaction-sub-text {
  color: var(--primary-base-1, var(--Colors-PRIMARY-BASE-1, #0d2950));
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  opacity: 0.5;
}
.v3-summary-bottomSheet-statusBar-text {
  color: var(--primary-base-2, var(--Colors-PRIMARY-BASE-2, #697a92));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}
.v3-summary-bottomSheet-img {
  width: 81.549px;
  height: 81.549px;
}
.v3-freezeCardDescription-title {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-style: normal;
  font-family: var(--Font-Family, Mulish);
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.2px;
  width: 325px;
  z-index: 9;
}

.v3-summary-bottomSheet-section-value-sub {
  color: var(--primary-color-1, var(--primary-color-1, #1965cf));
  font-family: var(--Font-Family, Mulish);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 111%;
  letter-spacing: 0.2px;
}

.v3-freezeCardDescription-subtitle-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  z-index: 9;
}

.v3-summary-bottomSheet-statusBar {
  width: 100%;
  height: 8px;
  flex-shrink: 0;
  border-radius: 25px;
  background: var(--System-Palette-neutral-3, #f1f1f1);
  position: relative;
}

.v3-summary-bottomSheet-statusBar-filled {
  height: 100%;
  border-radius: 25px;
  background: var(--primary-color-1, #1965cf);
  position: absolute;
  top: 0;
  left: 0;
}
.v3-summary-bottomSheet-stroke {
  margin: auto;
  padding: 16px;
}
.v3-summary-bottomSheet-total-outStanding-transactions {
  border-radius: 18px;
  background: var(--Colors-PRIMARY-BASE-5, #f0f2f4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  z-index: 9;
}
.v3-freezeCardDescription-subtitle-text {
  color: var(--primary-base-2, var(--Colors-PRIMARY-BASE-2, #697a92));
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  width: 100%;
}
.v3-freezeCardDescription-subtitle-icon {
  display: flex;
  width: 60px;
  height: 60px;
  padding: 10.75px 10px 9.25px 0px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.v3-freezeCardDescription-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.v3-freezeCardDescription-bg {
  position: absolute;
  width: 100%;
}

.demoLogin-wrapper {
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
}

.demoLogin-container {
  max-width: 450px;
  min-height: 100vh;
  min-height: 100dvh;
  margin: auto;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #16193b 34.5%, #03040f 100%);
}

.demoLogin-logo-container {
  width: 40%;
  padding-top: 5%;
  margin: 0px auto;
}

.demoLogin-horizontalBar-container {
  width: 40%;
  padding: 36px 0;
  margin: 0px auto;
}

.demoLogin-logo {
  width: 100%;
}

.demoLogin-heading-container {
  color: #fff;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.demoLogin-content-container {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  opacity: 0.5;
  text-align: center;
  max-width: 335px;
  width: 90%;
  margin: 0px auto;
}

.demoLogin-input-container {
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.demoLogin-input {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.12);
  border: none;
  height: 52px;
  width: 90%;
  color: #fff;
  padding-left: 15px;
}

.demoLogin-input::placeholder {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  opacity: 0.4;
  padding-left: 10px;
}

.demoLogin-input:focus {
  outline: 1px solid #fff;
}
.demoLogin-error {
  color: #ff2e2e;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-left: 5px;
  width: 90%;
  margin: 0px;
}

.demoLogin-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.demoLogin-button-active {
  border-radius: 12px;
  background: #0bd5ba;
  color: #16193b;
  width: 90%;
  height: 52px;
  margin: auto;
  margin-top: 6%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.demoLogin-button {
  border-radius: 12px;
  opacity: 0.5;
  background: #0bd5ba;
  color: #16193b;
  width: 90%;
  height: 52px;
  margin: auto;
  margin-top: 6%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.demoLogin-mobile-container {
  margin-top: auto;
  display: flex;
  align-items: center;
}

.demoLogin-mobile-img {
  width: 100%;
  mask-image: linear-gradient(to top, black 92%, transparent);
  -webkit-mask-image: linear-gradient(to top, black 92%, transparent);
}

.demoLogin-wrapper {
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
}

.demoLogin-container {
  max-width: 450px;
  min-height: 100vh;
  min-height: 100dvh;
  margin: auto;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #16193b 34.5%, #03040f 100%);
}

.demoLogin-logo-container {
  width: 40%;
  padding-top: 5%;
  margin: 0px auto;
}

.demoLogin-horizontalBar-container {
  width: 40%;
  padding: 36px 0;
  margin: 0px auto;
}

.demoLogin-logo {
  width: 100%;
}

.demoLogin-heading-container {
  color: #fff;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.demoLogin-content-container {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  opacity: 0.5;
  text-align: center;
  max-width: 335px;
  width: 90%;
  margin: 0px auto;
}

.demoLogin-input-container {
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.demoLogin-input {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.12);
  border: none;
  height: 52px;
  width: 90%;
  color: #fff;
  padding-left: 15px;
}

.demoLogin-input::placeholder {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  opacity: 0.4;
  padding-left: 10px;
}

.demoLogin-input:focus {
  outline: 1px solid #fff;
}
.demoLogin-error {
  color: #ff2e2e;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-left: 5px;
  width: 90%;
  margin: 0px;
}

.demoLogin-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.demoLogin-button-active {
  border-radius: 12px;
  background: #0bd5ba;
  color: #16193b;
  width: 90%;
  height: 52px;
  margin: auto;
  margin-top: 6%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.demoLogin-button {
  border-radius: 12px;
  opacity: 0.5;
  background: #0bd5ba;
  color: #16193b;
  width: 90%;
  height: 52px;
  margin: auto;
  margin-top: 6%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.demoLogin-mobile-container {
  margin-top: auto;
  display: flex;
  align-items: center;
}

.demoLogin-mobile-img {
  width: 100%;
  mask-image: linear-gradient(to top, black 92%, transparent);
  -webkit-mask-image: linear-gradient(to top, black 92%, transparent);
}
.v3-login-journey-offer-carousel {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 18px;
  background: rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(2px);
  width: 90%;
  margin: auto;
  margin-top: 80%;
  height: 132px;
  overflow: hidden;
}
.v3-login-journey-offer-image {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  border-radius: 12px;
  background-color: var(--leading-white, #fff);
}
.v3-login-journey-offer-text {
  color: var(--primary-base-1, var(--Colors-PRIMARY-BASE-1, #0d2950));
  /* H1 - strong */
  font-family: var(--Font-Family, Mulish);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}
.carousel-container {
  position: relative;
  width: 90%;
  margin: auto;
  overflow: hidden;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.v3-login-journey-offer-carousel {
  flex: 0 0 100%;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 18px;
  background: rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(2px);
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
}

.carousel-arrow.left {
  left: 10px;
}

.carousel-arrow.right {
  right: 10px;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: rgba(0, 0, 0, 0.8);
}
.v3-login-journey-bottomSheet-title {
  color: var(--leading-white, var(--System-Palette-leading-white, #fff));
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.v3-login-journey-bottomSheet-title-container {
  border-radius: 13px;
  background: var(--Colors-PRIMARY-ACCENT-1, #1965cf);
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.v3-login-journey-bottomSheet-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.v3-login-journey-bottomSheet-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  align-self: stretch;
}
.v3-login-journey-bottomSheet-body-title {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}
.v3-login-journey-bottomSheet-body-title-text {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}
.v3-login-journey-bottomSheet-body-description {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  text-align: center;
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  opacity: 0.5;
}
.v3-summary-bottomSheet-title-container {
  display: flex;
  /* padding: 24px 26px 28px 28px; */
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  border-radius: 32px 32px 0px 0px;
  background: var(--System-Palette-leading-white, #fff);
}
.v3-summary-bottomSheet-title-text {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-family: var(--Font-Family, Mulish);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}
.v3-summary-bottomSheet-title-subText {
  color: var(--neutral-1, var(--System-Palette-neutral-1, #0e0e0e));
  font-family: var(--Font-Family, Mulish);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.v3-cards-container-body {
  display: flex;
  padding: 18px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 18px;
  height: 86px;
  margin: auto;
  width: 90%;
  border: 1px solid var(--Colors-PRIMARY-BASE-4, #d3d8df);
  background: var(--Colors-PRIMARY-BASE-5, #f0f2f4);
  margin-bottom: 10px;
}
.v3-cards-container {
  display: flex;
  align-items: center;
  gap: 14px;
}
.v3-cards-image {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.v3-cards-name-number {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.v3-cards-name {
  color: var(--primary-base-1, var(--Colors-PRIMARY-BASE-1, #0d2950));
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}
.v3-cards-number {
  color: var(--primary-base-1, var(--Colors-PRIMARY-BASE-1, #0d2950));
  font-family: var(--Font-Family, Mulish);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.v3-cards-rupay-img-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.957px;
}
.v3-home-activitySection-creditCardInfo {
  display: flex;
  width: 100%;
  padding: 8px 20px;
  justify-content: space-between;
  align-items: center;
  background: var(--Colors-PRIMARY-ACCENT-3, #acc8ee);
}
.v3-home-activitySection-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media (max-width: 380px) {
  .v3-home-activitySection-creditCardInfo {
    padding: 8px 3px;
  }
}

.v3-home-activitySection-card-details {
  display: flex;
  align-items: center;
  gap: 4px;
}
.v3-home-activitySection-card-details-number {
  display: flex;
  height: 22px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 26.63px;
  background: rgba(0, 0, 0, 0.08);
}
.v3-home-activitySection-card-text {
  color: var(--primary-base-1, var(--Colors-PRIMARY-BASE-1, #0d2950));
  font-family: var(--Font-Family, Mulish);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}
.v3-offerDetails-accordian-dangerously-rendered-points blockquote {
  margin: 1em 40px;
}

@media (max-width: 390px) {
  .v3-activity-section-single-action {
    width: 160px;
  }
  .v3-quick-action-text {
    width: 0;
  }
}

/* removed yellow background when otp is auto filled */
input:-webkit-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}
